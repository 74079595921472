import React, { useEffect, useState } from "react"
import {
  Input,
  notification,
  Col,
  Drawer,
  Row,
  DatePicker,
  Divider,
  List,
  Avatar,
} from "antd"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "../../../helpers/FormHelpers"
import { Icon } from "../Icon"
import SelectDropdown from "../SelectDropdown"
import { DateSelector } from "../DateSelector"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchTypes } from "../../redux/actions/commun"
import ApiService from "../../services/ApiService"
import { errorNotification, successNotification } from "../../utils"
import { CircularProgress } from "@material-ui/core"
import { fetchOutages, fetchSitesDetail } from "../../redux/actions"
import moment from 'moment'
import { DollarOutlined} from '@ant-design/icons';
const { TextArea } = Input

const { RangePicker } = DatePicker;

interface ModalProps {
  onClose?: () => void
  open: boolean
  notifications: any
}

const ModalNotifications = ({ onClose = undefined, open = false, notifications }: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onChange" })

  const dispatch = useDispatch()

  const { types } = useSelector((state: RootStateOrAny) => state.commun)
  const sites_ = useSelector((state: RootStateOrAny) => state.sites)
  const [sites, setSites] = useState(sites_.data)


  const [outage_type, setoutage_type] = useState()
  const [site_public_id, setsite_public_id] = useState()
  const [start_time, setstart_time] = useState(moment().format('YYYY-MM-DD HH:mm'))
  const [end_time, setend_time] = useState(moment().add(1, 'days').format('YYYY-MM-DD HH:mm'))
  const [comment, setcomment] = useState("")
  const [loading, setloading] = useState(false)
  const [InstalledCapacity, setInstalledCapacity] = useState("")
  
  const type_arret = { "unplanned" : "non planifié",
                        "planned" : "planifié"}


  const onSubmit = async (data: any) => {

    const body = {
      ...data,
      outage_type,
      site_public_id,
      start_time,
      end_time,
      comment,
    }

    try {
      setloading(true)
      const resp = await ApiService.SignalerArret(body)
      if (resp.status == 200) successNotification(resp.data.message)

      dispatch(fetchOutages())

      onClose && onClose()

    } catch (err) {
      errorNotification(err.message)
    }
    setloading(false)
  }
  

  const handleChangeSite = site => {

    setsite_public_id(site.public_id)
    setInstalledCapacity(site.installed_capacity)

  }
  const handleChangeType = type => {
    setoutage_type(type.key)
  }



  useEffect(() => {
    // dispatch(fetchTypes())
    // dispatch(fetchSitesDetail())
  }, [])

  useEffect(() => {
    setSites(sites_.data)
  }, [sites_])




  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    setstart_time(dateString[0])
    setend_time(dateString[1])


  };

  const data = [
    {
      title: 'Alertes RTE le 11 septembre 2023',
      description :'Journée PP1'
    },
    {
      title: 'Centrale du Chapitre',
      description :'Factures disponible'
    },
    {
      title: 'Prix Negatifs le 11 septembre 2023',
      description :'19h : -1€'
    },
    {
      title: 'Prix Negatifs le 14 septembre 2023',
      description :'14h : -6€'
    },
  ];



  return (
    <>
      <Drawer
        className="user-drawer"
        width={window.innerWidth > 1181 ? 465 : "100vw"}
        title={
          <div className="font-semibold text-3xl leading-11 "style={{marginBottom:'80px'}} >
            Vos Alertes
          </div>
        }
        closeIcon={<Icon name="close" />}
        bodyStyle={{ padding: 40, paddingTop: 0, paddingBottom: 110 }}
        headerStyle={{ border: "none", padding: "45px 45px 0px 40px" }}
        placement="right"
        onClose={onClose}
        open={open}
        
      >

<List
    itemLayout="horizontal"
    dataSource={notifications}
    renderItem={(item, index) => (
      <List.Item>

        {item.title == 'Alerte Prix Spot' ? 
            <List.Item.Meta
              avatar={<DollarOutlined size={55}/>}
              title={item.title}
              description={<span>Le {moment(item.start_time).format('DD MMM HH:mm')}, le prix est négatif : {item.description}€ </span>}
            />
        :
            <List.Item.Meta
            avatar={<DollarOutlined size={55}/>}
            title={item.title}
            description={<span>Le {moment(item.start_time).format('DD MMM')} est classé {item.description} par RTE </span>}
          />
        }


      </List.Item>
    )}
  />
      </Drawer>
    </>
  )
}
export default ModalNotifications
