import ApiService from "../services/ApiService";

export const load_dashboard_volume = async (
queryParams : any
) => {

  const response = await ApiService.GetDashboardVolume(queryParams['queryKey'][1])
  const rq_data = await response.data.dashboard;
  return rq_data;
};



