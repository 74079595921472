import moment from "moment"
import { useEffect, useState } from "react"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
  Legend,
  LabelList,
  ReferenceLine
} from "recharts"
import { stringToHexColor } from "../../utils"
import Text from "../Text"
import { Xtransformer, tickFormatter } from "./Util"

var Lines = [
  // {
  //   key: "h01",
  //   title: "Tarif contrat H01",
  //   color: "#D3EE66",
  //   hasDot:false,
  //   fill:false
  // },
  // {
  //   key: "h07",
  //   title: "Tarif contrat H07",
  //   color: "#D01E73",
  //   hasDot:false,
  //   fill:false
  // },
  // {
  //   key: "h16",
  //   title: "Tarif contrat H16",
  //   color: "#4BD273",
  //   hasDot:false,
  //   fill:false
  // },
  {
    key: "Prix marché Spot",
    title: "Marché",
    color: "#5819F1",
    hasDot: false,
    fill: true,
  },
]

interface P {
  data: any
  xScale: string
  contract: string
}

const SwitchComponent: React.FC<P> = ({ data, xScale, contract}) => {
  const [lines, setlines] = useState(Lines)
  const [dataChart, setDataChart] = useState(Array())
  const [dataBumped, setDataBumped] = useState(Array())

  console.log('dataBumped',dataBumped)

  useEffect(() => {
    if (data.length) {
      const keys = Object.keys(data[0]).filter(k=>k===contract)
      // const keys = ["h01", "h07"]
      const l = keys.map((i, idx) => ({
        key: i,
        title: "Référence OA",
        color: 'red',
        hasDot: false,
        fill: false,
        marker : i
      }))
      setlines([...Lines, ...l])
    }

    const x = Xtransformer(data, xScale)

    const X = 30; // Example value for the bump
    const keyToBump = contract; // The key you want to bump
    const newKey = `Référence +30€`; // Name of the new key

    x.forEach(item => {
      item[newKey] = item[keyToBump] + X;
    });
    setDataChart(x)
  }, [data])

  const renderLabel = (label: string) => {
    const obj = lines.find(i => i.key == label)
    return obj ? obj.title : label
  }

  type CustomTooltipProps = TooltipProps<any, any> & {
    active?: any
    payload?: any
    label?: any
    xScale?: any
  }

  const CustomTooltip = ({
    xScale,
    active,
    payload,
    label,
    ...props
  }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="text-white rounded-lg p-2"
          style={{ background: "#20263D" }}
        >
          <Text type="14-600">{renderLabel(payload[0].payload.date)}</Text> <br />
          {payload.map((p: any) => (
            <div>
              <Text type="12-500">{renderLabel(p.name)}</Text> <br />
              <Text type="14-600">{p.value.toLocaleString("fr")} €</Text>
            </div>
          ))}
        </div>
      )
    }

    return null
  }

  const currentTime = moment().format("DD/MM HH[h]");
  console.log('dataChart',currentTime,dataChart)

  const renderCustomLabel = (props, dataLength, color) => {
    const { x, y, value, index } = props;
    if (index === dataLength - 1) { // Check if it's the first point
      return <text x={x} y={y} dy={-10} fill={color} fontSize={12} textAnchor="middle">{value}</text>;
    }
    return null;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={dataChart}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#5819F166" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#5819F103" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="2 2" vertical={true} />
          <XAxis
            tickMargin={30}
            padding={{ left: 20 }}
            tickLine={false}
            axisLine={false}
            dataKey="date"
            tickFormatter={tickFormatter}
            
          />
          <YAxis
            unit={" €"}
            tickLine={false}
            axisLine={false}
            // domain={["auto", "auto"]}
            domain={['dataMin', 'dataMax + 30']}
            tickFormatter={value =>
              new Intl.NumberFormat("fr", {
                notation: "compact",
                compactDisplay: "short",
              }).format(value)
            }
          />
          <Tooltip
            content={p => <CustomTooltip xScale={xScale} {...p} />}
            cursor={{ fill: "transparent" }}
          />
          <Legend
            wrapperStyle={{
              marginLeft: 20,
              top: -15,
              // display: "flex",
              // justifyContent: "start",
            }}
            align="left"
            verticalAlign="top"
            iconType="line"
            formatter={(value, entry, index) => renderLabel(value)}
          />

          {lines.map(l => (
              <Area
              dot={
                l.hasDot
                  ? { stroke: l.color, strokeWidth: 2, fill: "white", r: 4 }
                  : false
              }
              activeDot={{ stroke: l.color, strokeWidth: 1, fill: l.color }}
              strokeWidth={2}
              type="monotone"
              dataKey={l.key}
              stroke={l.color}
              fillOpacity={l.fill ? 1 : 0}
              fill="url(#colorUv)"
              isAnimationActive={false}
            >
              {l.marker && <LabelList dataKey={l.marker}  content={(props) => renderCustomLabel(props, dataChart.length, l.color)} />}
            </Area>
          ))}


            
            <Area
              dot={ false}
              activeDot={{ stroke: "black", strokeWidth: 0, fill:  "black" }}
              strokeWidth={2}
              type="monotone"
              dataKey={'Référence +30€'}
              stroke={"black"}
              fillOpacity={0}
              fill="url(#colorUv)"
              isAnimationActive={false}

            >
              <LabelList dataKey={'Référence +30€'}  content={(props) => renderCustomLabel(props, dataChart.length, 'black')} />
            </Area>


          <ReferenceLine x={currentTime} strokeWidth="1" stroke="black" strokeDasharray="3 3" />

        </AreaChart>
      </ResponsiveContainer>
    </>
  )
}
export default SwitchComponent
