import { Button, Checkbox, Form, Input } from "antd"
import TextArea from "antd/es/input/TextArea"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import ApiService from "../../services/ApiService"
import { fetchInvoices } from "../../redux/actions"

interface P {
  data: any
}

const FormContestComponent: React.FC<P> = ({ data }) => {

  const dispatch = useDispatch()

  const [formContest] = Form.useForm()

  const onFinishContest = async (values: any) => {
    const body = values
    console.log('onFinishContest', body)
    contestInvoiceClient(body)
    formContest.resetFields();
  };

  const contestInvoiceClient = async (body: object) => {
    console.log('sendInvoice()')
    const resp = await ApiService.ContestInvoiceClient(body)
    if (resp.status == 200) {
      dispatch(fetchInvoices());

    }
  }

  return (
    <>
              <Form
              form={formContest}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              onFinish={onFinishContest}
              initialValues={{ invoice_id: data.invoice_id }}
            >

              <Form.Item label="Référence" name="invoice_id">
                <Input disabled defaultValue={data.invoice_id} />
              </Form.Item>
              <Form.Item label="Commentaire" name="comments">
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Je ne suis pas d'accord, contactez moi</Button>
              </Form.Item>
            </Form>

    </>
  )
}
export default FormContestComponent
