
import { Button, Checkbox, Form, Input, Modal, Spin, Table, Card, Select, InputNumber } from "antd";
import React, { useState } from "react";
import type { ColumnsType } from 'antd/es/table';
import EditableCell from "./EditableCell";
import { fetchBudgetVolume } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";


interface IMyTableData {
  key: number;
  unit_name: string;
  pmax: number;
  jan: number;
  feb: number;
}

const data: IMyTableData[] = [];
for (let i = 0; i < 7; i++) {
  data.push({
    key: i,
    unit_name: `Ctrl_${i}`,
    pmax: i,
    jan: Math.floor(Math.random() * 1000),  // Replace with actual random range
    feb: Math.floor(Math.random() * 1000),
    mar: Math.floor(Math.random() * 1000),
    apr: Math.floor(Math.random() * 1000),
    may: Math.floor(Math.random() * 1000),
    jun: Math.floor(Math.random() * 1000),
    jul: Math.floor(Math.random() * 1000),
    aug: Math.floor(Math.random() * 1000),
    sep: Math.floor(Math.random() * 1000),
    oct: Math.floor(Math.random() * 1000),
    nov: Math.floor(Math.random() * 1000),
    dec: Math.floor(Math.random() * 1000),
  });
}

const dataPrix: IMyTableData[] = [];
for (let i = 0; i < 1; i++) {
  dataPrix.push({
    key: i,
    name: `Prix Forward Marché Spot`,
    jan: Math.floor(Math.random() * 150) + 50,  // Replace with actual random range
    feb: Math.floor(Math.random() * 150) + 50,
    mar: Math.floor(Math.random() * 150) + 50,
    apr: Math.floor(Math.random() * 150) + 50,
    may: Math.floor(Math.random() * 150) + 50,
    jun: Math.floor(Math.random() * 150) + 50,
    jul: Math.floor(Math.random() * 150) + 50,
    aug: Math.floor(Math.random() * 150) + 50,
    sep: Math.floor(Math.random() * 150) + 50,
    oct: Math.floor(Math.random() * 150) + 50,
    nov: Math.floor(Math.random() * 150) + 50,
    dec: Math.floor(Math.random() * 150) + 50,
  });
}


const TableComponent: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataPrix, setTableDataPrix] = useState(dataPrix);
  const [tableResult, setTableResult] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selection, setSelection] = useState([]);

  const [prixMC, setPrixMC] = React.useState(42)
  const [prixGOs, setPrixGOs] = React.useState(6)
  const [M_MC, setM_MC] = React.useState(15)
  const [M_GO, setM_GO] = React.useState(15)
  const [M_Spot, setM_Spot] = React.useState(5)

  const { budget } = useSelector((state: RootStateOrAny) => state.budget)


  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchBudgetVolume())
  }, [])

  React.useEffect(() => {


    if (budget?.data) {

      setTableData(budget.data?.budget)
    }



  }, [budget])

  console.log('tableData',tableData)

  React.useEffect(() => {
    // Set totals on initial render
    const newData = [...tableData];
    for (let index = 0; index < tableData.length; index++) {
      setTotal(newData, index);
    }
    setTableData(newData);

  }, []);


  React.useEffect(() => {
    calculateTotal()

  }, [tableDataPrix, tableData, selection, M_Spot, M_GO, prixGOs, prixMC]);


  const onInputChange = (key, index) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {

    const newData = [...tableData];
    console.log(e.target.value)

    newData[index][key] = Number(e.target.value);

    setTableData(newData);
    // calculateTotal()
  };

  const onInputChangePrix = (key, index) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newData = [...tableDataPrix];
    newData[index][key] = Number(e.target.value);
    setTableDataPrix(newData);
    // calculateTotal()
  };

  const setTotal = (data, index) => {
    // Set total
    data[index]["totalCount"] = Number(
      data[index]["jan"] + data[index]["feb"]
    );
  };


  const columns: ColumnProps<IMyTableData>[] = [
    {
      title: 'Name',
      dataIndex: 'unit_name',
      width: '15%'
    },
    {
      title: 'Pmax',
      dataIndex: 'pmax',
      width: '6%',
      render: (text, record, index) => (
        <Input value={parseInt(text).toFixed(0)} onChange={onInputChange('pmax', index)} />
      )
    },
    ...['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].map(item => ({
      dataIndex: item,
      title: item,
      render: (text, record, index) => (
        <Input value={parseInt(text).toFixed(0)} onChange={onInputChange(item, index)} />
      )
    }))
  ];


  const columnsPrix: ColumnProps<IMyTableData>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%'
    },
    {
      title: '',
      dataIndex: '',
      width: '8%'
    },
    ...['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].map(item => ({
      dataIndex: item,
      title: item,
      render: (text, record, index) => (
        <Input value={text} onChange={onInputChangePrix(item, index)} />
      )
    }))
  ];


  const columnsTotal: ColumnProps<IMyTableData>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%'
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '8%',
      render: (text, record, index) => (<div>{text.toFixed(1)}</div>)
    },
    ...['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].map(item => ({
      dataIndex: item,
      title: item,
      render: (text, record, index) => (<div>{text ? text.toFixed(1) : '-'}</div>

      )
    }))
  ];


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelection(newSelectedRowKeys)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const calculateTotal = () => {



    const total_energie = {
      key: '1',
      name: 'Energie - Chiffre Affaires - K €',
    };

    const total_energie_marge = {
      key: '2',
      name: 'Energie - Marge - K €',
    };

    const total_gos = {
      key: '3',
      name: 'GOS - Chiffre Affaires - K €',
    };

    const total_gos_marge = {
      key: '4',
      name: 'GOS - Marge - K €',
    };

    const total_mc = {
      key: '5',
      name: 'MC - Chiffre Affaires - K €',
    };

    const total_mc_marge = {
      key: '6',
      name: 'MC - Marge - K €',
    };


    const selectedPrix = tableDataPrix[0];

    if (selectedPrix) {
      Object.keys(selectedPrix).forEach(month => {
        if (month !== 'key' && month !== 'name' && month !== 'pmax') {

          total_energie[month] = rowSelection.selectedRowKeys.reduce((sum, selectedKey) => {
            const selectedData = tableData.find(item => item.key === selectedKey);
            if (selectedData) {
              return sum + (selectedData[month] * selectedPrix[month] / 1000);
            }
            return sum;
          }, 0);

          total_energie_marge[month] = rowSelection.selectedRowKeys.reduce((sum, selectedKey) => {
            const selectedData = tableData.find(item => item.key === selectedKey);
            if (selectedData) {
              return sum + (selectedData[month] * selectedPrix[month] / 1000 * M_Spot / 100);
            }
            return sum;
          }, 0);

          total_gos[month] = rowSelection.selectedRowKeys.reduce((sum, selectedKey) => {
            const selectedData = tableData.find(item => item.key === selectedKey);
            if (selectedData) {
              return sum + (selectedData[month] * prixGOs / 1000);
            }
            return sum;
          }, 0);

          total_gos_marge[month] = rowSelection.selectedRowKeys.reduce((sum, selectedKey) => {
            const selectedData = tableData.find(item => item.key === selectedKey);
            if (selectedData) {
              return sum + (selectedData[month] * prixGOs / 1000 * M_GO / 100);
            }
            return sum;
          }, 0);

        }
      });

      const monthlyKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

      total_energie['total'] = monthlyKeys.reduce((sum, month) => sum + total_energie[month], 0);
      total_energie_marge['total'] = monthlyKeys.reduce((sum, month) => sum + total_energie_marge[month], 0);
      total_gos['total'] = monthlyKeys.reduce((sum, month) => sum + total_gos[month], 0);
      total_gos_marge['total'] = monthlyKeys.reduce((sum, month) => sum + total_gos_marge[month], 0);


      total_mc['total']  = rowSelection.selectedRowKeys.reduce((sum, selectedKey) => {
        const selectedData = tableData.find(item => item.key === selectedKey);
        if (selectedData) {
          return sum + selectedData.pmax / 1000 * 0.33 * prixMC;
        }
        return sum;
      }, 0);

      total_mc_marge['total']  = rowSelection.selectedRowKeys.reduce((sum, selectedKey) => {
        const selectedData = tableData.find(item => item.key === selectedKey);
        if (selectedData) {
          return sum + selectedData.pmax / 1000 * 0.33 * prixMC * M_MC / 100;
        }
        return sum;
      }, 0);

      
    }

    setTableResult([total_energie, total_energie_marge, total_gos, total_gos_marge, total_mc, total_mc_marge])
  };





  return (
    <div style={{ marginTop: '30px' }}>
      <Card
        title="Mes Inputs">

        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', gap: '25px' }}>

          <Form.Item
            label="Margin Spot"
            style={{ width: '100px' }}
          >
            <InputNumber addonAfter="%" defaultValue={M_Spot} onChange={(value) => setM_Spot(value)} />
          </Form.Item>

          <Form.Item
            label="Margin MC"
            style={{ width: '100px' }}
          >
            <InputNumber addonAfter="%" defaultValue={M_MC} onChange={(value) => setM_MC(value)} />
          </Form.Item>

          <Form.Item
            label="Margin GO"
            style={{ width: '100px' }}
          >
            <InputNumber addonAfter="%" defaultValue={M_GO} onChange={(value) => setM_GO(value)} />
          </Form.Item>
        </div>


        <div style={{ paddingTop: 20, paddingBottom: 20, fontSize: '16px' }}>Mes Volumes En MWh</div>

        <Spin
          spinning={budget.loading}>


          <Table
            size={"small"}
            rowSelection={rowSelection}
            rowKey="key"
            columns={columns}
            dataSource={[...tableData]}
            pagination={false}
            summary={pageData => {



              const sumPerMonth = {}
              var sumPMAX = 0
              tableData.forEach(item => {


                if (rowSelection.selectedRowKeys.includes(item.key)) {


                  for (const [objkey, value] of Object.entries(item)) {
                    if (['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].includes(objkey)) {
                      sumPerMonth[objkey] = (parseInt(sumPerMonth[objkey]) || 0) + parseInt(value);
                    }
                    if (['pmax'].includes(objkey)) {
                      sumPMAX = (sumPMAX || 0) + parseInt(value);
                    }

                  }

                }

              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <div className="text-md"></div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-md font-bold">Total</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <div className="text-md">{sumPMAX}</div>
                    </Table.Summary.Cell>

                    {
                      Object.entries(sumPerMonth).map(([key, value]) => (
                        <Table.Summary.Cell key={key}>
                          <div className="text-md">{value}</div>
                        </Table.Summary.Cell>
                      ))
                    }

                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Spin>

        <div style={{ paddingTop: 40, paddingBottom: 20, fontSize: '16px' }}>Mes Pévisionnels Prix en €</div>
        <Table
          size={"small"}
          rowKey="key"
          columns={columnsPrix}
          dataSource={[...tableDataPrix]}
          pagination={false}
        />

        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', gap: '25px' }}>
          <Form.Item
            label="Estimation Prix MC"
            style={{ width: '150px', fontSize: '16px' }}
          >
            <InputNumber addonBefore="+" addonAfter="K€" defaultValue={prixMC} onChange={(value) => setPrixMC(value)} />
          </Form.Item>

          <Form.Item
            label="Estimation Prix GOs"
            style={{ width: '150px', fontSize: '16px' }}
          >
            <InputNumber addonBefore="+" addonAfter="€" defaultValue={prixGOs} onChange={(value) => setPrixGOs(value)} />
          </Form.Item>
        </div>

        <div style={{ paddingTop: 40, paddingBottom: 20, fontSize: '16px' }}>Mon total en K€</div>
        <Table
          size={"small"}
          rowKey="key"
          columns={columnsTotal}
          dataSource={[...tableResult]}
          pagination={false}
          summary={pageData => {



            const sumPerMonthCA = {}
            const sumPerMonthMarge = {}
            var totalCA = 0
            var totalMarge = 0


            // console.log('tableResult',tableResult)

            tableResult.forEach(item => {


              for (const [objkey, value] of Object.entries(item)) {
                if (['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].includes(objkey) && (item.name.includes("Chiffre Affaires"))) {
                  sumPerMonthCA[objkey] = (parseFloat(sumPerMonthCA[objkey]) || 0) + parseFloat(value);
                  // totalCA = (totalCA || 0) + parseFloat(value);
                }

                if (['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].includes(objkey) && (item.name.includes("Marge"))) {
                  sumPerMonthMarge[objkey] = (parseFloat(sumPerMonthMarge[objkey]) || 0) + parseFloat(value);
                  // totalMarge = (totalMarge || 0) + parseFloat(value);
                }

              }

            }
            );

            // #Add on MecaCAPA

            totalCA = tableResult.reduce((sum, obj) => {  
                                                          if (obj.name.includes("Chiffre Affaires")) {
                                                            return sum + (parseFloat(obj.total)|| 0);
                                                          }
                                                          return sum
                                                        }, 0)

            totalMarge = tableResult.reduce((sum, obj) => {
                                                          if (obj.name.includes("Marge")) {
                                                            return sum + (parseFloat(obj.total)|| 0);
                                                          }
                                                          return sum
                                                        }, 0)

  

            return (
              <>
                <Table.Summary.Row>

                  <Table.Summary.Cell className="text-md font-bold">Total CA - K€</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <div className="text-md" style={{ fontSize: '16px' }}>{parseFloat(totalCA).toFixed(1)}</div>
                  </Table.Summary.Cell>

                  {
                    Object.entries(sumPerMonthCA).map(([key, value]) => (
                      <Table.Summary.Cell key={key} >
                        <div className="text-md" style={{ fontSize: '16px' }}>{parseFloat(value).toFixed(1)}</div>
                      </Table.Summary.Cell>
                    ))
                  }

                </Table.Summary.Row>
                <Table.Summary.Row>

                  <Table.Summary.Cell className="text-md font-bold">Total Marge - K€</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <div className="text-md" style={{ fontSize: '16px' }}>{parseFloat(totalMarge).toFixed(1)}</div>
                  </Table.Summary.Cell>

                  {
                    Object.entries(sumPerMonthMarge).map(([key, value]) => (
                      <Table.Summary.Cell key={key} >
                        <div className="text-md" style={{ fontSize: '16px' }}>{parseFloat(value).toFixed(1)}</div>
                      </Table.Summary.Cell>
                    ))
                  }

                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
    </div>
  );
};

export default TableComponent
