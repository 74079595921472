import React, { lazy, useEffect, useState, Suspense } from "react"
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Avatar, Col, Collapse, message, Row, Select, Table, Tag, Timeline, Tooltip, Upload } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';
import SelectDropdown from "../SelectDropdown"
import { fetchCompanies, FetchUploadFile } from "../../redux/actions/commun"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import ApiService from "../../services/ApiService";
import { DatePicker, Space } from 'antd';
import { fetchInvoices, fetchSitesDetail, fetchSubscriptions } from "../../redux/actions";
import { TreeSelect } from 'antd';
import toast from "react-hot-toast"
import moment from 'moment'
import { Colors, Icon, IconNames } from "../Icon";
import Text from "../Text"
import { SmileOutlined } from '@ant-design/icons';
import { Modal } from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"
import TextArea from "antd/es/input/TextArea";
import TimelineComponent from "../Facturation/Timeline";
import UtilFactureComponent from "../Facturation/UtilFacture";
import { SyncOutlined } from "@material-ui/icons";
import FormReviewComponent from "../Facturation/FormReview";
import FormPaidComponent from "../Facturation/FormPaid";
const { confirm } = Modal


const { SHOW_PARENT } = TreeSelect;





const { Panel } = Collapse
// const { confirm } = Modal


export default function AlertAdmin() {



  const commun = useSelector((state: RootStateOrAny) => state.commun)
  const sites_ = useSelector((state: RootStateOrAny) => state.sites)
  const subscriptions_ = useSelector((state: RootStateOrAny) => state.subscriptions)

  const dispatch = useDispatch()

  const [selectedSPV, setSelectedSPV] = useState(Array())
  const [Dates, setDates] = useState(Array())
  const [Status, setStatus] = useState(Array())
  const [Labels, setLabels] = useState(Array())
  const [selectedDate, setSelectedDate] = useState([])
  const [selectedLabel, setSelectedLabel] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])

  const [selectedSites, setSelectedSites] = useState(Array())
  const [sites, setSites] = useState(Array())
  const [SPV, setSPV] = useState(Array())
  const [invoices, setInvoices] = useState(Array())
  const [currentinvoice, setcurrentInvoice] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const [subscriptions, setSubscriptions] = useState(Array())

  useEffect(() => {

    dispatch(fetchSubscriptions())
    if (commun.companies) {
      setSPV(commun.companies)
    }
    if (sites_.data) {
      setSites(sites_.data)
    }

    if (subscriptions_.data) {
      setSubscriptions(subscriptions_.data)
      setLoading(false)
    }
  }, [])


  useEffect(() => {
    console.log("useEffect subscriptions")
    if (subscriptions_.data) {
      console.log("useEffect subscription ", subscriptions_.data)
      setSubscriptions(subscriptions_.data)
      setLoading(false)

    }
  }, [subscriptions_.data])


  useEffect(() => {
    // dispatch(fetchCompanies())
    const companies = commun.companies?.map(item => { return { ...item, name: item.name.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()) } })
    setSPV(companies)
  }, [])

  useEffect(() => {
    setTree(generateTarget(sites, SPV))
  }, [sites, SPV])


  const extractUnique = (arr) => {

    const resultArray = [];
    const uniqueDate = [];

    const resultStatus = [];
    const uniqueStatus = [];

    const resultLabels = [];
    const uniqueLabels = [];

    for (const obj of arr) {
      // dates
      const PeriodEnd = moment(obj.period_end).format('YYYY-MM-DD');
      if (!uniqueDate.includes(PeriodEnd)) {
        resultArray.push({ "label": PeriodEnd, "value": PeriodEnd });
      }
      uniqueDate.push(PeriodEnd)

      // status
      const Status = obj.status
      if (!uniqueStatus.includes(Status)) {
        resultStatus.push({ "label": Status, "value": Status});
      }
      uniqueStatus.push(Status)

      // labels
      const Labels = obj.invoice_label
      if (!uniqueLabels.includes(Labels)) {
        resultLabels.push({ "label": Labels, "value": Labels});
      }
      uniqueLabels.push(Labels)

    }
    return {dates : resultArray, status : resultStatus, labels : resultLabels}
  }

  const onChange = (key) => {
    console.log(key)
  };

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
    const x = (value + '').split(',')
    if (x && x.length > 0) {
      setSelectedDate(x)
    }
  };

  console.log('selectedDate', selectedDate)
  const delay = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  const [form] = Form.useForm()
  const [formReview] = Form.useForm()

  const onFinish = async (values: any) => {

    console.log('onFinish', values)
   

    for (const site of values.Sites) {
      await createSubscription(site,values.identifier,values.type,values.channel,values.address);
      await delay(1000);
      dispatch(fetchSubscriptions());
    }

  };

  function getTitle(data, targetValue) {
    // Define a recursive function to search for the target value
    function findTitle(arr, target) {
        for (const item of arr) {
            if (item.value === target) {
                return item.title;
            }
            if (item.children && item.children.length > 0) {
                const result = findTitle(item.children, target);
                if (result) {
                    return result;
                }
            }
        }
        return null; // Return null if the target value is not found
    }

    // Call the recursive function to search for the title
    return findTitle(data, targetValue);
}

  const createSubscription = (site_id, identifier,type, channel,address) => {
    return new Promise((resolve, reject) => {
      toast.promise(ApiService.CreateSubscription({ site_id, identifier,type, channel,address }), {
        loading: `Creating Subscription For : ${getTitle(tree,site_id)}`,
        success: (data) => {
          resolve(data); // Resolve the promise when successful
          return `${data.data.message}`;
        },
        error: (err) => {
          reject(err); // Reject the promise on error
          console.log('erreur invoice',err.response)
          return `${err.response.data.message.toString()}`;
        }
      });
    });
  };


  function generateTarget(sites, SPV) {
    const Target = SPV.map(spvItem => {
      const filteredSites = sites.filter(site => site.companies_public_id === spvItem.public_id);

      return {
        value: spvItem.public_id,
        key: spvItem.public_id,
        title: spvItem.name,
        children: filteredSites.map(site => ({
          value: site.public_id,
          key: site.public_id,
          title: site.name,
          prm: site.prm_number,
        })),
      };
    });


    return Target;
  }

  const [tree, setTree] = useState(generateTarget(sites, SPV));
  const [prmSelected, setPrmSelected] = useState([]);

  const onChangeTree = (newValue: string) => {
    setPrmSelected(newValue);
  };


  console.log('tree',tree)
  // Facture Tableau 


  const handleSend = record => {
    confirm({
      title: "Etes-vous sûr de vouloir envoyer cette facture ?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      onOk() {
        console.log('sending invoice', record)
        sendInvoice(record.invoice_id)
      },
      onCancel() {
      },
    })
  }

  const sendInvoice = async (invoice_id: string) => {
    console.log('sendInvoice()')
    const resp = await ApiService.SendInvoice({ invoice_id })
    if (resp.status == 200) {
      dispatch(fetchInvoices());
    }
  }



  const handleDelete = record => {
    confirm({
      title: "Etes-vous sûr de supprimer cette Alerte ?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      onOk() {
        console.log('delete subscription', record)
        deleteSubscription(record.id)
      },
      onCancel() {
      },
    })
  }


  const deleteSubscription = async (id: string) => {
    const resp = await ApiService.DeleteSubscription({ id })
    if (resp.status == 200) {
      dispatch(fetchSubscriptions());
    }
  }

  const genExtra = sub => (
    <Space>

      <Icon
        color={Colors.primary}
        title="Supprimer"
        className={"cursor-pointer"}
        style={{ right: 25, position: "relative" }}
        onClick={() => handleDelete(sub)}
        name={IconNames.delete}
      />

    </Space>
  )

  const PanelHeader = ({ data }) => (
    <Row className="flex items-center">
      <Col xs={24} sm={12} md={4} lg={6}>
        <Text className="text-gray-6f" type="12-500">
          Site
        </Text>
        <Text className="block" type="14-500">
          {data.site_name}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={4}>
        <Text className="text-gray-6f" type="12-500">
          Identifier
        </Text>
        <Text className="block" type="14-500">
          {data.identifier || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={4}>
        <Text className="text-gray-6f" type="12-500">
          Type
        </Text>
        <Text className="block" type="14-500">
          {data.alert_type || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={3}>
        <Text className="text-gray-6f" type="12-500">
          channel
        </Text>
        <Text className="block" type="14-500">
        {data.alert_channel || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={6}>
        <Text className="text-gray-6f" type="12-500">
          Address
        </Text>
        <Text className="block" type="14-500">
        {data.alert_address || "-"}
        </Text>
      </Col>


    </Row>
  )


  const columns = [
    {
      title: "product",
      dataIndex: "product",
      key: "product",
      responsive: ["sm"],
      width: '40%',
      render: (_, { product }) => (
        <span style={{ fontSize: '14px',fontWeight:'normal' }}>
          {product}
        </span>
      ),
    },
    {
      title: "volume",
      dataIndex: "volume",
      key: "volume",
      responsive: ["sm"],
      width: '15%',
      editable: true,
      render: (_, { volume }) => (
        <span style={{ fontSize: '14px',fontWeight:'normal' }}>
          {parseFloat(volume)} MWh
        </span>
      ),
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      responsive: ["sm"],
      width: '15%',
      render: (_, { price }) => (
        <span style={{ fontSize: '14px',fontWeight:'normal' }}>
          {parseFloat(price).toLocaleString('fr') + "€"}
        </span>
      ),
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
      responsive: ["sm"],
      width: '15%',
      render: (_, { amount }) => (
        <span style={{ fontSize: '14px',fontWeight:'normal' }}>
          {parseFloat(amount).toLocaleString('fr') + "€"}
        </span>
      ),
    },
    {
      title: "tva",
      dataIndex: "tva",
      key: "tva",
      responsive: ["sm"],
      width: '15%',
      render: (_, {tva }) => (
        <span style={{ fontSize: '14px',fontWeight:'normal' }}>
          {parseFloat(tva).toLocaleString('fr') + "%"}
        </span>
      ),
    }
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (

    //   ),
    // },
  ]


  const PanelBody = ({ data }) => {
    return (
      <Row style={{ marginTop: 20 }}>
        <Col flex={2}>

          <div style={{ marginBottom: 40, fontSize: '16px', fontWeight: '600' }}> Votre Facture à recevoir </div>
          {
            data.invoices_details.map((item) =>

              <>
                <div style={{ marginTop: 20,marginBottom: 20, fontSize: '16px', fontWeight: '600' }}> {item.reference_label} </div>
                <Table
                  size="small"
                  className="text-sm"
                  columns={columns}
                  dataSource={item.lines}
                  rowKey={"product"}
                  pagination={false}
                  style={{ width: '100%' }}
                  summary={pageData => {
                    let totalAmount = 0;

                    item.lines.forEach(({product,amount }) => {
                      if (product.includes("Frais")) {
                        totalAmount -= parseFloat(amount);
                      } else {
                        totalAmount += parseFloat(amount);
                      }
                      
                    });
            
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell className="text-md font-bold">Total</Table.Summary.Cell>
                          <Table.Summary.Cell>
                          <div className="text-md">-</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                          <div className="text-md">-</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                          <div className="text-md font-bold">{totalAmount.toFixed(2).toLocaleString('fr') + "€"}</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                          <div className="text-md">-</div>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </>
            )
          }


        </Col>

        <Col flex={1}>
        </Col>

        <Col flex={2}>

          {data.status === 'en edition' ?
            <>
              <TimelineComponent data={data} />
            </>
            :
            data.status === 'envoyé au client' ?
              <>
                <TimelineComponent data={data} />
              </>
              :
              data.status === 'validé client' ?
                <>

                  <div style={{ marginBottom: 20, fontSize: '16px', fontWeight: '600' }}> Notre Validation </div>

                  <FormReviewComponent data={data} />

                  <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                </>
                :
                data.status === "validé bohr" ?
                  <>
                    
                    <TimelineComponent data={data} />
                    <FormPaidComponent data={data} />
                    <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                  </>
                  :
                  data.status === "conflit" ?
                    <>
                      <TimelineComponent data={data} />
                    </>
                    :
                    data.status === "payé" ?

                      <>
                        <TimelineComponent data={data} />
                        <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                      </>

                      :
                      <></>

          }

        </Col>




      </Row>
    )

  }



  console.log('subscriptions',subscriptions)

  return (

    <Suspense fallback={<div> Please Wait... </div>} >

      <div className="UserTable px-6 py-4 rounded-xl bg-white mb-15">
        <div className="md:flex justify-between  items-center pb-4">
          <div className="text-base font-semibold	mb-4 md:mb-0">
            Ajouter une Alerte
          </div>
        </div>

        <Space direction="vertical">

          <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            style={{ marginTop: '0px' }}
            initialValues={{ role: 'admin' }}>




            <Form.Item
            name="identifier"
            label={<label style={{ color: "black" }}>Nom</label>}
            rules={[
              {
                required: true,
                message: 'Votre nom est requis',
              },
            ]}
          >
            <Input placeholder="Dupont" />
          </Form.Item>

          <Form.Item name="type"  label={<label style={{ color: "black" }}>Type</label>}>
          <Select>
            <Select.Option value="negative_prices">negatives prices</Select.Option>
            <Select.Option value="ppx">peak period</Select.Option>
          </Select>
         </Form.Item>

          <Form.Item name="channel"  label={<label style={{ color: "black" }}>Channel</label>}>
          <Select>
            <Select.Option value="mail">mail</Select.Option>
            <Select.Option value="phone">phone</Select.Option>
          </Select>
         </Form.Item>

          <Form.Item
            name="address"
            label={<label style={{ color: "black" }}>Numéro / Email</label>}
            rules={[
              {
                required: true,
                message: 'Numéro / Email est requis',
              },
            ]}
          >
            <Input placeholder="Dupont" />
          </Form.Item>

          <Form.Item
              name="Sites"
              label={<label style={{ color: "black" }}>Sites</label>}
              rules={[
                {
                  required: true,
                  message: "Vous devez choisir au moins un site",
                },
              ]}
            >
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                value={prmSelected}
                dropdownStyle={{ overflow: 'auto' }}
                placeholder="Please select"
                allowClear
                multiple
                treeCheckable
                treeDefaultExpandAll
                onChange={onChangeTree}
                treeData={tree} />
            </Form.Item>

            <Form.Item >
              <Button htmlType="submit" style={{ color: "black" }}>
                Ajouter l'Alerte
              </Button>
            </Form.Item>

          </Form>
        </Space>

      </div>


      <div className="UserTable px-6 py-4 rounded-xl bg-white my-8">
        <div className="md:flex justify-between  items-center pb-4">
          <div className="text-base font-semibold	mb-4 md:mb-0">
            Alerts
          </div>





          <div style={{ cursor: 'pointer' }}>
            <SyncOutlined onClick={() => dispatch(fetchSubscriptions())} />
          </div>


        </div>



        <Collapse
                // defaultActiveKey={["0"]}
                onChange={onChange}
                accordion
                expandIconPosition="end"
                ghost
                collapsible="icon"

                expandIcon={({ isActive }) => (
                  <Icon name={isActive ? "arrow-up" : "arrow-down"} />
                )}
              >
                {subscriptions.map((sub: any, index: number) => {
                    return (
                      <Panel
                        header={<PanelHeader data={sub} />}
                        key={index + ""}
                        extra={genExtra(sub)}
                      >
                        {/* <PanelBody data={invoice} /> */}
                      </Panel>
                    );
                  })
                }
                </Collapse>
    </div>


    </Suspense>
  )
}
function setSPV(companies: any) {
  throw new Error("Function not implemented.");
}

