// const initState = { loading: false, data:null, volume: null ,error: null }


const initState = { 
  ncc : {
    loading: false, 
    data:null,
    error: null 
  }
}

const nccReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "LOAD_NCC":
      return {
        ...state,
        ncc: {
          ...state.ncc,
          loading: true,
          data: null,
          error: null
        }
      }
    case "LOAD_NCC_SUCCESS":
      return {
        ...state,
        ncc: {
          ...state.ncc,
          loading: false,
          data: action.payload,
          error: null
        }
      }
    case "LOAD_NCC_ERROR":
      return {
        ...state,
        ncc: {
          ...state.ncc,
          loading: false,
          data: null,
          error: action.payload
        }
      }
    default:
      return state

  }
}

export default nccReducer
