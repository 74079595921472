import React, { useEffect, useState } from "react"
import { Collapse, Col, Row, Avatar, Spin, Tag, Card, Segmented, Input, Tooltip, Button, Form, Checkbox, FloatButton, TreeSelect, Space } from "antd"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchContracts, fetchSitesDetail, fetchHydrofrance, fetchProductionUnits, fetchMeteoCiel, fetchEnedisR4Q } from "../../redux/actions"
import moment from "moment"
import toast from "react-hot-toast"

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { CaretRightOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import DemoForm from "./DemoForm"
import QuickAnalysis from "./QuickAnalysis"
import ApiService from "../../services/ApiService"
import { FiDownload } from "react-icons/fi";

const url = process.env.API_URL

const { RangePicker } = DatePicker;

const Gos = () => {

  const [form] = Form.useForm()
  const [data, setData] = useState();



  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map((row) => Object.values(row).join(',') + '\n');
    return header + rows.join('');
  }


  const onFinish = async (values: any) => {




    console.log('onFinish', values)
    const dateDebut = moment(`${values.Mois.$y} ${values.Mois.$M + 1}`, "YYYY MM").startOf('month').format('YYYY-MM-DD');
    const month = moment(`${values.Mois.$y} ${values.Mois.$M + 1}`, "YYYY MM").startOf('month').format('YYYYMM');
    const dateFin = moment(`${values.Mois.$y} ${values.Mois.$M + 1}`, "YYYY MM").endOf('month').add(1, 'days').format('YYYY-MM-DD');


    const prm = values.prm
    const insert = false
    const restore = true
    const mesuresCorrigees = 'True'
    const sens = 'INJECTION'

    console.log('GetEnedisR4Q',{ prm, dateDebut, dateFin, insert, restore,mesuresCorrigees,sens })
    toast.promise(ApiService.GetEnedisR4Q({ prm, dateDebut, dateFin, insert, restore,mesuresCorrigees,sens }), {
      loading: `Loading - Enedis API - ${values.prm}`,
      success: (data) => {
        const onSuccess = data.data.data;
        console.log('onsuccess', onSuccess);
        setData(onSuccess)

        const csvData = convertToCSV(onSuccess.details);

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${values.Sites}_${prm}_${month}.txt`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        return ("=]")
        // setLoading(false)
      },
      error: (err) => {
        const onError = err.response.data.message;
        // setError(onError)
        // setLoading(false)
      }

    });



  };

  const onChange = (key) => {
    console.log(key)
  };

  return (
    <>
      <div className="md:flex justify-between  items-center pb-4">
        <div className="text-base font-semibold	mb-4 md:mb-0">
          Via Market Services
        </div>
      </div>


      <div className="flex all-Campton">


        <Space direction="vertical">

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{ marginTop: '0px' }}
            initialValues={{ role: 'admin' }}>

            <Form.Item
              name="Mois"
              label={<label style={{ color: "black" }}>Mois</label>}
              rules={[
                {
                  required: true,
                  message: "Vous devez choisir un mois",
                },
              ]}
            >
              <DatePicker onChange={onChange} picker="month" format="YYYY-MM" />
            </Form.Item>

            <Form.Item
              name="Sites"
              label={<label style={{ color: "black" }}>Sites</label>}
              rules={[
                {
                  required: true,
                  message: "Vous devez choisir au moins un site",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="prm"
              label={<label style={{ color: "black" }}>PRM</label>}
              rules={[
                {
                  required: true,
                  message: "Vous devez indiquer le PRM",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item >
              <Button htmlType="submit" style={{ color: "black" }}>
                Récuperer le Total MWh + Fichier
              </Button>
            </Form.Item>

          </Form>
        </Space>




      </div>


      {data ?


data.total_month.map((item)=> (
                  <div>
                  Pour le mois {moment(item['start_time']).format('YYYY-MM')} la production totale est de {item['volume'].toLocaleString('fr')} KWh
                  </div>))
                  



        :
        <></>

      }



    </>
  )
}
export default Gos
