import React, { useEffect, useState } from "react"
import { Collapse, Col, Row, Avatar, Spin, Tag, Card, Segmented, Input, Tooltip, Button, Form, Checkbox, FloatButton } from "antd"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchContracts, fetchSitesDetail, fetchHydrofrance, fetchProductionUnits, fetchMeteoCiel, fetchEnedisR4Q } from "../../redux/actions"
import moment from "moment"
import toast from "react-hot-toast"

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { CaretRightOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import DemoForm from "./DemoForm"
import QuickAnalysis from "./QuickAnalysis"
import ApiService from "../../services/ApiService"


const url = process.env.API_URL

const { RangePicker } = DatePicker;

const Demo = () => {


  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const [error, setError] = useState('');



  const onFinish = (values: any) => {
    console.log(values)
    // const dateDebut = moment().subtract(4, 'weeks').format('YYYY-MM-DD')
    const dateFin = moment().format('YYYY-MM-DD')

    const dateDebut = moment().startOf('year').format('YYYY-MM-DD');

    setError('')
    setLoading(true)

    // postAPI(values)(
    //   (onSuccess) => {
    //   console.log(onSuccess)
    //   setData(onSuccess)
    //   setLoading(false)
    //   },
    //   (onError) => {
    //     setError(onError)
    //     setLoading(false)
    //   }
    // );

    const prm = values.prm

    toast.promise(ApiService.GetEnedisSimulation({ prm, dateDebut, dateFin }), {
      loading: `Loading - Enedis API - ${values.prm}`,
      success: (data) => {
        const onSuccess = data.data.data;
        console.log('onsuccess', onSuccess);
        setData(onSuccess)
        setLoading(false)
      },
      error: (err) => {
        const onError = err.response.data.message;
        setError(onError)
        setLoading(false)
      }

    });



  };

  return (
    <>
      <div className="md:flex justify-between  items-center pb-4">
        <div className="text-base font-semibold	mb-4 md:mb-0">
          Market Services
        </div>
      </div>


      <div className="flex all-Campton">

        <div className="w-full md:p-10 p-5">



          <div className="py-0">



            {Object.keys(data).length === 0 ?

              loading ?
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '10px', justifyContent: 'center', color: 'black', fontSize: '36px', margin: '20px' }} >
                    Nous récupérons vos datas
                    <br></br>
                    Cela peut prendre 1 minute
                    <Spin size="large" style={{ color: 'red', marginLeft: '20px' }} />
                  </div>
                </>

                :

                <div>
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '20px', color: 'black', marginTop: '0px', marginBottom: '50px', margin: '0px', justifyContent: 'center', alignItems: 'center' }}>
                  <strong style={{ fontSize: '50px', lineHeight: 'normal', marginTop: '15px' }}>Tester votre centrale</strong>
                  <div style={{ fontSize: '40px', lineHeight: 'normal', marginTop: '15px' }}>
                    Quels sont vos revenus au marché ?
                  </div>
                </div>
      
                <div style={{ height: '50px' }}></div>

                <DemoForm onFinish={onFinish} error={error} />
                </div>
              :


              <>

                <QuickAnalysis data={data} loading={loading} />



                <br></br>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
                  <Button style={{ color: "black" }} onClick={() => setData({})}>
                    Essayer un autre PRM
                  </Button>
                </div>

              </>


            }

          </div>
        </div>





      </div>

    </>
  )
}
export default Demo
