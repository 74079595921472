import React, { useEffect, useState } from "react"
const url = process.env.API_URL
import UserService from "../../services/UserService"
import { Colors, Icon, IconNames } from "../Icon";

interface P {
  data : any
}

const  UtilFactureComponent: React.FC<P> = ({ data,setmodalPdfOpen,setPdfContent }) => {

  const headers = {
    Authorization: `Bearer ${UserService.getToken()}`,
  }

  async function handleViewClick(record) {

    console.log('handleViewClick', record)

    const downloadurl = `${url}accounts/download_invoice?file_id=${record}`
    console.log('downloadurl', downloadurl)

    try {
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: `Bearer ${UserService.getToken()}` },
      };
      const response = await fetch(downloadurl, requestOptions)
      const blob = await response.blob()

      // Create a URL object from the blob and set it as the source of the iframe in the Modal
      const objectURL = URL.createObjectURL(blob)
      const iframe = document.createElement("iframe")
      iframe.src = objectURL
      iframe.style.width = "100%"
      iframe.style.height = "500px"

      setmodalPdfOpen(true)
      setPdfContent(
        <div style={{ height: "100vh" }}>
          <iframe
            src={objectURL}
            style={{ width: "90%", height: "100%", margin: "auto" }}
          />
        </div>
      )
    } catch (error) {
      console.error(error)
    }
  }

  function handleDownloadClick(record) {

    const downloadurl = `${url}accounts/download_invoice?file_id=${record.file_id}`

    fetch(downloadurl, { headers })
      .then(response => {
        // Convert the response to a blob object
        return response.blob()
      })
      .then(blob => {
        // Create a temporary URL object from the blob
        const url = URL.createObjectURL(blob)

        // Create a link and click it to download the PDF
        const link = document.createElement("a")
        link.href = url
        // link.download = `Invoice-${record.site}-${record.creation_date}.pdf`
        link.download = `F_${record.invoice_id}.pdf`
        link.click()

        // Clean up the temporary URL object
        URL.revokeObjectURL(url)
      })
      .catch(error => {
        console.error("Failed to download PDF:", error)
      })
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>

        <div style={{ marginRight: '20px' }}>Votre facture :</div>

        <Icon
          className="cursor-pointer"
          title="View"
          color={Colors.primary}
          style={{ marginRight: '20px' }}
          name={IconNames.eye}
          onClick={() => handleViewClick(data.file_id)}
        />

        <Icon
          className="cursor-pointer"
          title="Telecharger"
          style={{ marginRight: '20px' }}
          color={Colors.primary}
          name={IconNames.download}
          onClick={() => handleDownloadClick(data)}
        />

        </div>
    </>
  )
}
export default UtilFactureComponent
