
import React, { useEffect, useState } from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  Scatter,
  Text as TextR,
  ResponsiveContainer,
  ZAxis,
  ScatterChart,
  BarChart,
  Bar,
} from 'recharts';
import moment from "moment"
import Text from '../Text';
import { stringToHexColor } from "../../utils";

interface P {
  data: any,
}

const PrevisionChart: React.FC<P> = ({ data, site, type ,keyArray}) => {

  console.log('prevision',site,type,keyArray)
  
  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="text-white rounded-lg p-2"
          style={{ background: "#20263D" }}
        >
          {payload.map((p: any) => (
            <div>
              <Text type="12-500">{moment(p.payload.start_time).format('DD MMM HH:00')}</Text> <br />
              <Text type="14-600">
                {p.payload.volume.toLocaleString("fr")} kwh
              </Text>
            </div>
          ))}
        </div>
      )
    }

    return null
  }

  const CustomTooltipRiver = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="text-white rounded-lg p-2"
          style={{ background: "#20263D" }}
        >
          {payload.map((p: any) => (
            <div>
              <Text type="12-500">{moment(p.payload.start_time).format('DD MMM HH:00')}</Text> <br />
              <Text type="14-600">

                {p.payload[keyArray] && 
                p.payload[keyArray].toLocaleString("fr")}
              </Text>
            </div>
          ))}
        </div>
      )
    }

    return null
  }

  return (

    <>
    <p>Production Enedis</p>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart 
      width={650} 
      height={80} 
      data={data}
      syncId="anyId">
        <YAxis type="number"
          orientation={"left"}
          tickLine={false}
          axisLine={false}
          tickFormatter={value =>
            new Intl.NumberFormat("fr", {
              notation: "compact",
              compactDisplay: "short",
            }).format(value)
          }
          domain={["0", "dataMax"]}
        />
        {/* <XAxis tickLine={false} axisLine={false} dataKey="start_time" tickFormatter={value => moment(value).format('DD MMM HH:00')}/> */}
        <Bar dataKey="volume" fill={stringToHexColor(site.name)} />
        <Tooltip
          content={p => <CustomTooltip {...p} />}
          cursor={{ fill: "transparent" }}
        />
      </BarChart>
    </ResponsiveContainer>
    <br></br>
    <p>{type == 'hydro' ? "Hauteur d'eau " + data[0]?.label_station :
    type == 'eolien' ? "Vent Moyen " :
    type == 'solar' ? "Radiation" : ""} </p>
    <ResponsiveContainer width="100%" height={100}>
    <BarChart 
    width={650} 
    height={80} 
    data={data}
    syncId="anyId">
      <YAxis type="number"
        orientation={"left"}
        tickLine={false}
        axisLine={false}
        tickFormatter={value =>
          new Intl.NumberFormat("fr", {
            notation: "compact",
            compactDisplay: "short",
          }).format(value)
        }
        domain={["0", "dataMax"]}
      />
      <XAxis tickLine={false} axisLine={false} dataKey="start_time" tickFormatter={value => moment(value).format('DD MMM HH:00')}/>
      <Bar 
          dataKey={keyArray} 
          fill={stringToHexColor(site.name)} 
        />
      <Tooltip
        content={p => <CustomTooltipRiver {...p} />}




        
        
        
        cursor={{ fill: "transparent" }}
      />
    </BarChart>
    </ResponsiveContainer>

    </>

  );

};
export default PrevisionChart;