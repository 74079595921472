import React from "react";
import {
  Area,
  XAxis,
  BarChart,
  Legend,
  YAxis,
  Bar,
  ComposedChart,
  Text as TextR,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import Text from "../Text"



interface P {
  unit: any,
  maxTickNumber: any,
  customTooltip: any, data: any, dataKey1: any, dataKey2: any, dataKeyRender1: any, dataKeyRender2: any
}

const EnergieChart: React.FC<P> = ({ unit, maxTickNumber, customTooltip, data, dataKey1, dataKey2, dataKeyRender1, dataKeyRender2 }) => {

  const [chartdata, setChartData] = React.useState(data);

  React.useEffect(() => {
    setChartData(data)
  }, [data]);

  React.useEffect(() => {
    const data_int = chartdata.slice(0, maxTickNumber).map(item => { return { ...item, [dataKey1]: parseInt(item[dataKey1]), [dataKey2]: parseInt(item[dataKey2]) } })
    setChartData(data_int)
  }, []);

  const payloadMapper = payload => {
    const power = payload[0].payload.power_gain
    const gos = payload[0].payload.gos_gain
    const mecapa = payload[0].payload.mecapa_gain
    return [
      {
        title: "Production électrique",
        data: power,
      },
      {
        title: "Garanties d’origines",
        data: gos,
      },
      {
        title: "Mécanisme de capacité",
        data: mecapa,
      },
    ]
  }

  const renderLabel = (label: string) => {
    if (label == dataKey1) return dataKeyRender1
    if (label == dataKey2) return dataKeyRender2
    return label
  }

  const Value = ({ value = 0, desc = "" }) => (
    <div>
      {" "}
      <small>{renderLabel(desc)}</small>{" "}
      <Text type="14-600" className="mr-2">
        {value.toLocaleString("fr")} €
      </Text>
    </div>
  )

  const CustomTooltip = ({ active, payload, label }) => {


    if (active && payload && payload.length) {

      if (customTooltip) {
        const data = payloadMapper(payload)
        return (
          <>

            <div
              className="text-white rounded-lg p-2"
              style={{ background: "#20263D" }}
            >
              <div><Text type="14-600">{payload[0]['payload']['name']}</Text> </div><br />
              {data.map(({ title, data }) => (
                <div className="mb-2">
                  <Text type="12-500">{title}</Text> <br />
                  <Value value={parseInt(data).toLocaleString("fr")} />
                </div>
              ))}
            </div>
          </>
        )
      } else {
        return (

          <div
            className="text-white rounded-lg p-2"
            style={{ background: "#20263D" }}
          >
            <div><Text type="14-600">{payload[0]['payload']['name']}</Text> </div><br />
            {payload.map(p => (
              <div>
                <Text type="12-500">{renderLabel(p.name)}</Text> :
                <Text type="14-600">  {parseInt(p.value).toLocaleString("fr")} MWh</Text>
              </div>
            ))}
          </div>
        )
      }
    }

    return null
  }


  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <TextR x={0} y={0} dy={16} width={90} height={70} textAnchor="middle" verticalAnchor="start" fill="#666">
          {payload.value}
        </TextR>
      </g>
    )
  }



  return (

    <>
    <div style={{
      display: "flex",
      justifyContent: "end",
      margin: "20px",
      top: "-35px"
    }}>

        <div key={1} style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <div style={{ backgroundColor: 'green', width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px' }}></div>
          <span>Marché</span>
        </div>
        <div key={1} style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <div style={{ backgroundColor: '#005599', width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px' }}></div>
          <span>OA</span>
        </div>

    </div>
    
    <ResponsiveContainer width="100%" height={300}>

      <BarChart width={730} height={250} data={chartdata} style={{ background: "white" }}
        margin={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="2 2" vertical={false} />
        <XAxis tickLine={false} axisLine={false} height={78} minTickGap={0} interval={0} tick={<CustomizedAxisTick />} dataKey="name" />


        <YAxis
          width={70}
          unit={unit}
          tickLine={false}
          axisLine={false}
          domain={[dataMin => (Math.abs(dataMin) / 1.5), dataMax => (dataMax * 1)]}
          tickFormatter={(value) =>
            new Intl.NumberFormat("fr", {
              notation: "compact",
              compactDisplay: "short",
            }).format(value)
          }
        />
        <Tooltip
          content={p => <CustomTooltip {...p} />}
          cursor={{ fill: "transparent" }}
        />


        {dataKey1 !== '' ?

          <Bar
            dataKey={dataKey1}
            enableBackground={"x"}
            fill="#82ca9d"
            barSize={12}
            isAnimationActive={false}
            radius={[20, 20, 20, 20]}
          />

          :
          <></>

        }

        <Bar
          dataKey={dataKey2}
          enableBackground={"x"}
          isAnimationActive={false}

          barSize={12}
          radius={[20, 20, 20, 20]}

        >
          {chartdata.map((entry, index) => (
            <Cell fill={entry.contract_type === "Marché" ? '#82ca9d' : '#005599'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
    </>
  );
};

export default EnergieChart;