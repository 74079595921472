import { Button, Checkbox, Form, Input } from "antd"
import TextArea from "antd/es/input/TextArea"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import ApiService from "../../services/ApiService"
import { fetchInvoices } from "../../redux/actions"

interface P {
  data: any
}

const FormReviewComponent: React.FC<P> = ({ data }) => {

  const dispatch = useDispatch()

  const [formReview] = Form.useForm()

  const onFinishReview = async (values: any) => {
    const body = values
    console.log('onFinish', body)
    validateInvoiceAdmin(body)
  };

  const validateInvoiceAdmin = async (body: object) => {
    console.log('validateInvoiceAdmin',body)
    const resp = await ApiService.ValidateInvoiceAdmin(body)
    if (resp.status == 200) {
      dispatch(fetchInvoices());
    }
  }

  const resetInvoice = async (invoice_id: string) => {
    console.log('resetInvoice')
    const resp = await ApiService.ResetInvoice({'invoice_id' : invoice_id})
    if (resp.status == 200) {
      dispatch(fetchInvoices());
    }
  }


  return (
    <>
      <Form
        form={formReview}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onFinishReview}
        initialValues={{
          invoice_id: data.invoice_id ,
          file_id : data.file_id
        }}
      >

        <Form.Item label="Référence" name="invoice_id" hidden={true}>
          <Input disabled defaultValue={data.invoice_id} />
        </Form.Item>

        <Form.Item label="file_id" name="file_id" hidden={true}>
          <Input disabled defaultValue={data.file_id} />
        </Form.Item>
        
        <Form.Item label="Commentaire Client" name="comment_client">
          <Input disabled defaultValue={data.validated_comment} />
        </Form.Item>

        <Form.Item label="Commentaire" name="comments">
          <TextArea rows={1} />
        </Form.Item>
        <Form.Item
          name="accord"
          label="Votre accord"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "votre accord est requis",
            },
          ]}>
          <Checkbox>Je suis d'accord avec la facture  du client</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">Je suis d'accord, je valide</Button>
        </Form.Item>
      </Form>

      
      <Button style={{marginBottom:'30px'}} onClick={ () => resetInvoice(data.invoice_id) }>Je ne suis pas d'accord, je renvoie au niveau précédent (facture client)</Button>

      <br></br>

    </>
  )
}
export default FormReviewComponent
