import React, { useEffect, useState } from "react"
import { Router, useLocation } from "@reach/router"
import Dashboard from "./views/Dashboard"
import TopBar from "./components/TopBar"
import Login from "./views/login"
import ForgetPassword from "./views/ForgetPassword"
import Register from "./views/Register"
import Users from "./components/Manage/Users"
import Clients from "./views/Clients"

import Budget from "./views/Budget"

import GestionAdmin from "./views/GestionAdmin"
import SimulationAdmin from "./views/SimulationAdmin"
import ActivationForm from "./components/ActivationForm"
import ForgotPasswordForm from "./components/ForgotPasswordForm"

import PrivateRoute from "./components/PrivateRoute"
import PublicRoute from "./components/PublicRoute"

import PageOne from "./views/step"
import Marche from "./views/Marche"
import Analyses from "./views/Analyses"
import Previsions from "./views/Previsions"
import Historique from "./views/Historique"
import Gestion from "./views/Gestion"
import PasswordRecovery from "./views/PasswordRecovery"
import NotFoundPage from "../pages/404"

import ApiService from "./services/ApiService"
import { setCurrentUser } from "./redux/actions/auth"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { fetchCompanies, fetchRoles } from "./redux/actions/commun"

import moment from "moment"
import Benchmark from "./views/VueEnsemble"
import Live from "./views/EnDirect"
import VueEnsemble from "./views/VueEnsemble"
import openSocket from 'socket.io-client';
import EnDirect from "./views/EnDirect"
import Switch from "./views/Switch"
import NettingREAdmin from "./views/NettingREAdmin"
import BatterieAdmin from "./views/BatterieAdmin"


const currentversion = process.env.GATSBY_APP_VERSION


moment.locale("fr", {
  months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
    "_"
  ),
  monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
    "_"
  ),
  monthsParseExact: true,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd’hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e")
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M"
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD"
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
})




export default () => {
  const [loading, setloading] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()

  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.clear()
    dispatch(setCurrentUser(null))
    window.location.href = "/app/login"
    // navigate("/app/login")
  }

  useEffect(() => {

    console.log('checking version')
    if (localStorage.getItem('version')) {
      const browserversion = localStorage.getItem('version')
      console.log('version', currentversion, browserversion)
      if (currentversion !== browserversion) {
        console.log('version is not the last one')
        localStorage.clear()
      } else {
        console.log('version is up to date')
      }

    } else {
      console.log('no version in local storage', currentversion)
      localStorage.setItem("version", currentversion)
    }

    const PUBLIC_PATHS = [
      `/app/login`,
      "/app/subscribe",
      "/app/forget-password",
      "/app/password_recovery",
      "/app/activate",
      "/app/forgotPassword",
    ]


    const hasValidToken = async () => {
      try {
        setloading(true)

        const res = await ApiService.GetRoles()
        setloading(false)
      } catch (error) {

        logout()
      }
    }


    PUBLIC_PATHS.every(item => location?.pathname.indexOf(item) < 0) &&
      hasValidToken()

  }, [])

  if (loading) return null

  return (
    <Router>
      <NotFoundPage default />
      <PrivateRoute admin={true} path="/app" component={Dashboard} />
      <PrivateRoute admin={true} path="/app/dashboard" component={Dashboard} />
      <PrivateRoute superadmin={true} path="/app/clients" component={Clients} />
      <PrivateRoute superadmin={true} path="/app/users" component={Users} />
      <PrivateRoute superadmin={true} path="/app/simulation" component={SimulationAdmin} />
      <PrivateRoute superadmin={true} path="/app/netting" component={NettingREAdmin} />
      <PrivateRoute superadmin={true} path="/app/batterie" component={BatterieAdmin} />
      <PrivateRoute superadmin={true} path="/app/budget" component={Budget} />
      <PrivateRoute superadmin={true} path="/app/admin" component={GestionAdmin} />


      <PrivateRoute admin={true} path="/app/marche" component={Marche} />
      <PrivateRoute admin={true} path="/app/analyses" component={Analyses} />
      <PrivateRoute admin={true} path="/app/previsions" component={Previsions} />
      <PrivateRoute admin={true} viewer={true} path="/app/ensemble" component={VueEnsemble} />
      <PrivateRoute admin={true} viewer={true} path="/app/direct" component={EnDirect} />
      <PrivateRoute admin={true} viewer={true} path="/app/historique" component={Historique} />
      <PrivateRoute admin={true} path="/app/manage" component={Gestion} />
      <PrivateRoute admin={true} viewer={true} path="/app/switch" component={Switch} />



      {/* <PrivateRoute path="/app/portefeuille" component={Portefeuille} /> */}
      <PublicRoute path="/app/login" component={Login} />
      <PublicRoute path="/app/subscribe/:token" component={Register} />
      <PublicRoute path="/app/forget-password" component={ForgetPassword} />
      <PublicRoute
        path="/app/password_recovery/:token"
        component={PasswordRecovery}
      />
      {/* <PublicRoute path="/app/subscribe" component={SubscribeForm} /> */}
      <PublicRoute path="/app/activate" component={ActivationForm} />
      <PublicRoute path="/app/forgotPassword" component={ForgotPasswordForm} />
    </Router>
  )
}
