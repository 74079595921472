import React, { useEffect, useState } from "react"
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Space, Typography } from 'antd';
import BatterieChart from "./BatterieChart";


const Batterie = () => {

  const [form] = Form.useForm();

  const [formData, setFormData] = useState({});
  const [chartData, setChartData] = useState({});

  function generateDegradationData(formData) {
    let results = {};





    formData.items.forEach((item, index) => {
      if (item && item.start && item.end ) {
        const startYear = new Date(item.start).getFullYear();
        const endYear = new Date(item.end).getFullYear();
        const itemName = index
        let itemData = [];


        // Calculate degradation for each year
        for (let year = startYear; year <= endYear; year++) {
          let yearData = { year };


            const startYear = new Date(item.start).getFullYear();
            const endYear = new Date(item.end).getFullYear();
            const degradationRate = parseFloat(item.degradation) || 0;
            const outage_pct = parseFloat(item.outage) / 100 || 0;
            const cycle = parseFloat(item.cycle) || 0;
            const capacity = parseFloat(item.capacity) || 0;
            const spread = parseFloat(item.spread) || 0;
            const duration = parseFloat(item.duration) || 0;
            const turpe = parseFloat(item.turpe) || 0;
            const efficiency = parseFloat(item.efficiency) || 0;
            const prix_mc = parseFloat(item.prix_mc) || 0;
            const filiere = parseFloat(item.filiere) || 0;
            const parallel = parseFloat(item.parallel) || 1;


            if (year >= startYear && year <= endYear) {
              const yearsSinceStart = year - startYear;
              const degradation = Math.round(100 * Math.pow((1 - degradationRate / 100), yearsSinceStart))
              const cycle_per_year = Math.round(degradation / 100 * cycle * (1 - outage_pct))
              const V_consumed_year = cycle_per_year * degradation / 100 * duration * capacity
              const V_injected_year = V_consumed_year * (efficiency / 100)
              const turpe_cost_year = turpe * V_injected_year //Taxe sur l'injection
   
              // yearData[`degradation_${itemName}`] = degradation ; //
              // yearData[`cycle_${itemName}`] = cycle_per_year
              // yearData[`Marge_${itemName}`] = Math.round(cycle_per_year*spread*capacity*degradation/100*duration - turpe_cost_year)

              yearData[`Spread`] = parallel * Math.round(cycle_per_year * spread * capacity * duration * efficiency/100)
              yearData[`Degradation_Cost`] = parallel * (Math.round(cycle_per_year * spread * capacity * (degradation / 100) * duration) - Math.round(cycle_per_year * spread * capacity * duration))
              yearData[`Turpe_Cost`] = -Math.round(turpe_cost_year) * parallel
              yearData[`Meca Capa`] = parallel * Math.round(prix_mc*filiere/100*(degradation / 100)*0.98*capacity)

              yearData[`V_consumed`] = parallel* Math.round(V_consumed_year)
              yearData[`V_injected`] = parallel *  Math.round(V_injected_year)
              yearData[`degradation`] = degradation ; //
              // yearData[`Turpe_Cost`] = -Math.round(turpe_cost_year)

            }


          itemData.push(yearData);
        }
        console.log(itemName,itemData)
        results[itemName] = itemData;
      } // end of if item start and end date
      });
     

    console.log(results)
    return results;
  }

  const onFormChange = () => {
    const z = form.getFieldsValue()
    setFormData(z);
    const result = generateDegradationData(z)
    setChartData(result)

  };

  console.log(chartData)

  return (
    <>


      <div className="flex all-Campton" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '20px', paddingBottom: '20px' }}>

        <Form
          // labelCol={{ span: 6 }}
          // wrapperCol={{ span: 18 }}
          form={form}
          name="dynamic_form_complex"
          // style={{ maxWidth: 600 }}
          autoComplete="off"
          onValuesChange={onFormChange}
          initialValues={{ items: [{}] }}
        >
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <div style={{ display: 'flex', width: '100%', gap: '10px', flexDirection: 'row' }}>
                {fields.map((field,index) => (
                  <Card
                    className="w-full md:w-1/2"
                    style={{ width: '800px', padding: '0px' }}
                    size="small"
                    title={`Batterie ${field.name + 1}`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                  {/* <div>{field.key}</div>
                 <Form.Item style={{ marginBottom: '10px' }} label="Batterie" name={[field.name, 'batterie']}>
                      <Input defaultValue={`Batterie ${field.name + 1}`} />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: '10px' }} label="Name" name={[field.name, 'name']}>
                      <Input defaultValue={`Batterie ${field.name + 1}`} />
                    </Form.Item> */}

                    <Row gutter={10}>
                      <Col span={8}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Start" name={[field.name, 'start']}>
                          <DatePicker picker="year" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item style={{ marginBottom: '10px' }} label="End" name={[field.name, 'end']}>
                          <DatePicker picker="year" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Parallele" name={[field.name, 'parallel']}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Output Level" name={[field.name, 'output']}>
                          <InputNumber prefix="MW" min={1} max={200} defaultValue={3} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Input Level" name={[field.name, 'input']}>
                          <InputNumber prefix="MW" min={1} max={200} defaultValue={3} />
                        </Form.Item>
                      </Col>
                    </Row> */}

                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Capacity" name={[field.name, 'capacity']}>
                          <Input prefix="MW" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Duration" name={[field.name, 'duration']}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Efficiency" name={[field.name, 'efficiency']}>
                          <Input prefix="%" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Cycle/Year" name={[field.name, 'cycle']}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>


                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Degradation/Year" name={[field.name, 'degradation']}>
                          <Input prefix="%" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Outage/Year" name={[field.name, 'outage']}>
                          <Input prefix="%" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Turpe" name={[field.name, 'turpe']}>
                          <Input prefix="€" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Spread" name={[field.name, 'spread']}>
                          <Input prefix="€" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Prix MC" name={[field.name, 'prix_mc']}>
                          <Input prefix="€" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: '10px' }} label="Coeff Filiere" name={[field.name, 'filiere']}>
                          <Input prefix="%" />
                        </Form.Item>
                      </Col>
                    </Row>
                    

                    <BatterieChart data={chartData[Object.keys(chartData)[index]]} />

                  </Card>
                ))}

                <Button style={{width:'100px'}} type="dashed" onClick={() => add()} block>
                  + Add Item
                </Button>
              </div>
            )}
          </Form.List>

          {/* <Form.Item noStyle shouldUpdate>
            {() => (
              <Typography>
                <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
              </Typography>
            )}
          </Form.Item> */}
        </Form>






      </div>

      {/* <BatterieChart data={chartData} /> */}

    </>
  )
}

export default Batterie
