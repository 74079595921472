import ApiService from "../services/ApiService"

const getToken = () => typeof window !== 'undefined' && localStorage.getItem("token");

const isLoggedIn = () => typeof window !== 'undefined' && !!localStorage.getItem("token") //TODO: Verifier if token ok

const isSuperadmin = () => isLoggedIn() && !!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")||'null')?.user_role == 'superadmin'
const isViewer = () => isLoggedIn() && !!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")||'null')?.user_role == 'viewer'
const isAdmin = () => isLoggedIn() && !!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")||'null')?.user_role == 'admin' 
               
const loggedInfo  = () =>  { if (isLoggedIn()) {return localStorage} else {return null} }


const UserService = {
  isLoggedIn,
  getToken,
  isSuperadmin,
  loggedInfo,
  isAdmin,
  isViewer
};

export default UserService;
