import { IRegister } from "../interfaces"
import HttpService from "./HttpService"

const url = process.env.API_URL
console.log('process.env.API_URL',process.env.API_URL)

// Validate token
const isLoggedIn = () =>
  HttpService.get(`${url}application/isLoggedIn`).then(res => {
    if (res.data.data == "valid token") {
      return true
    } else {
      return false
    }
  })

// Log in
const Login = (body: { username: string; password: string }) =>
  HttpService.post(`${url}users/login`, body)

const SendSmsAuth = (token: string, phone: string) =>
  HttpService.get(`${url}users/send_sms_auth`, { token, phone })

const SendSmsVerification = () =>
  HttpService.secureGet(`${url}assets/send_sms_verification`)

const ValidateToken = (token: string) =>
  HttpService.get(`${url}users/validate_token`, { token })

const CreatePassword = (body: {
  password: string
  confirmed_password: string
  token: string
}) => HttpService.post(`${url}users/create_password`, body)

const ForgotPassword = (body: { email: string }) =>
  HttpService.post(`${url}users/reset_password`, body)

const Subscribe = (body: any) => HttpService.post(`${url}users/subscribe`, body)

const Register = (body: IRegister) => HttpService.post(`${url}register`, body)

const SetPassword = (body: {
  password?: string
  token: string
  resetCount: boolean
}) => HttpService.post(`${url}password`, body)

const SendMail = (body: {
  mail: string
  name: string
  company: string
  message: string
  phone: string
}) => HttpService.post(`${url}mails/contact_message`, body)

const GetUsers = () => HttpService.secureGet(`${url}assets/get_users`)

const GetCompanies = () => HttpService.secureGet(`${url}assets/get_companies`)

const GetCompaniesDetails = () => HttpService.secureGet(`${url}assets/get_companies_details`)

const GetBankDetails = (company_id:string) => HttpService.securePost(`${url}assets/get_bank_details`, {company_id})

const GetRoles = () => HttpService.secureGet(`${url}assets/get_roles`)

const GetProducts = () => HttpService.secureGet(`${url}assets/get_product`)

const GetSites = (company?: string) =>
  HttpService.secureGet(`${url}assets/get_sites`, { company })

const GetTechnologies = (company?: string) =>
  HttpService.secureGet(`${url}assets/get_technologies`, { company })

const GetSitesDetails = () =>
  HttpService.secureGet(`${url}assets/get_sites_detail`)

const GetNotificationsMarket = () =>
  HttpService.secureGet(`${url}assets/get_notifications_market`)

const GetProductionUnitsDetails = () =>
  HttpService.secureGet(`${url}assets/get_production_units_detail`)

const GetOldContracts = () =>
  HttpService.secureGet(`${url}assets/get_oa_contracts`)

const GetTypes = (company?: string) =>
  HttpService.secureGet(`${url}outages/get_outage_types`, { company })

const GetGroups = () => HttpService.secureGet(`${url}assets/get_groups`)



type IDateRange = {
  start_time: string
  end_time: string
}
type IAnalysis = {
  start_time: string
  end_time: string
  sites_ids?:string[]
  show_by_site:boolean
}

type IBenchmark = {
  start_time: string
  end_time : string
  site_id : string
}

const GetMarche = (params: IDateRange) =>
  HttpService.secureGet(`${url}accounts/get_market`, params)

const GetAnalysis = (params: IAnalysis) =>
  HttpService.secureGet(`${url}accounts/get_analysis/all`, params)

const GetAnalysisUptime = (params: IAnalysis) =>
  HttpService.secureGet(`${url}accounts/get_analysis/uptime`, params)

const GetAnalysisVolSpot = (params: IAnalysis) =>
  HttpService.secureGet(`${url}accounts/get_analysis/volspot`, params)

const GetAnalysisVolConso = (params: IAnalysis) =>
  HttpService.secureGet(`${url}accounts/get_analysis/volconso`, params)

const GetAnalysisEarning = (params: IAnalysis) =>
  HttpService.secureGet(`${url}accounts/get_analysis/earning`, params)

type IServices = {
    dateDebut: string
    dateFin: string
    listStation:string
  }

const GetHydrofrance = (params: IServices) =>
  HttpService.secureGet(`${url}accounts/hydrofrance`,params)

const GetMeteoCiel = (params: IServices) =>
  HttpService.secureGet(`${url}accounts/meteociel`,params)

type IServicesEnedis = {
    dateDebut: string
    dateFin: string
    prm: string
    insert: boolean
    restore : boolean
    mesuresCorrigees :boolean
    sens : string
  }

const GetEnedisR4Q = (params: IServicesEnedis) =>
  HttpService.secureGet(`${url}accounts/enedis_r4q`,params)

const GetBudgetVolume = (params: IDateRange) =>
  HttpService.secureGet(`${url}accounts/budget`,params)

type ISimulation = {
    dateDebut: string
    dateFin: string
    prm:string
  }

const GetEnedisSimulation = (params: ISimulation) =>
  HttpService.secureGet(`${url}accounts/enedis_simulation`,params)


const GetFindPRM = (params: {zipcode: string}) =>
  HttpService.secureGet(`${url}accounts/enedis_rp`,params)

const GetAnalysisEarningY_1 = (params: IAnalysis) =>
  HttpService.secureGet(`${url}accounts/get_analysis/earningY_1`, params)

const GetBenchmark = (params: IBenchmark) =>
  HttpService.secureGet(`${url}accounts/get_benchmark`, params)

const GetLive = (params: IBenchmark) =>
  HttpService.secureGet(`${url}accounts/get_live`, params)



const GetPortfolio = (params: IDateRange) =>
  HttpService.secureGet(`${url}accounts/get_portfolio`, params)

const GetOutages = (params: IDateRange) =>
  HttpService.secureGet(`${url}outages/get_outages`, params)

const GetPeakPeriods = (params: IDateRange) =>
  HttpService.secureGet(`${url}assets/get_peak_periods`, params)

const GetInvoices = () => HttpService.secureGet(`${url}assets/get_invoices`)

const GetSubscriptions = () => HttpService.secureGet(`${url}alerts/get_subscriptions`)

const GetContracts = () => HttpService.secureGet(`${url}accounts/get_contracts`)

const GetOAContracts = () => HttpService.secureGet(`${url}assets/get_oa_contracts`)

const GetNCC = () => HttpService.secureGet(`${url}accounts/get_ncc`)


const GetInvoice = (id: string) =>
  HttpService.secureGet(`${url}accounts/get_invoice`, { id })

const GetContract = (id: string) =>
  HttpService.secureGet(`${url}accounts/get_contract`, { id })


const DeleteContract= (body: { file_id: string }) =>
  HttpService.securePost(`${url}accounts/delete_contract`, body)

const DeleteUser = (body: { mail: string }) =>
  HttpService.securePost(`${url}invitation/delete_user`, body)

const DeleteProduct = (body: { product_id: string }) =>
  HttpService.securePost(`${url}assets/delete_product`, body)

const ModifyUser = (body: any) =>
  HttpService.securePost(`${url}assets/modify_user`, body)

const InviteUser = (body: any) =>
  HttpService.securePost(`${url}invitation/invite_user`, body)

const CreateCompany = (body: any) =>
  HttpService.securePost(`${url}assets/create_company`, body)

const DeleteCompany = (body: any) =>
  HttpService.securePost(`${url}assets/delete_companies`, body)

const DeleteInvoice = (body: any) =>
  HttpService.securePost(`${url}assets/delete_invoices`, body)

const DeleteSubscription = (body: any) =>
  HttpService.securePost(`${url}alerts/delete_subscriptions`, body)

const ValidateInvoiceClient = (body: any) =>
  HttpService.securePost(`${url}assets/validate_invoices_client`, body)

const ValidateInvoicePaymentAdmin = (body: { invoice_id: string }) =>
  HttpService.securePost(`${url}assets/validate_invoices_payment_admin`, body)

const ContestInvoiceClient = (body: any) =>
  HttpService.securePost(`${url}assets/contest_invoices_client`, body)
  
const ValidateInvoiceAdmin = (body: any) =>
  HttpService.securePost(`${url}assets/validate_invoices_admin`, body)

const ResetInvoice = (body: any) =>
  HttpService.securePost(`${url}assets/reset_invoice`, body)

const SendInvoice = (body: any) =>
  HttpService.securePost(`${url}assets/send_invoices`, body)

const CreateProduct= (body: any) =>
  HttpService.securePost(`${url}assets/create_product`, body)

const CreateInvoice= (body: any) =>
  HttpService.securePost(`${url}assets/create_invoice`, body)

const CreateSubscription= (body: any) =>
  HttpService.securePost(`${url}alerts/subscribe_to_alert`, body)

const CreateGroup = (body: any) =>
  HttpService.securePost(`${url}assets/create_group`, body)

const AddSite = (body: any) =>
  HttpService.securePost(`${url}assets/site_onboard`, body)

const Withdraw = (body: any) =>
  HttpService.securePost(`${url}accounts/withdraw`, body)

const ModifySite = (body: any) =>
  HttpService.securePost(`${url}assets/modify_site`, body)

const ModifyCompany = (body: any) =>
  HttpService.securePost(`${url}assets/modify_company`, body)

const ModifyProductionUnit = (body: any) =>
  HttpService.securePost(`${url}assets/modify_production_unit`, body)

const GetForecast = (params: IForecast) =>
  HttpService.secureGet(`${url}accounts/get_forecast`, params)

const UploadFile = (body: any) =>
HttpService.securePostFile(`${url}accounts/upload`, body)

const AnalyseRP50 = (body: any) =>
HttpService.securePostFile(`${url}accounts/analyserp50`, body)
 
const UploadContract = (body: any) =>
HttpService.securePostFile(`${url}accounts/upload_contract`, body)

const UploadInvoice = (body: any) =>
HttpService.securePostFile(`${url}accounts/upload_invoice`, body)


type IForecast = {
    start_time: string
    end_time: string
    sites_ids?:string[]
  }




  type Isiren = {
    siren : string;
  }

const Pappers = (params: Isiren) => 
  HttpService.secureGet(`${url}assets/get_companies_by_siren`, params)

type ISwitch = {
  show_by_site: boolean
  }

const GetDashboard = (params: ISwitch) => 
  HttpService.secureGet(`${url}accounts/get_dashboard/all`,params)

const GetDashboardEarning = (params: ISwitch) => 
  HttpService.secureGet(`${url}accounts/get_dashboard/earning`,params)

const GetLiveProduction = () => 
  HttpService.secureGet(`${url}accounts/get_live_production`)

const GetDashboardVolume = (params: ISwitch) => 
  HttpService.secureGet(`${url}accounts/get_dashboard/volume`,params)

const GetDashboardGos = (params: ISwitch) => 
  HttpService.secureGet(`${url}accounts/get_dashboard/gos`,params)

const GetDashboardMecapa = (params: ISwitch) => 
  HttpService.secureGet(`${url}accounts/get_dashboard/mecapa`,params)

type IArret = {
  public_id?: string
  start_time: string
  end_time: string
  remaining_power: number
  site_public_id: string
  outage_type: string
  comment: string
}
const SignalerArret = (body: IArret) =>
  HttpService.securePost(`${url}outages/create_outage`, body)

// const SendMail = (body: { mail:string,
//                           name:string,
//                           company:string,
//                           message:string,
//                           phone:string }) =>
//   HttpService.post(`${url}api/contact/contact_message`, body);

const LoginAsUser = (body: { email: string }) =>
  HttpService.securePost(`${url}management/login_as_user`, body)

const LogoutAsUser = () =>
  HttpService.secureGet(`${url}management/logout_as_user`)



const ApiService = {
  UploadFile,
  GetNCC,
  AnalyseRP50,
  UploadInvoice,
  DeleteContract,
  DeleteSubscription,
  UploadContract,
  GetBenchmark,
  GetLive,
  GetSubscriptions,
  GetLiveProduction,
  isLoggedIn,
  Login,
  Register,
  SetPassword,
  ForgotPassword,
  SendMail,
  ValidateToken,
  SendSmsAuth,
  Subscribe,
  CreatePassword,
  GetUsers,
  GetAnalysisVolConso,
  DeleteUser,
  DeleteCompany,
  DeleteInvoice,
  ResetInvoice,
  ValidateInvoiceClient,
  ValidateInvoicePaymentAdmin,
  ContestInvoiceClient,
  ValidateInvoiceAdmin,
  DeleteProduct,
  SendInvoice,
  ModifyUser,
  GetCompanies,
  GetRoles,
  GetProducts,
  GetSites,
  InviteUser,
  CreateCompany,
  CreateGroup,
  CreateProduct,
  CreateInvoice,
  LoginAsUser,
  GetGroups,
  GetDashboard,
  GetDashboardMecapa,
  GetDashboardGos,
  GetDashboardEarning,
  GetDashboardVolume,
  GetMarche,
  GetPeakPeriods,
  GetAnalysis,
  GetAnalysisUptime,
  GetAnalysisVolSpot,
  GetAnalysisEarning,
  GetAnalysisEarningY_1,
  GetPortfolio,
  GetOutages,
  GetTypes,
  LogoutAsUser,
  GetInvoices,
  GetNotificationsMarket,
  CreateSubscription,
  GetContracts,
  GetForecast,
  SendSmsVerification,
  Withdraw,
  AddSite,
  SignalerArret,
  ModifySite,
  ModifyCompany,
  ModifyProductionUnit,
  GetSitesDetails,
  GetProductionUnitsDetails,
  GetTechnologies,
  GetOldContracts,
  GetInvoice,
  GetContract,
  GetCompaniesDetails,
  GetBankDetails,
  Pappers,
  GetOAContracts,
  GetHydrofrance,
  GetMeteoCiel,
  GetEnedisR4Q,
  GetFindPRM,
  GetEnedisSimulation,
  GetBudgetVolume
}

export default ApiService
