import ApiService from "../services/ApiService";

export const load_sites_details = async () => {
  const response = await ApiService.GetSitesDetails()
  const rq_data = await response.data;
  return rq_data;
};



