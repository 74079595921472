import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import users from "./users";
import commun from "./commun";
import dashboard from "./dashboard";
import marche from "./marche";
import analyse from "./analyse";
import portfolio from "./portfolio";
import outage from "./outage";
import invoices from "./invoices";
import contracts from "./contracts";
import forecast from "./forecast";
import sites from "./sites";
import productionUnits from "./productionunits";
import benchmark from "./benchmark";
import live from "./live";
import budget from "./budget";
import earning from "./earning";
import peakperiod from "./peakperiod";
import subscriptions from "./subscriptions";
import ncc from "./ncc";

export default combineReducers({
  auth,
  message,
  users,
  commun,
  dashboard,
  marche,
  analyse,
  portfolio,
  outage,
  invoices,
  subscriptions,
  contracts,
  forecast,
  sites,
  benchmark,
  live,
  productionUnits,
  earning,
  budget,
  peakperiod,
  ncc
});
