import React, { useEffect, useState } from "react"
import { Timeline } from "antd"
import moment from 'moment'
import { SmileOutlined } from '@ant-design/icons';

interface P {
  data: any
}

const TimelineComponent: React.FC<P> = ({ data }) => {

  const level = {
    'en edition': 1,
    'envoyé au client': 2,
    'conflit': 2.5,
    'validé client': 3,
    'validé bohr': 4,
    'payé': 5,
  }

  const [currentLevel, setCurrentLevel] = React.useState(level[data.status])

  const chooseColor = (level) => {
    if (currentLevel === level) {
      return 'blue'
    }
    if (currentLevel > level) {
      return 'green'
    }
    if (currentLevel < level) {
      return 'grey'
    }
  }

  const chooseComment = (level, text, date) => {

    var z = text + ''

    if (currentLevel === level) {
      z = text + ' : en cours'
    }
    if (currentLevel > level) {
      z = text + ' : ' + moment(date).format('YYYY-MM-DD HH:mm')
    }
    if (currentLevel < level) {
      z = text + ''
    }

    return z
  }

  return (
    <>
      {data.status === 'conflit' ?

        <>
          <div style={{ marginBottom: 40, fontSize: '16px', fontWeight: '600' }}> Mes étapes de facturations </div>
          <Timeline
            items={[
              {
                color: chooseColor(1),
                children: chooseComment(1, 'Envoie FAR', data.createdAt),
              },
              {
                color: 'red',
                children:
                  <>
                  <div className="mb-5" >Non validé client {moment(data.contested_at).format('YYYY-MM-DD HH:mm')}</div>
                  
                  <div className="mr-2 py-3 px-4 bg-blue-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white max-w-md">
                    {data.contested_comment}
                  </div>
                  </>

              }
              ,
              {
                color: 'blue',
                children: 'En résolution, nous allons vous contacter dans les plus brefs delais',
              }
            ]}
          />
        </>

        :

        <>
          <div style={{ marginBottom: 40, fontSize: '16px', fontWeight: '600' }}> Mes étapes de facturations </div>
          <Timeline
            items={[
              {
                color: chooseColor(1),
                children: chooseComment(1, 'Envoie FAR', data.createdAt),
              },
              {
                color: chooseColor(2),
                children: chooseComment(2, 'Validation Client', data.validated_at),
              },
              {
                color: chooseColor(3),
                children: chooseComment(3, 'Validation Bohr', data.reviewed_at),
              },
              {
                color: chooseColor(4),
                children: chooseComment(4, 'Paiement par Bohr', data.payed_at),
              },
              {
                color: chooseColor(5),
                dot: <SmileOutlined />,
                children: <p>Payé !</p>,
              },
            ]}
          />
        </>
      }

    </>
  )
}
export default TimelineComponent
