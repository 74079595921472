import ApiService from "../services/ApiService";

export const load_live_production = async () => {
  const response = await ApiService.GetLiveProduction()
  const rq_data = await response.data.liveproduction;
  return rq_data;
};



