import React, { useEffect, useState } from "react"
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import moment from 'moment';


const customTheme = {
  axis: {
    fontSize: '14px', // Adjust the font size here
    tickColor: '#eee',
    ticks: {
      line: {
        stroke: '#555'
      },
      text: {
        fill: '#555'
      }
    },
    legend: {
      text: {
        fontSize: '14px' // Adjust the font size for the legend
      }
    },
  },
  fontSize: 12,
};

const BatterieChart = ({ data }:any) => {

  console.log('batterie chart',data)
  if (data === null || data === undefined || (typeof data === 'object' && Object.keys(data).length === 0)) {
    return null; // or return an empty string, or any other appropriate value
}


  const keys = [...new Set(data.flatMap(Object.keys).filter(key => key !== 'year'))];
  const labelFormat = value => `${Math.round(value)}`;


  const BarTotalsLayer = (props) => {
    const labelOffset = 10;
    const labelFontSize = 12;
    if (props.bars.length === 0) return null;
    // compute totals for each index/bar
    const totals = {};
    const totalsForPositive = {};
    const bandwidth = props.bars[0].width;
    props.bars.forEach((bar) => {
      const indexValue = bar.data.indexValue;
      if (!(indexValue in totals)) {
        totals[indexValue] = 0;
        totalsForPositive[indexValue] = 0;
      }
      if (!bar.data.hidden) {
        const barValue = bar.data.value;
        totals[indexValue] += bar.data.value;
        if (barValue > 0) {
        totalsForPositive[indexValue] += bar.data.value;
        }
      }
    });
    // place text elements above the bars
    const labels = Object.keys(totals).map((indexValue) => {
      const x = props.xScale(indexValue) + bandwidth / 2;
      const y = props.yScale(totalsForPositive[indexValue]) - labelOffset;
      const formattedTotal = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0 // This ensures no decimal places
    }).format(totals[indexValue]);
      return (
        <text
          key={"total." + indexValue}
          x={x}
          y={y}
          textAnchor={"middle"}
          fontWeight={"bold"}
          fontSize={labelFontSize}
        >
          {humanFormat(totals[indexValue])}
        </text>
      );
    });
    return <>{labels}</>;
  };


  const [animate, setAnimate] = useState(false);
  useEffect(() => setAnimate(true), [setAnimate]);

  function humanFormat(number) {
    if (number === 0) return '0';

    const sign = number < 0 ? '-' : '';
    number = Math.abs(number);

    const sizes = ["", "K", "M", "B", "T"];
    const i = number === 0 ? 0 : Math.floor(Math.log(number) / Math.log(1000));
    if (i === 0) return sign + number;

    const roundedNumber = (number / Math.pow(1000, i)).toFixed(1);
    const removeInsignificantZero = roundedNumber.endsWith('.0') ? roundedNumber.slice(0, -2) : roundedNumber;

    return sign + removeInsignificantZero + sizes[i];
}


  return (
    <>
    <div  style={{height:'500px'}}>
    <ResponsiveBar
    data={data}
    keys={keys}
    animate={animate}
    indexBy="year"
    margin={{ top: 60, right: 10, bottom: 70, left: 80 }}
    padding={0.3}
    groupMode="stacked"
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={{ scheme: 'nivo' }}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Year',
        legendPosition: 'middle',
        legendOffset: 32
    }}
    axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'P&L €',
        legendPosition: 'middle',
        legendOffset: -60
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    layers={[
      "grid",
      "axes",
      "markers",
      "bars",
      "legends",
      "annotations",
      BarTotalsLayer
    ]}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    label={({ value }) =>
    `${humanFormat(value)}`
  }
    legends={[
      {
        anchor: "bottom",
        dataFrom: "keys",
        direction: "row",
        itemHeight: 20,
        itemWidth: 120,
        toggleSerie: true,
        translateY: 64
      }
    ]}
/>
</div>
</>
  )
}


export default BatterieChart