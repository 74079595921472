import React, { PureComponent } from 'react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment'

interface P {
  dataConso: any,
  dataProd: any,
}





const NettingChartComponent: React.FC<P> = ({ dataConso, dataProd }) => {


  const [combinedData,setCombinedData] = React.useState(Array())
  const [resultArray,setResultArray] = React.useState(Array())
  const [couverture,setCouverture] = React.useState(Array())
  const [averageCouverture,setaverageCouverture] = React.useState(0)

  React.useEffect(() => {
    console.log('useEffect Netting')
    // Handle any side effects of tag change
    setCombinedData([...dataConso, ...dataProd])


    const { _resultArray, _couverture, _averageCouverture } = aggregateAndSubtract(dataConso, dataProd);

    if (_resultArray) {
      setResultArray(_resultArray)
    }
    
    setCouverture(_couverture)
    setaverageCouverture(_averageCouverture)

  }, [dataConso, dataProd]);





  if (!combinedData || combinedData.length === 0) {
    return null;
  }


  function aggregateAndSubtract(dataConso, dataProd) {

    console.log('aggregateAndSubtract')
    // Helper function to aggregate data
    const aggregateData = (data) => {
      const aggregationMap = new Map();

      data.forEach(item => {
        item.data.forEach(point => {
          if (!aggregationMap.has(point.x)) {
            aggregationMap.set(point.x, 0);
          }
          aggregationMap.set(point.x, aggregationMap.get(point.x) + point.y);
        });
      });

      return Array.from(aggregationMap, ([x, y]) => ({ x, y }));
    };

    // Aggregate data for both Conso and Prod
    const aggregatedConso = aggregateData(dataConso);
    const aggregatedProd = aggregateData(dataProd);

    console.log('aggregatedConso', aggregatedConso)
    console.log('aggregatedProd', aggregatedProd)

    // Convert aggregatedConso to a map for efficient lookup
    const consoMap = new Map(aggregatedConso.map(item => [item.x, item.y]));

    const positiveData = [];
    const negativeData = [];
    const couvertureData = []

    // Iterate over aggregatedProd and separate into positive and negative
    aggregatedProd.forEach(prodItem => {
      const consoY = consoMap.get(prodItem.x) || 0;
      const yResult = prodItem.y - consoY;


      const couvertureValue = consoY === 0 ? 1 : Math.min(1, prodItem.y / consoY);
      couvertureData.push({ x: prodItem.x, y: couvertureValue });


      if (yResult >= 0) {
        positiveData.push({ x: prodItem.x, y: yResult });
        negativeData.push({ x: prodItem.x, y: 0 });
      } else {
        negativeData.push({ x: prodItem.x, y: yResult });
        positiveData.push({ x: prodItem.x, y: 0 });
      }

    });

    // Prepare the final array to return
    const _resultArray = [
      { id: 'positive', color: "", data: positiveData }, // You can assign specific colors if needed
      { id: 'negative', color: "", data: negativeData },
    ];

    const _couverture = [
      { id: 'couverture', color: "", data: couvertureData }
    ]

    console.log('resultArray', resultArray);

        // Calculate the sum of all y values in couvertureData
    const totalCouverture = couvertureData.reduce((sum, item) => sum + item.y, 0);

    // Calculate the average by dividing the total by the number of elements
    const _averageCouverture = couvertureData.length > 0 ? totalCouverture / couvertureData.length : 0;

    console.log("Average Couverture:", averageCouverture);

    return { _resultArray, _couverture, _averageCouverture };
  }


  console.log('combinedData',combinedData)
  


  return (
    <>
      <div style={{ height: '100%',display:'flex',flexDirection:'column' }}>

      <div style={{ padding: '5px' }}>Injection & Soutirage</div>
        <div style={{ height: '400px' }}>
          
          <ResponsiveLine
            data={combinedData}
            margin={{ top: 10, right: 10, bottom: 80, left: 30 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={false}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Kwh',
              legendOffset: -65,
              legendPosition: 'middle'
            }}
            enablePoints={false}
            enableGridX={false}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 75,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 120,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        </div>

        <div style={{ padding: '5px' }}>Production -  Consommation</div>
        <div style={{ height: '300px', marginTop: '20px' }}>
          
          <ResponsiveLine
            colors={[
              'rgb(97, 205, 187)',
              'rgb(244, 117, 96)'
            ]}
            data={resultArray}
            margin={{ top: 10, right: 10, bottom: 50, left: 30 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={false}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Kwh',
              legendOffset: -65,
              legendPosition: 'middle'
            }}
            enableArea
            enableGridX={false}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.3
                ]
              ]
            }}
            pointBorderWidth={1}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 45,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 120,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        </div>

        <div style={{ padding: '5px' }}>Couverture en % | Sur la periode : {(averageCouverture*100).toFixed(1)}%</div>
        <div style={{ height: '100px', marginTop: '20px' }}>
          
          <ResponsiveLine
            colors={[
              'rgb(97, 205, 187)',
              'rgb(244, 117, 96)'
            ]}
            data={couverture}
            margin={{ top: 10, right: 10, bottom: 10, left: 30 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 1,
              stacked: false,
              reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={false}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickValues: [0, 0.2, 0.4, 0.6, 0.8, 1],
              tickRotation: 0,
              legend: 'Kwh',
              legendOffset: -65,
              legendPosition: 'middle'
            }}
            enableArea
            enableGridX={false}
            pointSize={1}
            pointColor={{ theme: 'background' }}
            pointBorderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.3
                ]
              ]
            }}
            pointBorderWidth={1}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        </div>
      </div>
    </>
  )
};
export default NettingChartComponent