// import postAPI from "@/actions/postAPI";
// import postFindPRM from "@/actions/postFindPRM";
import { Button, Checkbox, Form, Input, Modal, Select, Space, Spin } from "antd";
// import Search from "antd/es/input/Search";
import { useState } from "react";
import toast from "react-hot-toast";
import { findPRM } from "../../redux/actions";
import ApiService from "../../services/ApiService";
// import city from './out.json';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 7 },
    sm: { span: 16 },
  },
};

const { Search } = Input;

const DemoForm: React.FC = ({ onFinish, error }) => {

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading_, setLoading_] = useState(false);
  const [options, setOptions] = useState([]);
  const [zipcode, setZipCode] = useState('');
  const [error_, setError_] = useState('');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const onSearchzip = (value: string) => {
    console.log(zipcode)
    setOptions([])
    setError_('')
    setLoading_(true)

    toast.promise(ApiService.GetFindPRM({zipcode}), {
        loading: `Loading - PRMS - ${zipcode}`,
        success: (data) => {
            const onSuccess = data.data.data;
            console.log('onsuccess',onSuccess);
            setOptions(onSuccess);
            setLoading_(false);
          },
        error: (err) => {
                const onError = err.response.data.message;
                setError_(onError);
                setLoading_(false);
        }

      });

    

  }


  const city = [{"value": "59190 - MORBECQUE", "label": "59190 - MORBECQUE"}, {"value": "22330 - LANGOURLA", "label": "22330 - LANGOURLA"}, {"value": "31310 - GOUTEVERNISSE", "label": "31310 - GOUTEVERNISSE"}]


  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    form.setFieldsValue({ prm: value });

  };

  const onChange_ = (value: string) => {
    console.log(`selected ${value}`);
    setZipCode(value)
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };
  


  return (

    <>




      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '10px', justifyContent: 'center', margin: '0px' }} >
        
        <Form
          name="register"
          form={form}
          style={{ maxWidth: 800, backgroundColor: 'transparent', color: 'white' }}
          scrollToFirstError
          {...formItemLayout}
          onFinish={onFinish}
        >

          <Form.Item
            name="nom"
            label={<label style={{ color: "black" }}>Nom</label>}
            rules={[
              {
                required: true,
                message: 'Votre nom est requis',
              },
            ]}
          >
            <Input placeholder="Dupont" />
          </Form.Item>

          <Form.Item
            name="prenom"
            label={<label style={{ color: "black" }}>Prénom</label>}
            rules={[
              {
                required: true,
                message: 'Votre prénom est requis',
              },
            ]}
          >
            <Input placeholder="Jean" />
          </Form.Item>

          <Form.Item
            name="email"
            label={<label style={{ color: "black" }}>Email</label>}
            rules={[
              {
                type: 'email',
                message: 'Email Non Valide',
              },
              {
                required: true,
                message: 'Votre email est requis',
              },
            ]}
          >
            <Input placeholder="jean.dupont@gmail.com" />
          </Form.Item>

          <Form.Item
            name="mobile"
            label={<label style={{ color: "black" }}>Telephone</label>}
            // rules={[
            //   {
            //     type: 'email',
            //     message: 'Email Non Valide',
            //   },
            //   {
            //     required: true,
            //     message: 'Votre email est requis',
            //   },
            // ]}
          >
            <Input placeholder="0612345678" />
          </Form.Item>




          <Form.Item label={<label style={{ color: "black" }}>Société </label>} style={{ marginBottom: 0 }}>
            <Form.Item
              name="societe"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input placeholder="Nom Société" />
            </Form.Item>
            <Form.Item
              name="siren"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
            >
              <Input placeholder="Siren" />
            </Form.Item>

          </Form.Item>

          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingBottom: '15px', justifyContent: 'center' }}>-----</div>

          <Form.Item
            name="prm"
            label={<label style={{ color: "black" }}>Mon PRM</label>}
            rules={[
              {
                pattern: new RegExp(/^\d{14}$/),
                message: 'Inserer les 14 chiffres de votre PRM'
              },
              {
                required: true,
                message: 'Votre PRM est requis',
              },
            ]}
          >
            <Input placeholder="50094500712523" />

          </Form.Item>
          {error !== '' ?
            <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>
            :
            <></>
          }

          <div style={{ display: 'flex', flexDirection: 'row', width: '100%',color:'black', paddingBottom: '15px', justifyContent: 'center' }}>Ou si je ne le connais pas </div>

          {/* <Form.Item
            name="Code Postal"
            label={<label style={{ color: "black" }}>Code Postal</label>}>
            <Space.Compact style={{ width: '100%' }}>
              <Input placeholder="Code Postal" onChange={(e) => setZipCode(e.target.value)} />
              {!loading_ ?
                <Button style={{ color: "black" }} onClick={() => onSearchzip()}>Rechercher</Button>
                :

                <div style={{ marginLeft: '15px', marginTop: '5px' }}><Spin /></div>
              }
            </Space.Compact>
            {error_ !== '' ?
              <div style={{ color: 'red', marginTop: '5px' }}>{error_}</div>
              :
              <></>
            }


          </Form.Item> */}

          <Form.Item
            name="selectville"
            label={<label style={{ color: "black" }}>Code Postal</label>}>
            <Space.Compact style={{ width: '100%' }}>
              <Select
                showSearch
                placeholder="Code Postal"
                optionFilterProp="children"
                onSearch={onSearch}
                onChange={onChange_}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={city}
              />
              {!loading_ ?
                <Button style={{ color: "black" }} onClick={() => onSearchzip()}>Rechercher</Button>
                :

                <div style={{ marginLeft: '15px', marginTop: '5px' }}><Spin /></div>
              }
            </Space.Compact>

            {error_ !== '' ?
            <div style={{ color: 'red',marginTop: '5px' }}>{error_}</div>
            :
            <></>
          }
          </Form.Item>

          {/* {error_ !== '' ?
            <div style={{ color: 'red', marginLeft:'120px',marginTop: '-10px' }}>{error_}</div>
            :
            <></>
          } */}



          {options.length > 0 ?

            <Form.Item
              name="proposition"
              label={<label style={{ color: "black" }}>Liste</label>}>
              <Select
                showSearch
                placeholder="Select a PRM"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options}
              />
            </Form.Item>
            : <></>}
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Vous devez accepter pour lancer votre évaluation')),
              },
            ]}
            {...tailFormItemLayout}
          >
            <Checkbox style={{ color: "black" }}>
              Je certifie avoir les droits du PRM cité et
              j'autorise Bohr Energie à récuperer mes données CDC/Historiques... auprès d'Enedis
            </Checkbox>

          </Form.Item>
          <div style={{ color: "black", fontStyle: 'italic', textAlign: 'end' }} onClick={showModal} >Voir l'autorisation</div>


          <Form.Item label=" " colon={false}>
            <Button htmlType="submit" style={{ color: "black", fontSize:'24px',height:'60px' }}>
              Evaluer mes gains
            </Button>
            {/* <div style={{ color: "black", fontStyle: 'italic', textAlign: 'start', paddingTop: '5px' }} onClick={showModal} >et recever l'évaluation par mail</div> */}
          </Form.Item>
        </Form>
      </div>



      <Modal title="AUTORISATION DE COMMUNICATION À UN TIERS DES DONNÉES DE MESURE D’UN OU PLUSIEURS SITES D’ÉLECTRICITÉ RACCORDÉS AU RESEAU PUBLIC DE DISTRIBUTION" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <br></br>
        <h4 style={{ fontWeight: 'bold' }}>Le Client</h4>
        <p>Décrit par le formulaire remplie</p>
        <br></br>
        <h4 style={{ fontWeight: 'bold' }}>Le Tier</h4>
        <p>Dénomination Sociale : BOHR ENERGIE</p>
        <p>Forme Juridique : SAS, société par actions simplifiée</p>
        <p>SIREN : 889663654</p>
        <p>Adresse : 231 RTE DE LAMILLARIE 81990 PUYGOUZON</p>
        <br></br>
        <p><b>Le Client autorise le Tier à recevoir communication auprès d’Enedis</b>, SA à directoire et à conseil de surveillance, au capital de 270 037 000 euros,
          immatriculée au R.C.S. de Nanterre sous le numéro 444 608 442 et dont le siège social est situé Tour Enedis, 34 Place des Corolles, 92070 Paris La Défense Cedex
          <b> les données suivantes sous réserve de disponibilité pour le PRM/Site mentionné dans le formulaire</b></p>
        <br></br>
        <p>-	L’historique des mesures, en kWh, du site (et puissances atteintes et dépassements de puissance)</p>
        <p>-	L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site</p>
        <p>-	L’historique de courbe de charge, au pas restitué par Enedis, du site</p>
        <p>-	Les données techniques et contractuelles disponibles du site</p>
        <br></br>
        <br></br>
        <p>La présente autorisation ne peut être cédée et pourra être retirée à tout moment. Elle est consentie pour toute la période d’exécution du contrat ou à défaut pour la durée de 24 mois à compter de la date de signature. Le Client accepte expressément que ses données personnelles soient conservées par le Tiers et/ou Enedis à des fins de gestion et de traçabilité. Conformément à la loi Informatique et Libertés du 6 janvier 1978, le Client dispose d’un droit d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des données le concernant qu’il peut exercer sur simple demande auprès du Tiers et/ou d’Enedis, 34 place des Corolles, 92079 Paris La Défense Cedex.</p>
      </Modal>

    </>
  )


}
export default DemoForm
