import React, { useEffect, useState } from "react"
import { Button, Drawer, Form, Input, Select } from "antd"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "../../../helpers/FormHelpers"
import SelectDropdown from "../SelectDropdown"
import { Colors, Icon, IconNames } from "../Icon"
import ModalCompany from "./ModalCompany"
import { roles } from "../../utils/constants"
import ApiService from "../../services/ApiService"
import UserService from "../../services/UserService"
import { CircularProgress } from "@material-ui/core"
import { errorNotification, successNotification } from "../../utils"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchUsers } from "../../redux/actions/users"
import Passcode from "../registerComponents/Passcode"
import { useQuery } from "react-query"
import { load_companies } from "../../actions/load_companies"

export const SITES = [
  { key: "Assigny", value: "Assigny" },
  { key: "Orlu", value: "Orlu" },
  { key: "Rivesaltes", value: "Rivesaltes" },
  { key: "Salles-Curan", value: "Salles-Curan" },
  { key: "Harcanville", value: "Harcanville" },
  { key: "Tous les sites", value: "Tous les sites" },
]

export interface ModalProps {
  onClose?: () => void
  open: boolean
  data?: any
  isGestion?: boolean
  isEditing?: boolean
}
const ModalUser = ({
  onClose = undefined,
  open = false,
  data = null,
  isGestion = false,
  isEditing,
}: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({ mode: "onChange", defaultValues: data })

  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const { data: CompaniesRQ, isLoading: isLoadingCompaniesRQ, isError: isErrorCompaniesRQ, refetch: refetchCompaniesRQ } = useQuery(['companies',auth.currentUser?.user_id], load_companies,{staleTime: 30*60*1000});



  // const { roles, companies } = useSelector(
  //   (state: RootStateOrAny) => state.commun
  // )

  const [modalCompanyOpen, setModalCompanyOpen] = useState(false)
  const [data_, setdata] = useState(data)
  const [loading, setloading] = useState(false)
  const [listSites, setlistSites] = useState([])
  const [showOTP, ShowOTP] = useState(false)



  const dispatch = useDispatch()

  const sendSms = async callbackSuccess => {
    try {
      const resp = await ApiService.SendSmsVerification()
      if (resp.status == 200) {
        successNotification(resp.data.message)
        callbackSuccess && callbackSuccess()
      }
    } catch (error) {
      errorNotification(error.response.data.message)
    }
  }


  const onFinish = async (values: any) => {



    const body = values

    console.log('onFinish', body)

    setloading(true)
    try {
      if (isGestion) {
        if (!UserService.isSuperadmin()) { // user is not superadmin, need to ask for verification
          ShowOTP(true)
          setdata(body)
          sendSms(() => setloading(false))
          return
        } else { // user is superadmin, No need to ask for verification
          const resp = await ApiService.InviteUser(body)
          successNotification("Invitation envoyé avec succès")
          dispatch(fetchUsers())
          setloading(false)
          reset()
          onClose && onClose()
          return
        }
      }
      if (!isEditing) {
        // empty form
        const resp = await ApiService.InviteUser(body)
        successNotification("Invitation envoyé avec succès")

      } else {
        // prefilled form
        console.log('modify', body)
        const resp = await ApiService.ModifyUser(body)
        successNotification("Modificaiton faite avec succès")
      }
      dispatch(fetchUsers())
      setloading(false)
      reset()
      onClose && onClose()
    } catch (error) {
      setloading(false)
      errorNotification(error?.message)
    }



  };


  const loadSites = async (company: string) => {
    const list_sites = await ApiService.GetSites(company)
    setlistSites(list_sites.data)
  }


  const handleSubmitPasscode = async (code: any) => {
    console.log('handleSubmitPasscode')
    console.log('code', code)
    console.log('data_', data_)

    const body = { ...data_, 'verification_code': code }

    console.log('body', body)

    try {
      const resp = await ApiService.InviteUser(body)
      successNotification(resp.data.message)
      if (resp.status == 200) {
        reset()
        setdata(null)
        ShowOTP(false)
        dispatch(fetchUsers())
        onClose && onClose()
      }

    } catch (error) {
      errorNotification(error.response.data.message)
    }
  }

  useEffect(() => {
    setdata(data)
    if (!!data) {
      setValue("mail", data.email)
    }
  }, [data])

  const [form] = Form.useForm()

  const handleChangeCompany = (com: any) => {
    console.log('company', com)
    // if (!companies.length) return
    // let com = companies[0]
    setValue("company", com.public_id)
    loadSites(com.public_id)
    setdata({ ...data_, company: com.public_id })
    //setdata({ ...data_, company: com.public_id, entreprise: com.name })
    form.setFieldsValue({ company: com.public_id })
  }


  return (
    <>
      <ModalCompany
        onClose={() => {
          setModalCompanyOpen(false)
        }}
        open={modalCompanyOpen}
      />
      <Drawer
        className="user-drawer"
        width={window.innerWidth > 1181 ? 465 : "100vw"}
        title={
          <h1 className="font-semibold text-3xl leading-11 ">
            {showOTP
              ? "Confirmer l’ajout utilisateur"
              : isEditing
                ? "Modifier un utilisateur"
                : "Ajouter un utilisateur"}
          </h1>
        }
        closeIcon={<Icon name="close" />}
        bodyStyle={{ padding: 40, paddingTop: 0 }}
        headerStyle={{ border: "none", padding: "45px 45px 0px 40px" }}
        placement="right"
        onClose={() => {
          reset()
          setdata(null)
          ShowOTP(false)
          onClose && onClose()
        }}
        open={open}
      >
        {showOTP ? (
          <Passcode
            handleSubmit={handleSubmitPasscode}
            handleback={() => ShowOTP(false)}
            handleResend={sendSms}

          />
        ) : (


          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{ marginTop: '50px' }}
            initialValues={{ role: 'admin' }}>

            <Form.Item
              name="company"
              label={<label style={{ color: "black" }}>Entreprise</label>}
              rules={[
                {
                  required: true,
                  message: "l'Entreprise est requise",
                },
              ]}
            >

              <SelectDropdown
                placeholder="Sélectionner une entreprise"
                items={CompaniesRQ}
                keyAttribute="public_id"
                valueAttribute="name"
                className="mr-0"
                type="none"
                onSelect={handleChangeCompany}
                FooterComponent={
                  <Button
                    className="flex items-center gap-2.5 px-1"
                    type="link"
                    icon={
                      <Icon
                        color={Colors.primary}
                        name={IconNames.plusCircle}
                      />
                    }
                    onClick={() => setModalCompanyOpen(true)}
                  >
                    <span className="text-violet-bohr font-semibold	">
                      Ajouter une entreprise
                    </span>
                  </Button>
                }
                // type={"radio"}
                footerClickExitEvent={true}
                {...register("company", { required: "company is required" })}
              />
            </Form.Item>




            <Form.Item
              name="mail"
              label={<label style={{ color: "black" }}>Email</label>}
              rules={[
                {
                  required: true,
                  message: "l'email est requis",
                },
              ]}
            >
              <Input placeholder="Dupont" />
            </Form.Item>




            <Form.Item
              name="role"
              label={<label style={{ color: "black" }}>Rôle</label>}
              rules={[
                {
                  required: true,
                  message: "le rôle est requis",
                },
              ]}
            >
              <Select
                options={[
                  { value: 'superadmin', label: 'superadmin', disabled: auth.currentUser?.company_id !== 17 ? true : false },
                  { value: 'admin', label: 'admin' },
                  // { value: 'editor', label: 'editor', disabled: true },
                  { value: 'viewer', label: 'viewer' },
                ]}
              />
            </Form.Item>




            <Form.Item
              name="sites"
              label={<label style={{ color: "black" }}>Site</label>}
            >
              <Select
                disabled
                options={listSites.map(item => ({
                  value: item.public_id,
                  label: item.name,
                }))}
              />
            </Form.Item>


            <Form.Item label=" " colon={false} >
              <Button htmlType="submit" style={{ color: "black" }}>
                Ajouter
              </Button>
            </Form.Item>


          </Form>



        )}
      </Drawer>
    </>
  )
}
export default ModalUser
