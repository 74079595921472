// const initState = { loading: false, data:null, uptime: null ,error: null }


const initState = { 
                            general : {
                              loading: false, 
                              data:null,
                              error: null 
                            },
                            uptime : {
                              loading: false, 
                              data:null,
                              error: null 
                            },
                            volspot : {
                              loading: false, 
                              data:null,
                              error: null 
                            },
                            earning : {
                              loading: false, 
                              data:null,
                              error: null 
                            },
                            earningY_1 : {
                              loading: false, 
                              data:null,
                              error: null 
                            },
                          
                  }

const analyseReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "LOAD_ANALYSE":
      return {
        ...state,
          general : {
            ...state.general,
            loading : true,
            data : null,
            error : null
          }
      }
    case "LOAD_ANALYSE_SUCCESS":
      return {
        ...state,
          general : {
            ...state.general,
            loading : false,
            data : action.payload,
            error : null
          }
      }
    case "LOAD_ANALYSE_ERROR":
      return {
        ...state,
          general : {
            ...state.general,
            loading : false,
            data : null,
            error : action.payload
          }
      }
      case "LOAD_ANALYSE_UPTIME":
        return {
          ...state,
            uptime : {
              ...state.uptime,
              loading : true,
              data : null,
              error : null
          }
        }
      case "LOAD_ANALYSE_UPTIME_SUCCESS":
        return {
          ...state,
            uptime : {
              ...state.uptime,
              loading : false,
              data : action.payload,
              error : null
            }
        }
      case "LOAD_ANALYSE_UPTIME_ERROR":
        return {
          ...state,
            uptime : {
              ...state.uptime,
              loading : false,
              data : null,
              error : action.payload
            }
        }
      case "LOAD_ANALYSE_VOLSPOT":
          return {
            ...state,
              volspot : {
                ...state.volspot,
                loading : true,
                data : null,
                error : null
            }
          }
      case "LOAD_ANALYSE_VOLSPOT_SUCCESS":
          return {
            ...state,
            volspot : {
                ...state.volspot,
                loading : false,
                data : action.payload,
                error : null
              }
          }
      case "LOAD_ANALYSE_VOLSPOT_ERROR":
          return {
            ...state,
              volspot : {
                ...state.volspot,
                loading : false,
                data : null,
                error : action.payload
              }
          }
      case "LOAD_ANALYSE_EARNING":
            return {
              ...state,
                earning : {
                  ...state.earning,
                  loading : true,
                  data : null,
                  error : null
              }
            }
      case "LOAD_ANALYSE_EARNING_SUCCESS":
            return {
              ...state,
              earning : {
                  ...state.earning,
                  loading : false,
                  data : action.payload,
                  error : null
                }
            }
      case "LOAD_ANALYSE_EARNING_ERROR":
            return {
              ...state,
              earning : {
                  ...state.earning,
                  loading : false,
                  data : null,
                  error : action.payload
                }
            }
        case "LOAD_ANALYSE_EARNINGY_1":
              return {
                ...state,
                  earningY_1 : {
                    ...state.earningY_1,
                    loading : true,
                    data : null,
                    error : null
                }
              }
        case "LOAD_ANALYSE_EARNINGY_1_SUCCESS":
              return {
                ...state,
                earningY_1 : {
                    ...state.earningY_1,
                    loading : false,
                    data : action.payload,
                    error : null
                  }
              }
        case "LOAD_ANALYSE_EARNINGY_1_ERROR":
              return {
                ...state,
                earningY_1 : {
                    ...state.earningY_1,
                    loading : false,
                    data : null,
                    error : action.payload
                  }
              }
      default:
        return state

  }
}

export default analyseReducer
