import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { setCurrentUser } from "../redux/actions/auth"
import Card from "../components/Card"
import Text from "../components/Text"
import { Icon } from "../components/Icon"
import PieChart from "../components/Charts/PieChart"
import DeltaBadge from "../components/DeltaBadge"
import { Spin, Table } from "antd"
import { fetchDashboard, fetchDashboardEarning, fetchDashboardGos, fetchDashboardMecapa, fetchDashboardVolume, fetchSitesDetail } from "../redux/actions"
import BarChartComponent from "../components/Charts/BarChart"
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import moment from 'moment'
import { batch } from 'react-redux'
import EnergieComponent from "../components/Dashboard/Energie"
import { load_data_analysis_background } from "../actions/load_data_analysis_background"
import { useQuery, useQueryClient } from "react-query"
import { load_market } from "../actions/load_market"
import { load_sites_details } from "../actions/load_sites_details"
import { load_dashboard_earning } from "../actions/load_dashboard_earning"
import { load_dashboard_volume } from "../actions/load_dashboard_volume"
import { load_dashboard_mecapa } from "../actions/load_dashboard_mecapa"
import { load_dashboard_gos } from "../actions/load_dashboard_gos"
import { load_analysis_uptime } from "../actions/load_analysis_uptime"
import { load_analysis_volspot } from "../actions/load_analysis_volspot"
import { load_analysis_earning_y_1 } from "../actions/load_analysis_earning_y_1"
import { load_analysis_earning } from "../actions/load_analysis_earning"
import AreaChartComponent from "../components/Charts/LineChart"
import SwitchComponent from "../components/Charts/LineChartSwitch"



export default function Switch() {

  const auth = useSelector((state: RootStateOrAny) => state.auth)
  var start_date = new Date()
  var today = new Date()
  var tomorrow = new Date()
  start_date.setDate(today.getDate());
  tomorrow.setDate(today.getDate() + 1);



  function transform(oldData, spotMarketPrice) {

    if (!oldData) return []

    let newData1 = oldData?.reduce((acc, item) => {

      var date = moment.utc(item.start_time).tz('Europe/Paris').format()

      if (!acc[date]) {
        acc[date] = {}
      }

      acc[date][item.contract_type] = item.contract_value


      return acc
    }, {})

    let newData2 = spotMarketPrice?.reduce((acc, item) => {

      var date = moment.utc(item.start_time).tz('Europe/Paris').format()

      if (!acc[date]) {
        acc[date] = {}
      }

      // console.log('item["Prix marché Spot"]',item.start_time,item["Prix marché Spot"])
      acc[date]["Prix marché Spot"] = item["Prix marché Spot"]
      return acc
    }, newData1)

    if (!newData2) return []

    var arr: any[] = []
    Object.entries(newData2).forEach(([key, value]) =>
      arr.push({ date: key, ...value })
    )

    return arr
  }

  const [xScale, setxScale] = useState({ key: "hour", value: "Heure" })
  const [start_time, setstart_time] = useState(start_date.toISOString().split('T')[0])
  const [end_time, setend_time] = useState(tomorrow.toISOString().split('T')[0])

  const { data: MarketRQ, isLoading: isLoadingMarketRQ, isError: isErrorMarketRQ, refetch: refetchMarketRQ } = useQuery(['Market', {
    start_time: start_time,
    end_time: end_time,
  }, auth.currentUser?.user_id], load_market, { staleTime: 1000 * 60 * 60 });

  const [datachart, setDataChart] = useState(transform(MarketRQ?.OAcontract, MarketRQ?.spotMarketPrice))
  useEffect(() => {
    setDataChart(transform(MarketRQ?.OAcontract, MarketRQ?.spotMarketPrice))
  }, [MarketRQ])



  const { data: SiteDetailsRQ, isLoading: isLoadingSiteDetailsRQ, isError: isErrorSiteDetailsRQ, refetch: refetchSiteDetailsRQ } = useQuery(['site_details', auth.currentUser?.user_id], load_sites_details, { staleTime: 30 * 60 * 1000 });

  const [data, setData] = useState(Array())

  useEffect(() => {
    if (SiteDetailsRQ) {
      setData(SiteDetailsRQ)
    }
  }, [SiteDetailsRQ])

  console.log('prm', data)
  console.log('datachart', datachart)

  return (
    <Layout isDashboard={true} showSignlerArret showContactUs showNotifications>
      <Seo title="Bohr Energie | Tableau de bord" />
      <Layout.Header>
      <div>
          <h1 style={{ fontSize: 40 ,paddingBottom:'10px'}} className="font-semibold text-3xl mb-2">
            Market Switch
          </h1>
          <p className="text-gray-6f font-medium text-xl">
            Voici le comparatif prix OA-Marché de vos sites.
          </p>
        </div>
      </Layout.Header>
      <Spin spinning={isLoadingMarketRQ}>

      <div className="flex-wrap" style={{ display: "flex" }}>
        <img
          src="/images/accueil.png"
          alt="dashboard"
          style={{ width: 167, position: "absolute", right: 66, top: 14 }}
          className="hidden md:block"
        />

        {data &&
          [{'name':'Montartier','last_contract':'h07_cm'},
          {'name':'La Balme','last_contract':'h07_cb'},
          {'name':'Saint Leger','last_contract':'h07_csl'},
          {'name':'La Christine','last_contract':'h07_cc'}].map(site => (
            <Card
              className="w-full md:w-1/2"
              title= {site.name}
              help="Prix marché help"
            >
              <SwitchComponent
                xScale="hour"
                data={datachart}
                contract={site.last_contract}
              />
            </Card>))
              }




      </div>
      </Spin>


    </Layout>
  )
}
