import ApiService from "../services/ApiService";

export const load_companies = async () => {
  const response = await ApiService.GetCompanies()
  const rq_data = await response.data;
  return rq_data;
};



