import ApiService from "../services/ApiService";

export const load_analysis_uptime = async (
queryParams : any
) => {

  const response = await ApiService.GetAnalysisUptime(queryParams['queryKey'][1])
  const rq_data = await response.data.analysis;
  return rq_data;
};



