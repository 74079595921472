
import { Button, Checkbox, Form, Input, Modal, Spin, Table, Card, Select, InputNumber } from "antd";
import React, { useState } from "react";
import DeltaBadge from "./DeltaBadge";
import MixBarChart from "./MixBarChart";
import Text from "../Text";
import ExamplePieChart from "./PieChart";
import type { SelectProps } from 'antd';

const columns_ = [
  {
    title: "Mois",
    dataIndex: "start_time",
    key: "start_time",
    render(text, record) {
      return (
        <div>{text}</div>)


    }

  },
  {
    title: "Prix EEX",
    dataIndex: "price",
    key: "price",
    responsive: ["sm"],
    render(text, record) {
      return (
        <div>{parseFloat(text || 0).toFixed(2)} €</div>)


    }
  },
  {
    title: "Volume",
    dataIndex: "volume",
    key: "volume",
    responsive: ["sm"],
    render(text, record) {
      return (
        <div>{parseInt(text || 0).toLocaleString("fr")}</div>)


    }
  },
  {
    title: "Montant",
    dataIndex: "value_goo",
    key: "value_goo",
    render(text, record) {
      return (
        <div> {parseFloat(text || 0).toFixed(0)} €</div>)


    }
  },
]


const QuickAnalysis: React.FC = ({ data, loading }) => {

  const contracts = [{ label: 'h07', value: 'h07' },
  { label: 'h01', value: 'h01' },
  { label: 's21', value: 's21' }]

  const [selectedContracts, setSelectedContracts] = React.useState(['h07'])
  const [prixMC, setPrixMC] = React.useState(42)
  const [M_MC, setM_MC] = React.useState(15)
  const [M_GO, setM_GO] = React.useState(15)
  const [M_Spot, setM_Spot] = React.useState(5)

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    const x = (value + ' ').split(',')
    setSelectedContracts(x)
    console.log(selectedContracts)
  };


  console.log('data?.total_mc', data?.total_mc)



  return (

    <>

      <div style={{ marginBottom: '10px', display :'flex', flexDirection:'row',gap:'25px'}}>

        <Form.Item
          label="Type de Contrats"
          style={{width:'220px'}}
        >
            <Select
              mode="multiple"
              allowClear
              placeholder="select benchmark"
              defaultValue={['h07', 's21']}
              onChange={handleChange}
              options={contracts}
            />
        </Form.Item>

        <Form.Item
          label="Prix MC"
          style={{width:'150px'}}
        >
        <InputNumber addonBefore="+" addonAfter="K€" defaultValue={prixMC} onChange={(value)=>setPrixMC(value)} />
        </Form.Item>

        <Form.Item
          label="Margin Spot"
          style={{width:'100px'}}
        >
        <InputNumber addonAfter="%" defaultValue={M_Spot} onChange={(value)=>setM_Spot(value)} />
        </Form.Item>

        <Form.Item
          label="Margin MC"
          style={{width:'100px'}}
        >
        <InputNumber addonAfter="%" defaultValue={M_MC} onChange={(value)=>setM_MC(value)} />
        </Form.Item>

        <Form.Item
          label="Margin GO"
          style={{width:'100px'}}
        >
        <InputNumber addonAfter="%" defaultValue={M_GO} onChange={(value)=>setM_GO(value)} />
        </Form.Item>

      </div>

        <Card
          style={{marginBottom:"30px"}}
          title="Bohr P&L">



          <div >
            <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
              <h1 className="lg:text-3xl text-3xl text-center lg:text-left">

                {
                  //@ts-ignore
                  parseInt((data?.spot* M_Spot/100 || 0)) + parseInt(data?.pmax * 0.33 * prixMC * M_MC/100 || 0) + parseInt((data?.total_goo * M_GO/100 || 0))
                }
              </h1>
              <p className="">€</p>
            </div>

            <p className="mb-12  text-center">Energie + MC + GO</p>

          </div>



          <div className="grid grid-cols-3 grid-rows-1 gap-4 space-x-10 lg:flex lg:flex-row md:mx-4 mx-0 lg justify-center items-center">

              <div>
                <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
                  <h1 className="lg:text-2xl text-2xl text-center lg:text-left">
                    {parseInt((data?.spot* M_Spot/100 || 0)).toLocaleString("fr")}
                  </h1>
                  <p className="">€</p>
                </div>
                <p className="mb-12  text-center">Spot</p>
              </div>

              <div>
                <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
                  <h1 className="lg:text-2xl text-2xl text-center lg:text-left">
                    {parseInt(data?.pmax * 0.33 * prixMC * M_MC/100 || 0).toLocaleString("fr")}
                  </h1>
                  <p className="">€</p>
                </div>
                <p className="mb-12  text-center">MC</p>
              </div>

              <div>
                <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
                  <h1 className="lg:text-2xl text-2xl text-center lg:text-left">
                      {parseInt((data?.total_goo * M_GO/100 || 0)).toLocaleString("fr")}
                  </h1>
                  <p className="">€</p>
                </div>
                <p className="mb-12  text-center">GOs</p>
              </div>

          </div>

        </Card>





      <div className="flex-wrap grid gap-4 grid-cols-2">


        <Card
          title="Mon CA Global 2023">



          <div >
            <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
              <h1 className="lg:text-3xl text-3xl text-center lg:text-left">

                {parseInt((data?.total_goo || 0) + (data?.spot || 0) + (data?.pmax * 0.33 * prixMC * 6 / 12 || 0)).toLocaleString("fr")}




              </h1>
              <p className="">€</p>
            </div>

            <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
              Energie + MC + GO
            </div>
          </div>

          <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
            <ExamplePieChart data={data} />
          </div>

        </Card>

        <Card

          title="Mon chiffre d'affaire énergie"
        >


          <div >
            <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
              <h1 className="lg:text-3xl text-3xl text-center lg:text-left">

                {
                  //@ts-ignore
                  data['spot']?.toFixed(0)
                }
              </h1>
              <p className="">€</p>
            </div>

            <p className="mb-12  text-center">spot</p>

          </div>



          <div className="grid grid-cols-1 grid-rows-1 gap-4 lg:flex lg:flex-row md:mx-4 mx-0 lg:justify-between items-center">
            {selectedContracts ? selectedContracts.map((key) => (
              <div key={key}>
                <DeltaBadge delta={((data?.spot - data[key]) / data?.spot) * 100 || 0} />

                <div className="flex flex-row font-Campton-SemiBold items-center justify-center">

                  <h1 className="lg:text-2xl text-2xl text-center lg:text-left">

                    {
                      //@ts-ignore
                      data[key]?.toFixed(0)
                    }
                  </h1>
                  <p className="">€</p>
                </div>

                <p className="mb-12  text-center">{key}</p>
              </div>
            )) : null}
          </div>

          <div>
            <Text type="16-600" className="text-gray-6f">
              {'Mon Volume : ' + parseInt(data?.total_volume || 0).toLocaleString("fr")} mwh
            </Text>
          </div>

        </Card>



        <Card
          className="w-full"
          title="Ma production valorisé">


          <br></br>
          <Spin spinning={loading} style={{ padding: '0px' }}>
            <MixBarChart
              data={data?.graph}
              datakey={"value"}
              unit={"€"}

            />
          </Spin>
        </Card>



        <Card
          className="h-500"
          title="Mes volumes quotidiens">
          <br></br>
          <Spin spinning={loading} style={{ padding: '0px' }}>
            <MixBarChart
              data={data?.graph}
              datakey={"volume"}
              unit={"MWh"}

            />
          </Spin>
        </Card>


        <Card
          title="Mon garanties d'origines">

          <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
            <h1 className="lg:text-3xl text-3xl text-center lg:text-left">

              {
                //@ts-ignore
                data?.total_goo.toFixed(0)
              }
            </h1>
            <p className="">€</p>
          </div>

          <br></br>
          <Table
            size={'small'}
            pagination={false}
            dataSource={data.goo}
            columns={columns_}
          />
        </Card>

        <Card
          title="Mes mécanismes de capacité">

          <div className="flex flex-row font-Campton-SemiBold items-center justify-center">
            <h1 className="lg:text-3xl text-3xl text-center lg:text-left">

              {
                //@ts-ignore
                // data?.total_mc.toFixed(0)
                parseInt(data?.pmax * 0.33 * prixMC || 0)
              }
            </h1>
            <p className="">€ / annuel</p>
          </div>

          <br></br>
          <br></br>
          <br></br>
          <div>
            <Text type="12-600" className="text-gray-6f">
              {'Estimé sur une Pmax de ' + parseInt(data?.pmax || 0).toLocaleString("fr") + ' kwh et un prix MW de ' + prixMC + 'K€'}
            </Text>
          </div>
        </Card>




      </div>

    </>
  )


}
export default QuickAnalysis
