import React, { useEffect, useState } from "react"
import { Collapse, Col, Row, Avatar, Spin, Tag, Card, Segmented, Input } from "antd"
import { Colors, Icon, IconNames } from "../Icon"
import Text from "../Text"
import "./customStyle.css"
import ModalAssign from "./ModalAssign"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchContracts, fetchSitesDetail } from "../../redux/actions"
import { stringToHexColor } from "../../utils"
import UserService from "../../services/UserService"
import moment from "moment"
import { fetchCompanies } from "../../redux/actions/commun"
import SelectDropdown from "../SelectDropdown"
import { Space, Table, Pagination, Modal } from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"
const { confirm } = Modal

import { UserOutlined } from '@ant-design/icons';
import ApiService from "../../services/ApiService"


const url = process.env.API_URL

const { Panel } = Collapse


const SPVAdmin = () => {
  
  const commun = useSelector((state: RootStateOrAny) => state.commun)
  const sites_ = useSelector((state: RootStateOrAny) => state.sites)
  const [filters, setfilters] = useState({
    sites: [],
  })
  const [selectedSPV, setSelectedSPV] = useState(Array())
  const [SPV, setSPV] = useState(Array())
  const [sites, setSites] = useState(Array())
  const [loading, setLoading] = useState(true)
  const [currentSPV, setcurrentSPV] = useState()
  const [currentSite, setcurrentSite] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [showedData, setShowedData] = useState(Array())
  const [modalPdfOpen, setmodalPdfOpen] = useState(false)
  const [pdfContent, setPdfContent] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(fetchSitesDetail())
    // dispatch(fetchCompanies())
    if (commun.companies) {
      setSPV(commun.companies)
      setLoading(false)
    }
    if (sites_.data) {
      setSites(sites_.data)
    }

  }, [])



  useEffect(() => {
    if (sites_.data) {
      setSites(sites_.data)
    }
  }, [sites_])


  useEffect(() => {
    if (commun.companies) {
      setSPV(commun.companies)
      setLoading(false)
    }
  }, [commun.companies])

  const onChange = key => {
  }


  const deleteCompany = async (public_id: string) => {
    const resp = await ApiService.DeleteCompany({ public_id })
    if (resp.status == 200) {
      dispatch(fetchSitesDetail())
      dispatch(fetchCompanies())
    }
  }


  const handleDelete = record => {
    confirm({
      title: "Etes-vous sûr de supprimer cette companie ?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      onOk() {
        console.log('delete company',record)
        deleteCompany(record.public_id)
      },
      onCancel() {
      },
    })
  }

  const genExtra = spv => (
    <Space>
      <Icon
        name="edit"
        onClick={event => {
          //event.stopPropagation()
          setcurrentSPV(spv)
          setModalOpen(true)
        }}
        style={{ right: 25, position: "relative" }}
        className="cursor-pointer"
        color={Colors.primary}
      />
      <Icon
      color={Colors.primary}
      title="Supprimer"
      className={"cursor-pointer"}
      onClick={() => handleDelete(spv)}
      name={IconNames.delete}
      />
    </Space>
  )

  const PanelHeader = ({ data, len }) => (
    <Row className="flex items-center">
      <Col xs={24} sm={12} md={8} lg={16}>
        <Avatar
          gap={9}
          style={{
            backgroundColor: stringToHexColor(data.name),
            color: "white",
            marginRight: 19,
          }}
          size={40}
        >
          {data.name.toUpperCase().slice(0, 2) }
        </Avatar>
        <Text type="16-600" style={{textTransform: 'capitalize'}}>{data.name} ({len})</Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={3}>
        <Text className="text-gray-6f" type="12-500">
          Siren
        </Text>
        <Text className="block" type="14-500">
          {data.siren || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={8} lg={3}>
        <Text className="text-gray-6f" type="12-500">
          TVA
        </Text>
        <Text className="block" type="14-500">
          {data.tva_number || "-"}
        </Text>
      </Col>
    </Row>
  )
  

  const PanelBody = ({ data,dataSites }) => {
      return(    
              <Row className="flex items-center" style={{marginTop:'30px'}}>
                  <Table
                    loading={loading}
                    showHeader ={false}
                    rowKey={"name"}
                    pagination={false}
                    columns={columns}
                    dataSource={dataSites}
                    style={{width:'100%'}}
                  />
              </Row>
            )

  }




  useEffect(() => {
    setShowedData(sites)
  }, [sites])
  
  const headers = {
    Authorization: `Bearer ${UserService.getToken()}`,
  }



  const onSelectChange = (newSelectedRowKeys: SetStateAction<never[]>) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const onInputChange = (record, index,text) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {

    const newData = [...showedData];

    newData.find(v => v.public_id === record.public_id).site_type = e.target.value;
    setShowedData(newData)
    // setTotal(newData, index);
    // setTableData(newData);
  };


  const columns = [
    {
      title: "prm_number",
      dataIndex: "prm_number",
      key: "prm_number",
      responsive: ["sm"],
      width: '10%',
      render: (_, { prm_number }) => (prm_number),
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      responsive: ["sm"],
      width: '60%',
      editable : true,
      render: (_, { name }) => (name),
    },
    {
      title: "type",
      dataIndex: "site_type",
      key: "site_type",
      responsive: ["sm"],
      width: '15',
      render: (_, { site_type }) => (site_type),
    },
    {
      title: "installed_capacity",
      dataIndex: "installed_capacity",
      key: "installed_capacity",
      responsive: ["sm"],
      width: '15%',
      render: (_, { installed_capacity }) => (installed_capacity) + " KwH",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Icon
            className={"cursor-pointer"}
            title="Modifier"
            color={Colors.primary}
            name={IconNames.edit}
            onClick={event => {
              //event.stopPropagation()
              setcurrentSite(record)
              setModalOpen(true)
            }}


          />
          <Icon

            title="Supprimer"
            className={"cursor-pointer"}
            onClick={() => handleDelete(record)}
            name={IconNames.delete}
            color={Colors.primary}
          />
        </Space>
      ),
    },
  ]







  const handleSelectSPV = val => {
    setSelectedSPV(val.map(i => i.public_id))
  }
  const handleUnselectSPV = val => {
    setSelectedSPV(selectedSPV.filter(i => i!=val))
  }

  const handleCloseModalPdf = () => {
    setmodalPdfOpen(false)
  }




  const panelDataSPV = {

    "Adresse" : 'address',
    'Ville': "city",
    'Code Postal': "postal_code",
    'Pays' : 'country',
    "Représentant Legal" : 'legal_representative_lastname',
    "Téléphone Responsable" : 'contact_phone',
    "Mail Responsable" : 'contact_mail',
    "Mail Facturation" : 'contact_facturation',
    
  }

  const panelDataSite = {
    "N. Card": "card_number",
    "Ancien contrat": "last_contract",
    "Puissance d’injection max": "installed_capacity",
    "Latitude": "latitude",
    "Longitude": "longitude",
    "Puissance installé": "installed_capacity",
    "Type Turbine": "turbine_type",
    "Riviere de Reference": "reference_river",
    'Mail Responsable': 'contact_mail',
    'Telephone Responsable':'contact_phone',
    'Adresse' :'address',
    // 'Ville' : 'city',
    'Code Postal' :'postal_code',
    'Pays' :'country',
  }



  return (
    <>
      <div className="md:flex justify-between  items-center pb-4">
        <div className="text-base font-semibold	mb-4 md:mb-0">
        Companies (sites) ({showedData?.length || 0})
        </div>
        <div className="flex sm:flex-row flex-col gap-4 items-center justify-between">
          

            <div className="flex justify-center">
            <SelectDropdown
              items={SPV}
              keyAttribute="public_id"
              valueAttribute="name"
              defaultValues={selectedSPV}
              onSelect={handleSelectSPV}
              placeholder="Sélectionnez un SPV / Société"
            />
            </div>

        </div>



      </div>

      <div className="flex flex-wrap gap-3" style={{marginBottom:'20px'}}>
        {SPV?.filter(
            spv => selectedSPV && selectedSPV.includes(spv.public_id)
          )
          .map(spv => (
            <Tag closable onClose={()=>handleUnselectSPV(spv.public_id)}>{spv.name}</Tag>
          ))}

      </div>



      <Spin spinning={loading}>


        <ModalAssign
          panelData={panelDataSite}
          data={currentSite}
          open={modalOpen && !!currentSite}
          onClose={() => {
            setcurrentSite(null)
            setModalOpen(false)
          }}
        />
        <ModalAssign
          panelData={panelDataSPV}
          data={currentSPV}
          open={modalOpen && !!currentSPV}
          onClose={() => {
            setcurrentSPV(null)
            setModalOpen(false)
          }}
        />
        <Collapse
          // defaultActiveKey={["0"]}
          onChange={onChange}
          expandIconPosition="end"
          ghost
          collapsible="icon"
          
          expandIcon={({ isActive }) => (
            <Icon name={isActive ? "arrow-up" : "arrow-down"} />
          )}
        >
          {SPV?.map((spv: any, index: number) => {


                return (  <Panel
                  header={<PanelHeader data={spv} len={showedData.filter((i)=>i.companies_public_id==spv.public_id).length}/>}
                  key={index + ""}
                  extra={genExtra(spv)}
                >
                  <PanelBody data={spv} dataSites={showedData.filter((i)=>i.companies_public_id==spv.public_id)}/>
                </Panel>)



            })}
        </Collapse>
      </Spin>

      <Modal
        className="modal-fullscreen rounded-none h-full w-full p-0 m-0 all-Campton"
        open={modalPdfOpen}
        onCancel={handleCloseModalPdf}
        bodyStyle={{ minHeight: "100vh" }}
        width="100%"
        footer={null}
      >
        {pdfContent}
      </Modal>

    </>
  )
}
export default SPVAdmin
