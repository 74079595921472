
import React, { useEffect, useState } from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  Scatter,
  ResponsiveContainer,
  ZAxis,
  ScatterChart,
} from 'recharts';
import moment from "moment"
import Text from '../Text';
import { stringToHexColor } from "../../utils";

interface P {
  data: any,
}

const ScatterComponent: React.FC<P> = ({ data , site,type,keyArray}) => {

  const integerFormatter = (value) => { return Math.round(value)} ;

  return (


      <ResponsiveContainer width="100%" height={500}>
        <ScatterChart
          width={730}
          height={500}
          margin={{
            top: 30,
            right: 0,
            bottom: 0,
            left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis  dataKey={keyArray}  type="number" name={
            type === 'hydro' ? 'Hauteur d eau' :
            type === 'solar' ? 'Radiation' :
            type === 'eolien' ? 'Vent moyen' :
            'wind_mean' // You can set a default key if needed
          } unit="" domain={['dataMin', 'dataMax'+10]} tickFormatter={integerFormatter}/>
          <YAxis dataKey="volume" type="number" name="volume" unit="kwh" domain={['dataMin', 'dataMax' + 10]} tickFormatter={integerFormatter}/>
          <ZAxis dataKey="z" type="number" range={[15, 15]} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Legend />
          <Scatter name={
            type === 'hydro' ? 'Production vs Hauteur d eau' :
            type === 'solar' ? 'Production vs Radiation Solaire' :
            type === 'eolien' ? 'Production vs Vent moyen à 10m' :
            'wind_mean' // You can set a default key if needed
          } data={data} fill={stringToHexColor(site.name)} />
        </ScatterChart>
      </ResponsiveContainer>



  );

};
export default ScatterComponent;