import { Button, Checkbox, Form, Input, Upload } from "antd"
import TextArea from "antd/es/input/TextArea"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import ApiService from "../../services/ApiService"
import { fetchInvoices } from "../../redux/actions"
import { PlusOutlined } from '@ant-design/icons';
import toast from "react-hot-toast"
import { useQuery } from "react-query"
import { load_invoices } from "../../actions/load_invoices"

interface P {
  data: any
}

const FormValidateComponent: React.FC<P> = ({ data }) => {


  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const { data: invoicesRQ, isLoading: isLoadinginvoicesRQ, isError: isErrorinvoicesRQ, refetch: refetchinvoicesRQ } = useQuery(['invoices',auth.currentUser?.user_id], load_invoices,{staleTime: 30*60*1000});



  const dispatch = useDispatch()
  const [invoiceID,setInvoiceID]= React.useState(data.invoice_id)
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    const body = values
    console.log('onFinish', body)
    validateInvoiceClient(body)
    form.resetFields();
  };

  const validateInvoiceClient = async (body: object) => {
    console.log('sendInvoice()',body)
    const resp = await ApiService.ValidateInvoiceClient(body)
    if (resp.status == 200) {
      dispatch(fetchInvoices());
      refetchinvoicesRQ()
      toast.success("Facture bien re\u00e7ue !", {
        duration: 5000, // 5000 milliseconds = 5 seconds
      });
    }
    
  }



  const onChangeCheck = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const customRequest = async ({ onSuccess, onError, file }) => {

    const data = new FormData()
    data.append('file', file)
    data.append('invoice_id', invoiceID)
    const config = {
      "headers": {
        "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
      }
    }
    const resp = await ApiService.UploadInvoice(data)
    if (resp.status == 200) {
      onSuccess(null, file);
    }

  };


  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'application/pdf';
    if (!isJpgOrPng) {
      console.log('seulement des fichiers au format PDF');
      toast.error('seulement des fichiers au format PDF')
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      console.log('Taille maximum = 2MB!');
      toast.error('Taille maximum = 2MB!')
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <>
              { data.total_amount === 0 ?

                
                
                
                <>   
                <br></br>   
                <Button style={{marginBottom:'30px'}} onClick={ () => validateInvoiceClient({'comments':'facture à 0€','invoice_id':data.invoice_id }) }>Je confirme aucune production ce mois-ci</Button>
                <div>Vous n'avez pas besoin de transmettre une facture</div>
                </>
              

                :

                <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={onFinish}
                initialValues={{ invoice_id: data.invoice_id }}
              >

                <Form.Item label="Référence" name="invoice_id">
                  <Input disabled defaultValue={data.invoice_id} />
                </Form.Item>
                <Form.Item label="Commentaire" name="comments">
                  <TextArea rows={1} />
                </Form.Item>
                <Form.Item
                  label="Votre Facture"
                  name="facture"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "votre devez nous faire parvenir votre facture",
                    }
                  ]}

                >
                  <Upload 
                    customRequest={customRequest} 
                    listType="picture-card" 
                    beforeUpload={beforeUpload}
                    maxCount={1} 
                    accept="application/pdf">
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Télécharger</div>
                    </div>
                  </Upload>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit">Je valide et je charge ma facture</Button>
                </Form.Item>
              </Form>
                
              }
              

    </>
  )
}
export default FormValidateComponent
