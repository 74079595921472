import React, { useState } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import { Modal, Tabs } from "antd"
import { Icon, IconNames } from "../components/Icon"
import Sites from "../components/Manage/Sites"
import AddSite from "../components/Manage/AddSite"
import UserTable from "../components/UserTable"
import ModalUser from "../components/UserTable/ModalUser"
import ModalProduct from "../components/ProductTable/ModalProduct"
import FacturesTable from "../components/Manage/Factures"
import ContractsTable from "../components/Manage/Contracts"
import { RootStateOrAny, useSelector } from "react-redux"
import SPV from "../components/Manage/SPV"
import Users from "../components/Manage/Users"
import Products from "../components/Manage/Products"
import FactureAdmin from "../components/Manage/FactureAdmin"
import ContratAdmin from "../components/Manage/ContratAdmin"
import SPVAdmin from "../components/Manage/SPVAdmin"
import SitesAdmin from "../components/Manage/SitesAdmin"
import DataAdmin from "../components/Manage/DataAdmin"
import MarketService from "../components/Manage/MarketService"
import Demo from "../components/Simulation/Demo"
import Gos from "../components/Simulation/Gos"

export default function SimulationAdmin() {



  const [modalProductOpen, setmodalProductOpen] = useState(false)
  const [modalUserOpen, setmodalUserOpen] = useState(false)
  const [modalUserOpen2, setmodalUserOpen2] = useState(false)
  const [modalProductOpen2, setmodalProductOpen2] = useState(false)
  const [selecteduser, setSelecteduser] = useState()
  const { companies } = useSelector(
    (state: RootStateOrAny) => state.commun
  )
  const handleCloseModal = () => {
    setmodalProductOpen(false)
  }
  
  const [activeTab, setactiveTab] = useState("1")

  const ActionButton = () => {
    const actionFunction = () => {
      activeTab == "2" && setmodalProductOpen2(true)
      activeTab == "1" && setmodalUserOpen2(true)
    }
    if (activeTab == "1" || activeTab == "2")
      return (
        <button
          className="bg-violet-bohr text-white flex items-center justify-center py-2.5 px-4 rounded-lg"
          type="button"
          onClick={actionFunction}
        >
          <Icon name={IconNames.plusCircle} />
          <span className="ml-2.5">
            {activeTab == "2" && "Ajouter un produit"}
            {activeTab == "1" && "Ajouter un utilisateur"}
          </span>
        </button>
      )
    else return null
  }
  const handleEditUser = (user:any)=>{
    const com = companies?.filter(c=>c.name==user.company)[0]
    setSelecteduser({...user, company:com.public_id, entreprise:com.name})
    setmodalUserOpen(true)
  }
  

  return (
    <Layout isDashboard={false}>
      <Seo title="Bohr Energie | Gestion" />
      <Layout.Header>
        <h1 style={{ fontSize: 40 }} className="font-semibold text-3xl mb-0">
          Simulation
        </h1>
      </Layout.Header>
      <Tabs
        activeKey={activeTab}
        onChange={key => setactiveTab(key)}
        defaultActiveKey="1"
        items={[
          {
            label: `Prospect`,
            key: "1",
            children: <Demo />,
          },
          
          {
            label: `GOs`,
            key: "2",
            children: <Gos />,
          },
          // {
          //   label: `Finance`,
          //   key: "3",
          //   children: <Products />,
          // }
        ]}
      />
    </Layout>
  )
}
