import React, { lazy, useEffect, useState, Suspense } from "react"
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Avatar, Col, Collapse, message, Row, Select, Table, Tag, Timeline, Tooltip, Upload } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';
import SelectDropdown from "../SelectDropdown"
import { fetchCompanies, FetchUploadFile } from "../../redux/actions/commun"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import ApiService from "../../services/ApiService";
import { DatePicker, Space } from 'antd';
import { fetchInvoices, fetchSitesDetail } from "../../redux/actions";
import { TreeSelect } from 'antd';
import toast from "react-hot-toast"
import moment from 'moment'
import { Colors, Icon, IconNames } from "../Icon";
import Text from "../Text"
import { SmileOutlined } from '@ant-design/icons';
import { Modal } from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"
import TextArea from "antd/es/input/TextArea";
import TimelineComponent from "../Facturation/Timeline";
import UtilFactureComponent from "../Facturation/UtilFacture";
import { SyncOutlined } from "@material-ui/icons";
import FormReviewComponent from "../Facturation/FormReview";
import FormPaidComponent from "../Facturation/FormPaid";
import { useQuery } from "react-query"
import { load_companies } from "../../actions/load_companies"
import { load_invoices } from "../../actions/load_invoices"
import { load_sites_details } from "../../actions/load_sites_details"
const { confirm } = Modal


const { SHOW_PARENT } = TreeSelect;





const { Panel } = Collapse
// const { confirm } = Modal


export default function FactureAdmin() {



  const commun = useSelector((state: RootStateOrAny) => state.commun)
  const sites_ = useSelector((state: RootStateOrAny) => state.sites)
  const invoices_ = useSelector((state: RootStateOrAny) => state.invoices)

  const dispatch = useDispatch()

  const [selectedSPV, setSelectedSPV] = useState(Array())
  const [Dates, setDates] = useState(Array())
  const [Status, setStatus] = useState(Array())
  const [Labels, setLabels] = useState(Array())
  const [selectedDate, setSelectedDate] = useState([])
  const [selectedLabel, setSelectedLabel] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])

  const [selectedSites, setSelectedSites] = useState(Array())

  const [invoices, setInvoices] = useState(Array())
  const [currentinvoice, setcurrentInvoice] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)



  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const { data: CompaniesRQ, isLoading: isLoadingCompaniesRQ, isError: isErrorCompaniesRQ, refetch: refetchCompaniesRQ } = useQuery(['companies', auth.currentUser?.user_id], load_companies, { staleTime: 30 * 60 * 1000 });
  const { data: SiteDetailsRQ, isLoading: isLoadingSiteDetailsRQ, isError: isErrorSiteDetailsRQ, refetch: refetchSiteDetailsRQ } = useQuery(['site_details', auth.currentUser?.user_id], load_sites_details, { staleTime: 1000 * 60 * 60 });

  const [sites, setSites] = useState(Array())
  const [SPV, setSPV] = useState(Array())




  useEffect(() => {
    dispatch(fetchSitesDetail())
    dispatch(fetchInvoices())
    // dispatch(fetchSitesDetail())
    // dispatch(fetchCompanies())

    if (commun.companies) {
      setSPV(commun.companies)
    }
    if (sites_.data) {
      setSites(sites_.data)
    }

    if (invoices_.data) {
      setInvoices(invoices_.data)
      setLoading(false)
      const uniques = extractUnique(invoices_.data)
      setDates(uniques.dates)
      setStatus(uniques.status)
      setLabels(uniques.labels)
    }
  }, [])


  useEffect(() => {
    console.log("useEffect invoices")
    if (invoices_.data) {
      console.log("useEffect invoices f", invoices_.data)
      setInvoices(invoices_.data)
      setLoading(false)
      const uniques = extractUnique(invoices_.data)
      setDates(uniques.dates)
      setStatus(uniques.status)
      setLabels(uniques.labels)

    }
  }, [invoices_.data])


  useEffect(() => {
    // dispatch(fetchCompanies())
    const companies = commun.companies?.map(item => { return { ...item, name: item.name.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()) } })
    setSPV(companies)
  }, [])

  useEffect(() => {

    setSites(sites_.data)
    console.log('setTree', sites_.data, SPV)
    setTree(generateTarget(sites_.data, SPV))
  }, [sites_.data, SPV])


  const extractUnique = (arr) => {

    const resultArray = [];
    const uniqueDate = [];

    const resultStatus = [];
    const uniqueStatus = [];

    const resultLabels = [];
    const uniqueLabels = [];

    for (const obj of arr) {
      // dates
      const PeriodEnd = moment(obj.invoice_id.slice(0, 6), "YYYYMM").endOf('month').format('YYYY-MM-DD')

      if (!uniqueDate.includes(PeriodEnd)) {
        resultArray.push({ "label": PeriodEnd, "value": PeriodEnd });
      }
      uniqueDate.push(PeriodEnd)

      // status
      const Status = obj.status
      if (!uniqueStatus.includes(Status)) {
        resultStatus.push({ "label": Status, "value": Status });
      }
      uniqueStatus.push(Status)

      // labels
      const Labels = obj.invoice_label
      if (!uniqueLabels.includes(Labels)) {
        resultLabels.push({ "label": Labels, "value": Labels });
      }
      uniqueLabels.push(Labels)




    }
    resultArray.sort((a, b) => a.label.localeCompare(b.label));
    resultStatus.sort((a, b) => a.label.localeCompare(b.label));
    resultLabels.sort((a, b) => a.label.localeCompare(b.label));
    return { dates: resultArray, status: resultStatus, labels: resultLabels }
  }

  const onChange = (key) => {
    console.log(key)
  };

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
    const x = (value + '').split(',')
    if (x && x.length > 0) {
      setSelectedDate(x)
    }
  };

  console.log('selectedDate', selectedDate)
  const delay = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  const [form] = Form.useForm()
  const [formReview] = Form.useForm()

  const onFinish = async (values: any) => {

    console.log('onFinish', values)
    const start_date = moment(`${values.Mois.$y} ${values.Mois.$M + 1}`, "YYYY MM").startOf('month').format('YYYY-MM-DD');
    const end_date = moment(`${values.Mois.$y} ${values.Mois.$M + 1}`, "YYYY MM").endOf('month').add(1, 'days').format('YYYY-MM-DD');
    // the end date is excluded so for the month of august in full we request : 2023-08-01 - 2023-09-01
    for (const item of values.Sites) {
      await createInvoice(start_date, end_date, item);
      await delay(1000);
      dispatch(fetchInvoices());
    }


  };

  function getTitle(data, targetValue) {
    // Define a recursive function to search for the target value
    function findTitle(arr, target) {
      for (const item of arr) {
        if (item.value === target) {
          return item.title;
        }
        if (item.children && item.children.length > 0) {
          const result = findTitle(item.children, target);
          if (result) {
            return result;
          }
        }
      }
      return null; // Return null if the target value is not found
    }

    // Call the recursive function to search for the title
    return findTitle(data, targetValue);
  }

  const createInvoice = (start_date, end_date, site_id) => {
    return new Promise((resolve, reject) => {
      toast.promise(ApiService.CreateInvoice({ start_date, end_date, site_id }), {
        loading: `Creating Invoice For : ${getTitle(tree, site_id)}`,
        success: (data) => {
          resolve(data); // Resolve the promise when successful
          return `${data.data.message}`;
        },
        error: (err) => {
          reject(err); // Reject the promise on error
          console.log('erreur invoice', err.response)
          return `${err.response.data.message.toString()}`;
        }
      });
    });
  };



  function generateTarget(sites, SPV) {


    if (sites && SPV) {

      const Target = SPV.filter(spv => spv.invoicing_activated == true).map(spvItem => {
        const filteredSites = sites.filter(site => site.companies_public_id === spvItem.public_id && site.invoicing_activated == true);

        return {
          value: spvItem.public_id,
          key: spvItem.public_id,
          title: spvItem.name,
          children: filteredSites.map(site => ({
            value: site.public_id,
            key: site.public_id,
            title: site.name,
            prm: site.prm_number,
          })),
        };
      });


      return Target;
    }

  }


  const [tree, setTree] = useState(generateTarget(SiteDetailsRQ, CompaniesRQ));
  const [prmSelected, setPrmSelected] = useState([]);

  const onChangeTree = (newValue: string) => {
    setPrmSelected(newValue);
  };


  console.log('SiteDetailsRQ', SiteDetailsRQ)
  // Facture Tableau 


  const handleSend = record => {
    confirm({
      title: "Etes-vous sûr de vouloir envoyer cette facture ?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      onOk() {
        console.log('sending invoice', record)
        sendInvoice(record.invoice_id)
      },
      onCancel() {
      },
    })
  }

  const sendInvoice = async (invoice_id: string) => {
    console.log('sendInvoice()')
    const resp = await ApiService.SendInvoice({ invoice_id })
    if (resp.status == 200) {
      dispatch(fetchInvoices());
    }
  }



  const handleDelete = record => {
    confirm({
      title: "Etes-vous sûr de supprimer cette facture ?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      onOk() {
        console.log('delete invoice', record)
        deleteInvoice(record.invoice_id)
      },
      onCancel() {
      },
    })
  }


  const deleteInvoice = async (invoice_id: string) => {
    const resp = await ApiService.DeleteInvoice({ invoice_id })
    if (resp.status == 200) {
      dispatch(fetchInvoices());
    }
  }

  const [modalPdfOpen, setmodalPdfOpen] = useState(false)

  const [pdfContent, setPdfContent] = useState(null)

  const handleCloseModalPdf = () => {
    setmodalPdfOpen(false)
  }

  const genExtra = invoice => (
    <Space>

      {invoice.status === "en edition" ?

        <Icon
          color={Colors.primary}
          title={"Envoyé au client (" + invoice.sent_to + ')'}
          className={"cursor-pointer"}
          style={{ right: 25, position: "relative" }}
          onClick={() => handleSend(invoice)}
          name="mail"
        />
        :
        invoice.status === "envoyé au client" ?
          <Icon
            color={Colors.primary}
            title="En attente du retour client"
            className={"cursor-pointer"}
            style={{ right: 25, position: "relative" }}
            name="clock"
          />
          :
          invoice.status === "validé client" ?
            <Icon
              color={"green"}
              title="En attente validation Bohr"
              className={"cursor-pointer"}
              style={{ right: 25, position: "relative" }}
              name="check"
            />
            :
            invoice.status === "conflit" ?
              <Icon
                color={"red"}
                title="Annulé la FAR"
                className={"cursor-pointer"}
                style={{ right: 25, position: "relative" }}
                name="check"
              />
              :
              invoice.status === "validé bohr" ?
                <Icon
                  color={Colors.primary}
                  title="En attente du paiment"
                  className={"cursor-pointer"}
                  style={{ right: 25, position: "relative" }}
                  name="clock"
                />
                :
                invoice.status === "payé" ?
                  <Icon
                    color={Colors.primary}
                    title="tout est bon !"
                    className={"cursor-pointer"}
                    style={{ right: 25, position: "relative" }}
                    name="dollar"
                  />
                  :
                  <></>
      }

      <Icon
        color={Colors.primary}
        title="Supprimer"
        className={"cursor-pointer"}
        style={{ right: 25, position: "relative" }}
        onClick={() => handleDelete(invoice)}
        name={IconNames.delete}
      />



    </Space>
  )

  const PanelHeader = ({ data }) => (
    <Row className="flex items-center">
      <Col xs={24} sm={12} md={4} lg={3}>
        <Text className="text-gray-6f" type="12-500">
          Date
        </Text>
        <Text className="block" type="14-500">
          {moment(data.invoice_id.slice(0, 6), "YYYYMM").endOf('month').format('YYYY-MM-DD') || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={4}>
        <Text className="text-gray-6f" type="12-500">
          Référence
        </Text>
        <Text className="block" type="14-500">
          {data.invoice_id || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={9}>
        <Text className="text-gray-6f" type="12-500">
          Label
        </Text>
        <Text className="block" type="14-500">
          <Text type="16-600" style={{ textTransform: 'capitalize' }}>{data.invoice_label}</Text>
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={3}>
        <Text className="text-gray-6f" type="12-500">
          Montant
        </Text>
        <Text className="block" type="14-500">
          {parseFloat(data.total_amount).toLocaleString('fr') + "€" || "-"}
        </Text>
      </Col>

      <Col style={{ textAlign: 'center' }} xs={24} sm={12} md={4} lg={5}>
        {data.status === "en edition" ?
          <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#78C0E0", width: '130px' }}>{data.status || "-"}</Tag>
          :
          data.status === "envoyé au client" ?
            <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#449DD1", width: '130px' }}>{data.status || "-"}</Tag>
            :
            data.status === "conflit" ?
              <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "red", width: '130px' }}>{data.status || "-"}</Tag>
              :
              data.status === "validé client" ?
                <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#192BC2", width: '130px' }}>{data.status || "-"}</Tag>
                :
                data.status === "validé bohr" ?
                  <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#150578", width: '130px' }}>{data.status || "-"}</Tag>
                  :
                  data.status === "payé" ?
                    <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#0E0E52", width: '130px' }}>{data.status || "-"}</Tag>
                    :
                    <></>
        }

      </Col>


    </Row>
  )


  const columns = [
    {
      title: "product",
      dataIndex: "product",
      key: "product",
      responsive: ["sm"],
      width: '40%',
      render: (_, { product }) => (
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {product}
        </span>
      ),
    },
    {
      title: "volume",
      dataIndex: "volume",
      key: "volume",
      responsive: ["sm"],
      width: '15%',
      editable: true,
      render: (_, { volume, product }) => (
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {parseFloat(volume)} {product.includes("capacité") ? "GC" : "MWh"}
        </span>
      ),
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      responsive: ["sm"],
      width: '15%',
      render: (_, { price }) => (
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {parseFloat(price).toLocaleString('fr') + "€"}
        </span>
      ),
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
      responsive: ["sm"],
      width: '15%',
      render: (_, { amount }) => (
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {parseFloat(amount).toLocaleString('fr') + "€"}
        </span>
      ),
    },
    {
      title: "tva",
      dataIndex: "tva",
      key: "tva",
      responsive: ["sm"],
      width: '15%',
      render: (_, { tva }) => (
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {parseFloat(tva).toLocaleString('fr') + "%"}
        </span>
      ),
    }
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (

    //   ),
    // },
  ]


  const PanelBody = ({ data }) => {
    return (
      <Row style={{ marginTop: 20 }}>
        <Col flex={2}>

          <div style={{ marginBottom: 40, fontSize: '16px', fontWeight: '600' }}> Votre Facture à recevoir </div>
          {
            data.invoices_details.map((item) =>

              <>
                <div style={{ marginTop: 20, marginBottom: 20, fontSize: '16px', fontWeight: '600' }}> {item.reference_label} </div>
                <Table
                  size="small"
                  className="text-sm"
                  columns={columns}
                  dataSource={item.lines}
                  rowKey={"product"}
                  pagination={false}
                  style={{ width: '100%' }}
                  summary={pageData => {
                    let totalAmount = 0;

                    item.lines.forEach(({ product, amount }) => {
                      if (product.includes("Frais")) {
                        totalAmount -= parseFloat(amount);
                      } else {
                        totalAmount += parseFloat(amount);
                      }

                    });

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell className="text-md font-bold">Total</Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md">-</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md">-</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md font-bold">{totalAmount.toFixed(2).toLocaleString('fr') + "€"}</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md">-</div>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </>
            )
          }


        </Col>

        <Col flex={1}>
        </Col>

        <Col flex={2}>

          {data.status === 'en edition' ?
            <>
              <TimelineComponent data={data} />
            </>
            :
            data.status === 'envoyé au client' ?
              <>
                <TimelineComponent data={data} />
              </>
              :
              data.status === 'validé client' ?
                <>

                  <div style={{ marginBottom: 20, fontSize: '16px', fontWeight: '600' }}> Notre Validation </div>

                  <FormReviewComponent data={data} />

                  <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                </>
                :
                data.status === "validé bohr" ?
                  <>

                    <TimelineComponent data={data} />
                    <FormPaidComponent data={data} />
                    <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                  </>
                  :
                  data.status === "conflit" ?
                    <>
                      <TimelineComponent data={data} />
                    </>
                    :
                    data.status === "payé" ?

                      <>
                        <TimelineComponent data={data} />
                        <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                      </>

                      :
                      <></>

          }

        </Col>




      </Row>
    )

  }

  const handleSelectSPV = val => {
    setSelectedSPV(val.map(i => i.public_id))
  }
  const handleSelectDate = val => {
    setSelectedDate(val.map(i => i.period_end))
  }


  console.log('StatusFilter', selectedStatus.length)
  console.log('selectedLabel', selectedLabel.length)
  console.log('date', selectedDate)

  return (

    <Suspense fallback={<div> Please Wait... </div>} >

      <div className="UserTable px-6 py-4 rounded-xl bg-white mb-15">
        <div className="md:flex justify-between  items-center pb-4">
          <div className="text-base font-semibold	mb-4 md:mb-0">
            Editeur de Facture
          </div>
        </div>


        <Space direction="vertical">

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{ marginTop: '0px' }}
            initialValues={{ role: 'admin' }}>

            <Form.Item
              name="Mois"
              label={<label style={{ color: "black" }}>Mois</label>}
              rules={[
                {
                  required: true,
                  message: "Vous devez choisir un mois",
                },
              ]}
            >
              <DatePicker onChange={onChange} picker="month" format="YYYY-MM" />
            </Form.Item>

            <Form.Item
              name="Sites"
              label={<label style={{ color: "black" }}>Sites</label>}
              rules={[
                {
                  required: true,
                  message: "Vous devez choisir au moins un site",
                },
              ]}
            >
              <TreeSelect
                showSearch
                style={{ width: '500px' }}
                value={prmSelected}
                dropdownStyle={{ overflow: 'auto' }}
                placeholder="Please select"
                allowClear
                multiple
                treeCheckable
                treeDefaultExpandAll
                onChange={onChangeTree}
                treeData={tree} />
            </Form.Item>

            <Form.Item >
              <Button htmlType="submit" style={{ color: "black" }}>
                Creer les factures à recevoirs
              </Button>
            </Form.Item>

          </Form>
        </Space>

      </div>


      <div className="UserTable px-6 py-4 rounded-xl bg-white my-8">
        <div className="md:flex justify-between  items-center pb-4">
          <div className="text-base font-semibold	mb-4 md:mb-0">
            Factures
          </div>
          <div className="flex justify-center" style={{ width: '600px' }}>

            <select style={{ margin: '10px' }} value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
              <option value="">Select Date</option>
              {Dates.map((date) => (
                <option key={date.value} value={date.value}>
                  {date.label}
                </option>
              ))}
            </select>

            <select style={{ margin: '10px' }} value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
              <option value="">Select Status</option>
              {Status.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>

            <select style={{ margin: '10px' }} value={selectedLabel} onChange={(e) => setSelectedLabel(e.target.value)}>
              <option value="">Select Label</option>
              {Labels.map((label) => (
                <option key={label.value} value={label.value}>
                  {label.label}
                </option>
              ))}
            </select>

          </div>
          <div style={{ cursor: 'pointer' }}>
            <SyncOutlined onClick={() => dispatch(fetchInvoices())} />
          </div>
        </div>



        <Collapse
          // defaultActiveKey={["0"]}
          onChange={onChange}
          accordion
          expandIconPosition="end"
          ghost
          collapsible="icon"

          expandIcon={({ isActive }) => (
            <Icon name={isActive ? "arrow-up" : "arrow-down"} />
          )}
        >






          {invoices
            .filter((item) => {
              const dateFilter =
                selectedDate.length === 0 || selectedDate === moment(item.invoice_id.slice(0, 6), "YYYYMM").endOf('month').format('YYYY-MM-DD');
              const statusFilter = selectedStatus.length === 0 || selectedStatus === item.status;
              const labelFilter = selectedLabel.length === 0 || selectedLabel === item.invoice_label;
              return dateFilter && statusFilter && labelFilter;
            })
            .map((invoice: any, index: number) => {
              return (
                <Panel
                  header={<PanelHeader data={invoice} />}
                  key={index + ""}
                  extra={genExtra(invoice)}
                >
                  <PanelBody data={invoice} />
                </Panel>
              );
            })
          }

          {/* {Array.isArray(selectedDate) && selectedDate[0] !== '' ?

            invoices?.filter((item) => selectedDate.includes(moment(item.period_end).format('YYYY-MM-DD'))).map((invoice: any, index: number) => {
              return (
                <Panel
                  header={<PanelHeader data={invoice} />}
                  key={index + ""}
                  extra={genExtra(invoice)}
                >
                  <PanelBody data={invoice} />
                </Panel>
              )

            })
            :
            invoices?.map((invoice: any, index: number) => {
              return (
                <Panel
                  header={<PanelHeader data={invoice} />}
                  key={index + ""}
                  extra={genExtra(invoice)}
                >
                  <PanelBody data={invoice} />
                </Panel>
              )
            })
          } */}



        </Collapse>

      </div>

      <Modal
        className="modal-fullscreen rounded-none h-full w-full p-0 m-0 all-Campton"
        open={modalPdfOpen}
        onCancel={handleCloseModalPdf}
        bodyStyle={{ minHeight: "100vh" }}
        width="100%"
        footer={null}
      >
        {pdfContent}
      </Modal>

    </Suspense>
  )
}
function setSPV(companies: any) {
  throw new Error("Function not implemented.");
}

