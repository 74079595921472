import React, { useEffect, useState, useRef } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import { Card, Col, Collapse, Row, Spin, Table } from "antd"
import openSocket from 'socket.io-client';
import { Icon } from "../components/Icon";
import Text from "../components/Text"
import echarts from 'echarts';
import GaugeChart from "../components/Charts/GaugeChart";
import GaugeChart2 from "../components/Charts/GaugeChart2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../services/ApiService";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import EChartBar from "../components/Charts/EChartBar";
import { fetchSitesDetail } from "../redux/actions";
import { useQuery } from "react-query";
import { load_live_production } from "../actions/load_live_production";


const ws_url = process.env.SOCKET_URL

const { Panel } = Collapse

export default function EnDirect() {

  const dispatch = useDispatch()

  const sites = useSelector((state: RootStateOrAny) => state.sites)
  const [loading, setLoading] = useState(true);
  const [prmToName, setPrmToName] = useState({});
  const [prm, setPrm] = useState(sites.data?.map(item => item.prm_number) || []);
  const [lastRoomMessages, setLastRoomMessages] = useState({});


  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const { data: LiveRQ, isLoading: isLoadingLiveRQ, isError: isErrorLiveRQ, refetch: refetchLiveRQ } = useQuery(['live', auth.currentUser?.user_id], load_live_production, { staleTime: 1000 * 60 * 1 });

  console.log('LiveRQ', LiveRQ)


  React.useEffect(() => {

    dispatch(fetchSitesDetail())

    const fetchData = async () => {
      setLoading(true)
      try {
        const resp = await ApiService.GetLiveProduction();
        if (resp.status === 200) {
          setLastRoomMessages(resp.data.liveproduction);
          setLoading(false)
        }
      } catch (error) {
        // Handle the error here if needed
        setLoading(false)
      }
    };
    fetchData();
  }, []);


  React.useEffect(() => {


    if (sites.data) {

      const x = sites.data?.reduce((acc, obj) => {
        acc[obj.prm_number] = obj.name;
        return acc;
      }, {});

      setPrmToName(x)
      setPrm(sites.data?.map(item => item.prm_number))


    }


  }, [sites.data]);



  // const [lastRoomMessages, setLastRoomMessages] = useState({
  //   '50085962322506': [{
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 10,
  //     "element": "turbine",
  //     "type": "p_active_g1",
  //     "unit": "kw"
  //   },
  //   {
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 30,
  //     "element": "turbine",
  //     "type": "p_active_g2",
  //     "unit": "kw"
  //   },{
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 30,
  //     "element": "sonde",
  //     "type": "hauteur",
  //     "unit": "kw"
  //   },
  //   {
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 30,
  //     "element": "compteur",
  //     "type": "idx pa",
  //     "unit": "kw"
  //   },],
  //   'inf50': [{
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 10,
  //     "element": "turbine",
  //     "type": "p_active_g1",
  //     "unit": "kw"
  //   },
  //   {
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 0,
  //     "element": "turbine",
  //     "type": "p_active_g2",
  //     "unit": "kw"
  //   },],
  //   '123456789': [{
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 30,
  //     "element": "turbine",
  //     "type": "p_active_g4",
  //     "unit": "kw"
  //   },
  //   {
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 58,
  //     "element": "turbine",
  //     "type": "p_active_g1",
  //     "unit": "kw"
  //   },
  //   {
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 200,
  //     "element": "turbine",
  //     "type": "p_active_g2",
  //     "unit": "kw"
  //   },
  //   {
  //     "start_time": "2023-10-04 19:00:02+0000",
  //     "created_time": "2023-10-04 19:00:16+0000",
  //     "prm": "50085962322506",
  //     "measure": 0,
  //     "element": "turbine",
  //     "type": "p_active_g3",
  //     "unit": "kw"
  //   }]
  // });




  // React.useEffect(() => {


  //     const sockets = prm.map(room => openSocket(`${ws_url}?channel=${room}`, { transports: ['websocket'] }));
  //     console.log('sockets',sockets)

  //     prm.forEach((room, index) => {
  //       subscribeToChanges(sockets[index], room);
  //     });

  //     return () => {
  //       sockets.forEach(socket => socket.close());
  //     }



  // }, []);


  // const subscribeToChanges = (socket, room) => {
  //   socket.on(room, message => {
  //     setLastRoomMessages(prevLastMessages => ({
  //       ...prevLastMessages,
  //       [room]: message
  //     }));

  //   });
  // };


  const columns = [
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => <div style={{ fontWeight: 'normal', fontSize: '12px' }}>{text.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())}</div>,
    },
    {
      title: 'measure',
      dataIndex: 'measure',
      key: 'measure',
      render: (text) => <div style={{ fontWeight: 'normal', fontSize: '12px', textAlign: 'right' }}>{Number(text).toFixed(0)}</div>,
    }, {
      title: 'unit',
      dataIndex: 'unit',
      key: 'unit',
      render: (text) => <div style={{ fontWeight: 'normal', fontSize: '12px' }}>{text}</div>,
    }]


  return (

    <Layout isDashboard={true} showSignlerArret showContactUs>
      <Seo title="Bohr Energie | Live " />
      <Layout.Header>
        <h1 style={{ fontSize: 40 }} className="font-semibold text-3xl mb-0">
          En Direct
        </h1>
      </Layout.Header>




      <div className="flex-wrap" style={{ display: "flex", marginTop: '50px' }}>
        <img
          src="/images/accueil.png"
          alt="dashboard"
          style={{ width: 167, position: "absolute", right: 66, top: 14 }}
          className="hidden md:block"
        />






        {LiveRQ && Object.keys(LiveRQ).length > 0 ?


          Object.keys(LiveRQ).map(site => (
            // <Panel
            //   style={{ marginBottom: '10px', borderBottom: '1px #f1f1f1 solid' }}
            //   header={<PanelHeader site={site} />}
            //   key={index + ""}
            // >
            //   <PanelBody message={lastRoomMessages[site]} />
            // </Panel>



            // extra={<div>Mis à jour : {moment(lastRoomMessages[site][0]['start_time']).format('DD/MM HH:mm')}</div>}
            <Card className="w-full md:w-1/2" title={prmToName[site]} extra={<div>Mis à jour : {moment(LiveRQ[site][0]['start_time']).format('DD/MM HH:mm')}</div>}>
              <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {LiveRQ[site].filter((item) => item.element === "turbine").map((item) => (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <GaugeChart2 data={Number(item.measure)} max={250} />
                    <div>{item.type.slice(-2)}</div>
                  </div>
                ))}

                {LiveRQ[site].filter(item => item.type === 'niveau_eau').length > 0 ?

                  <div style={{ height: '100%' }}>
                    <EChartBar data={LiveRQ[site].filter(item => item.type === 'niveau_eau')[0].measure} unit={LiveRQ[site].filter(item => item.type === 'niveau_eau')[0].unit} />
                  </div>
                  :
                  <></>

                }



              </Row>



              <Row justify="center" style={{ marginTop: '10px' }}>
                <Collapse defaultActiveKey={[]} expandIcon={({ isActive }) => (
                  isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
                )} ghost collapsible="icon">
                  <Collapse.Panel header="Voir Plus">
                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '30px' }}>
                      <Table
                        title={() => 'Sonde'}
                        showHeader={false}
                        pagination={false}
                        size="small"
                        columns={columns}
                        dataSource={LiveRQ[site].filter(object => object.element === 'sonde')}
                      />
                      <Table
                        title={() => 'Compteur'}
                        showHeader={false}
                        pagination={false}
                        size="small"
                        columns={columns}
                        dataSource={LiveRQ[site].filter(object => object.element === 'compteur')}
                      />
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Row>









            </Card>
          ))

          :
          isLoadingLiveRQ ?
            <Spin></Spin>
            :
            <div>Installez la box Bohr dans vos centrales pour recevoir vos informations de production en direct</div>

        }

      </div>


    </Layout>
  )
}
