import { FC, useEffect, useState } from "react"
import { navigate } from "gatsby"
import UserService from "../services/UserService"
import { RouteComponentProps } from "@reach/router"


type Props = RouteComponentProps<{
  component: any
  superadmin: boolean
  admin: boolean
  viewer: boolean
}>
const PrivateRoute: FC<Props> = ({
  component: Component,
  location,
  superadmin = false,
  admin = false,
  viewer = false,
  ...rest
}) => {



  // Is the user Logged or not ?

  const isLogged = UserService.isLoggedIn()
  console.log('isLogged',isLogged)
  if (
    !isLogged && 
    location?.pathname !== `/app/login` &&
    location?.pathname !== `/app/register`
  ) {
    typeof window !== "undefined" && navigate("/app/login")
    return null
  }

  const isSuperAdmin = UserService.isSuperadmin();
  const isAdmin = UserService.isAdmin();
  const isViewer = UserService.isViewer();

  const hasRequiredRole = (superadmin && isSuperAdmin) || (admin && isAdmin) || (viewer && isViewer);

  // console.log('isSuperAdmin',isSuperAdmin)
  // console.log('isAdmin',isAdmin)
  // console.log('isViewer',isViewer)
  // console.log('const hasRequiredRole',hasRequiredRole)

  if (hasRequiredRole) {
    // If user has the required role, render the component
    return <Component {...rest} />
  } else {
    if (isSuperAdmin) {
      navigate("/app/admin");
    }
    if (isAdmin) {
      navigate("/app/dashboard");
    }
    if (isViewer) {
      navigate("/app/ensemble");
    }
    return null
  }

}
export default PrivateRoute
