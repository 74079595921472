import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import frLocale from "@fullcalendar/core/locales/fr"
import "./calendar.css"
import ModalEvent from "./ModalEvent"
import { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchOutages, fetchPeakPeriods } from "../../redux/actions"
import { stringToHexColor } from "../../utils"
import moment from "moment"
import ApiService from "../../services/ApiService"

const def_events = [
  {
    title: "Rivesaltes - Maintenance",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    start: "2023-01-12",
    end: "2023-01-15",
    color: "#FF7B4C40",
  },
  {
    title: "Orlu - Maintenance",
    start: "2023-01-12",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    end: "2023-01-13",
    color: "#6BCAFA40",
  },
  {
    title: "Bohr - Maintenance",
    start: "2023-01-12",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    end: "2023-01-12",
    color: "#FF7B4C40",
  },
  {
    title: "Rivesaltes - Maintenance",
    start: "2023-01-12",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    end: "2023-01-15",
    color: "#6BCAFA40",
  },
  {
    title: "Rivesaltes - Maintenance",
    start: "2023-01-12",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    end: "2023-01-15",
    color: "#6BCAFA40",
  },
  {
    title: "Rivesaltes - Maintenance",
    start: "2023-01-12",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    end: "2023-01-15",
    color: "#6BCAFA40",
  },
  {
    title: "Rivesaltes - Maintenance",
    start: "2023-01-12",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    end: "2023-01-15",
    color: "#6BCAFA40",
  },
  {
    title: "Rivesaltes - Maintenance",
    start: "2023-01-12",
    extendedProps: {
      data1: 'value1',
      data2: 'value2'
    },
    end: "2023-01-15",
    color: "#6BCAFA40",
  },
]

export function Calendar() {
  const {data, loading} = useSelector((state: RootStateOrAny) => state.outage)
  const peakperiod = useSelector((state: RootStateOrAny) => state.peakperiod)
  const [modalOpen, setmodalOpen] = useState(false)
  const [modalData, setmodalData] = useState<any>()
  const [events, setevents] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchOutages())
    dispatch(fetchPeakPeriods())
  }, [])



  useEffect(() => {

    // console.log('UE',data)
    // console.log('UE',peakperiod.data)

    var outageEvents = []
    if (data && data.length>0) {
        outageEvents = data?.map(item=>({
        title:`${item.name} - ${item.comment}`,
        start:moment(item.start_date).format('YYYY-MM-DD'),
        end:moment(item.end_date).format('YYYY-MM-DD'),
        color: stringToHexColor(item.name)+'40',
        extendedProps:item
      }))
    }

    var peakperiodEvents = []
    if (peakperiod?.data && peakperiod.data.length>0) {
        peakperiodEvents = peakperiod?.data.map(item=>({
        title: `${item.pp1 ? 'PP1' : ''}${item.pp1 && item.pp2 ? ' & ' : ''}${item.pp2 ? 'PP2' : ''}`,
        start:moment(item.start_time).format('YYYY-MM-DD'),
        end:moment(item.end_time).format('YYYY-MM-DD'),
        color: 'red',
        extendedProps:item
      }))
    }

    const combinedEvents = outageEvents.concat(peakperiodEvents);

    setevents(combinedEvents)

  }, [data,peakperiod.data])

console.log('events',events)

const handleClick = (event) => {
  console.log('handleClick', event.extendedProps);

  // Check if the "site_public_id" key exists in event.extendedProps
  if ("site_public_id" in event.extendedProps) {
    setmodalData(event.extendedProps);
    setmodalOpen(true);
  } else {
    // If "site_public_id" key doesn't exist, do nothing
    // You can optionally add a message here for clarity
    console.log('site_public_id not found in extendedProps');
  }
}

  return (
    <div>
      <ModalEvent data={modalData} open={modalOpen} onClose={()=>setmodalOpen(false)}/>
      <FullCalendar
        headerToolbar={{
          start: "",
          center: "prev,title,next",
          end: "",
        }}
        showNonCurrentDates={false}
        dayHeaderFormat={{ weekday: "long" }}
        locale={frLocale}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        eventContent={renderEventContent}
        eventBackgroundColor="red"
        eventTextColor="white"
        eventClassNames={"event-custom"}
        dayMaxEvents={3}
        eventClick={({event})=>{

            handleClick(event)
        
        }}
      />
    </div>
  )
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}
