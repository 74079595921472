import ApiService from "../services/ApiService";

export const load_enedis_r4q = async (
queryParams : any
) => {
  console.log('load_enedis_r4q',queryParams)
  const response = await ApiService.GetEnedisR4Q(queryParams)
  const rq_data = await response.data
  return rq_data;
};



