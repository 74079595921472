import ApiService from "../services/ApiService";

export const load_invoices = async () => {
  const response = await ApiService.GetInvoices()
  const rq_data = await response.data;
  return rq_data;
};



