import React, { useEffect } from 'react';
import * as echarts from 'echarts'; 

const EChartBar = ({data,unit}) => {
  console.log(data)


  const chartRef = React.useRef(null);
  // Initialize the chart once the component mounts
  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    // Chart configuration
    const option = {
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 40
      },
      xAxis: {
        type: 'category',
        data: ["Niveau d'Eau"],
        axisLine: {
          show: false, // Show Y-axis line
        },
        axisTick: {
          show: false, // Show Y-axis ticks
        },
        axisLabel: {
          color: 'black',
          fontSize: 12,
          fontWeight: 500,
          interval: 0,
          margin: 28,
        },
      },
      yAxis: {
        type: 'value',
        position: 'right', // Display Y-axis on the right
        axisLine: {
          show: true, // Show Y-axis line
        },
        axisTick: {
          show: false, // Show Y-axis ticks
        },
      },
      series: [
        {
          data: [data],
          type: 'bar',
          label: {
            show: true, // Display labels
            position: 'inside', // Display labels inside the bar
            color: 'white', // Label text color
            fontWeight: 'bold', // Label text bold
            formatter: function(params) {
              // Include both the data value and the unit in the label
              return `${Number(data)} ${unit}`;
            },
          },
        }
      ]
    };

    chart.setOption(option);

    // Cleanup: Destroy the chart when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div ref={chartRef} style={{ width: '80px', height: '140px' , marginTop:'20px',marginLeft:'30px'}} />;
};

export default EChartBar;
