import React, { useState } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import Params from "../components/Budget/Params"
import TableComponent from "../components/Budget/Table"
import ProjectionComponent from "../components/Budget/Projection"

export default function Budget() {
  return (
    <Layout isDashboard={false}>
      <Seo title="Bohr Energie | Gestion" />
      <Layout.Header>
        <h1 style={{ fontSize: 40 }} className="font-semibold text-3xl mb-0">
          Budget
        </h1>
      </Layout.Header>
      <TableComponent/>
    </Layout>
  )
}
