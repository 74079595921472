import React from "react"
import { legacy_createStore as createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux"
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist"
import rootReducer from "./redux/reducers"
import AlertMessage from "./components/AlertMessage"
import Realtime from './services/Realtime'
import { ConfigProvider } from "antd"

import "react-toastify/dist/ReactToastify.css"
import "antd/dist/reset.css"

import "./index.css"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react"
import Routes from "./Routes"

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const persistConfig = {
  key: "bohr",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, {}, applyMiddleware(thunk))
export const persistor = persistStore(store)

// Initializing connextion with backend through socketio
// Realtime.init(process.env.SOCKET_URL)
// Realtime.getConnection()

const queryClient = new QueryClient();

const App: React.FC = () => {
      /*constructor(props) {

      /Required step: always call the parent class' constructor
      super(props);       
      this.realtime=Realtime.getConnection()
      this.realtime.registerEvent('status_reported',(data)=>{
          if (data["message"]==="status_changed")
          {
              console.log("Event: Update")
              var task = data["task"]
              var message = data["message"]
              this.props.getData(this.props.prod_mode);
          } 
          else if (data["message"]==="priority_changed")
          {
              console.log("Event: Update Config")
              var task = data["task"]
              var message = data["message"]
              this.props.getDataConfig(this.props.prod_mode);
          }
      });
    }*/
  persistor.persist();

  return (
    <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <TopBar /> */}
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#5819f1",
            },
          }}
        >
          <Routes/>
        </ConfigProvider>
        <AlertMessage />
      </PersistGate>
    </Provider>
    <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
