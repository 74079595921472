// const initState = { loading: false, data:null, volume: null ,error: null }

import moment from 'moment';


const initState = { 
  volume : {
    loading: false, 
    data:null,
    error: null,
    expire: null 
  },
  gos : {
    loading: false, 
    data:null,
    error: null ,
    expire: null
  },
  mecapa : {
    loading: false, 
    data:null,
    error: null ,
    expire: null
  },
}

const dashboardReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "LOAD_DASHBOARD_VOLUME":
      return {
        ...state,
        volume: {
          ...state.volume,
          loading: true,
          data: null,
          error: null
        }
      }
    case "LOAD_DASHBOARD_VOLUME_SUCCESS":
      return {
        ...state,
        volume: {
          ...state.volume,
          loading: false,
          data: action.payload,
          error: null,
          expire : moment().date()
        }
      }
    case "LOAD_DASHBOARD_VOLUME_ERROR":
      return {
        ...state,
        volume: {
          ...state.volume,
          loading: false,
          data: null,
          error: action.payload
        }
      }
    case "LOAD_DASHBOARD_GOS":
      return {
        ...state,
        gos: {
          ...state.gos,
          loading: true,
          data: null,
          error: null
        }
      }
    case "LOAD_DASHBOARD_GOS_SUCCESS":
      return {
        ...state,
        gos: {
          ...state.gos,
          loading: false,
          data: action.payload,
          error: null,
          expire :moment().date()
        }
      }
    case "LOAD_DASHBOARD_GOS_ERROR":
      return {
        ...state,
        gos: {
          ...state.gos,
          loading: false,
          data: null,
          error: action.payload
        }
      }
    case "LOAD_DASHBOARD_MECAP":
      return {
        ...state,
        mecapa: {
          ...state.mecapa,
          loading: true,
          data: null,
          error: null
        }
      }
    case "LOAD_DASHBOARD_MECAPA_SUCCESS":
      return {
        ...state,
        mecapa: {
          ...state.mecapa,
          loading: false,
          data: action.payload,
          error: null,
          expire : moment().date()
        }
      }
    case "LOAD_DASHBOARD_MECAPA_ERROR":
      return {
        ...state,
        mecapa: {
          ...state.mecapa,
          loading: false,
          data: null,
          error: action.payload
        }
      }
    case "RESET_DASHBOARD":
        return initState
    default:
      return state

  }
}

export default dashboardReducer
