// export const hello = () => ({
//   type: 1,
//   payload: {
//     data: "hello world",
//   },
// });

import ApiService from "../../services/ApiService"
import moment from 'moment'
import toast, { Toaster } from 'react-hot-toast';

export function closeMessage() {
  return {
    type: "CLOSE",
  }
}
export function errMessage(message: string) {
  return {
    type: "MESSAGE",
    payload: {
      message,
      severity: "error",
    },
  }
}

export function successMessage(message: string) {
  return {
    type: "MESSAGE",
    payload: {
      message,
      severity: "success",
    },
  }
}


export const fetchDashboard = (show_by_site=true) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { dashboard } = getState()
    // if (dashboard.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_DASHBOARD" })
      const resp = await ApiService.GetDashboard({show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_DASHBOARD_SUCCESS", payload: resp.data.dashboard })
      }
    } catch (error) {
      dispatch({ type: "LOAD_DASHBOARD_ERROR", payload: error })
    }
  }
}

export const fetchDashboardEarning = (show_by_site=true) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const {earning}  = getState();
    const currentDate = moment().date()

    if (earning.earning.data && earning.earning.expire>=currentDate) {
      console.log('Earning In Cache');
      return  
    }



    try {
      dispatch({ type: "LOAD_DASHBOARD_EARNING" })
      const resp = await ApiService.GetDashboardEarning({show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_DASHBOARD_EARNING_SUCCESS", payload: resp.data.dashboard })
      }
    } catch (error) {
      console.log('fetchDashboardEarning',error)
      dispatch({ type: "LOAD_DASHBOARD_EARNING_ERROR", payload: error })
    }
  }
}



export function handle401(error:any) {

  if (error?.status === 401 || error?.response?.status === 401) {
    // console.log('handling 401')
    // console.log('redirecting to login')
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.clear()
    window.location.href = "/app/login"
  }
}

export const fetchDashboardVolume = (show_by_site=true) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const {dashboard}  = getState();
    const currentDate = moment().date()
    if (dashboard.volume.data && dashboard.volume.expire>=currentDate) {
      console.log('Volume In Cache');
      return  
    }


    try {
      dispatch({ type: "LOAD_DASHBOARD_VOLUME" })
      const resp = await ApiService.GetDashboardVolume({show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_DASHBOARD_VOLUME_SUCCESS", payload: resp.data.dashboard })
      }
    } catch (error) {
      console.log('fetchDashboardVolume',error)
      handle401(error)
      dispatch({ type: "LOAD_DASHBOARD_VOLUME_ERROR", payload: error })
    }
  }
}

export const fetchDashboardGos = (show_by_site=true) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const {dashboard}  = getState();
    const currentDate = moment().date()
    if (dashboard.gos.data && dashboard.gos.expire>=currentDate) {
      console.log('Gos In Cache');
      return  
    }

    try {
      dispatch({ type: "LOAD_DASHBOARD_GOS" })
      const resp = await ApiService.GetDashboardGos({show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_DASHBOARD_GOS_SUCCESS", payload: resp.data.dashboard })
      }
    } catch (error) {
      console.log('fetchDashboardGos',error)
      handle401(error)
      dispatch({ type: "LOAD_DASHBOARD_GOS_ERROR", payload: error })
    }
  }
}

export const fetchDashboardMecapa = (show_by_site=true) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const {dashboard}  = getState();
    const currentDate = moment().date()
    if (dashboard.mecapa.data && dashboard.mecapa.expire>=currentDate) {
      console.log('mecapa In Cache');
      return  
    }

    try {
      dispatch({ type: "LOAD_DASHBOARD_MECAPA" })
      const resp = await ApiService.GetDashboardMecapa({show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_DASHBOARD_MECAPA_SUCCESS", payload: resp.data.dashboard })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_DASHBOARD_MECAPA_ERROR", payload: error })
    }
  }
}


export const fetchMarche = (start_time='2023-01-01',end_time ='2023-01-02') => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    // const { marche } = getState()
    // if (marche.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_MARCHE" })
      const resp = await ApiService.GetMarche({start_time, end_time})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_MARCHE_SUCCESS", payload: resp.data })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_MARCHE_ERROR", payload: error })
    }
  }
}

export const fetchAnalysis = (start_time='2023-01-01',end_time ='2023-01-01', selectedSites=undefined, show_by_site=false) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { analyse } = getState()
    // if (analyse.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    // const start = moment(start_time,"DD-MM-YYYY").format("YYYY-MM-DD").toString()
    // const end = moment(end_time,'DD-MM-YYYY').format('YYYY-MM-DD').toString()

    try {
      dispatch({ type: "LOAD_ANALYSE" })
      const resp = await ApiService.GetAnalysis({start_time, end_time , sites_ids:selectedSites, show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_ANALYSE_SUCCESS", payload: resp.data.analysis })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_ANALYSE_ERROR", payload: error })
    }
  }
}

export const fetchAnalysisUptime = (start_time='2023-01-01',end_time ='2023-01-01', selectedSites=undefined, show_by_site=false) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { analyse } = getState()
    // if (analyse.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    // const start = moment(start_time,"DD-MM-YYYY").format("YYYY-MM-DD").toString()
    // const end = moment(end_time,'DD-MM-YYYY').format('YYYY-MM-DD').toString()

    try {
      dispatch({ type: "LOAD_ANALYSE_UPTIME" })
      const resp = await ApiService.GetAnalysisUptime({start_time, end_time , sites_ids:selectedSites, show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_ANALYSE_UPTIME_SUCCESS", payload: resp.data.analysis })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_ANALYSE_UPTIME_ERROR", payload: error })
    }
  }
}


export const fetchAnalysisVolSpot = (start_time='2023-01-01',end_time ='2023-01-01', selectedSites=undefined, show_by_site=false) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { analyse } = getState()
    // if (analyse.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    // const start = moment(start_time,"DD-MM-YYYY").format("YYYY-MM-DD").toString()
    // const end = moment(end_time,'DD-MM-YYYY').format('YYYY-MM-DD').toString()

    try {
      dispatch({ type: "LOAD_ANALYSE_VOLSPOT" })
      const resp = await ApiService.GetAnalysisVolSpot({start_time, end_time , sites_ids:selectedSites, show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_ANALYSE_VOLSPOT_SUCCESS", payload: resp.data.analysis })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_ANALYSE_VOLSPOT_ERROR", payload: error })
    }
  }
}

export const fetchAnalysisEarning = (start_time='2023-01-01',end_time ='2023-01-01', selectedSites=undefined, show_by_site=false) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { analyse } = getState()
    // if (analyse.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    // const start = moment(start_time,"DD-MM-YYYY").format("YYYY-MM-DD").toString()
    // const end = moment(end_time,'DD-MM-YYYY').format('YYYY-MM-DD').toString()

    try {
      dispatch({ type: "LOAD_ANALYSE_EARNING" })
      const resp = await ApiService.GetAnalysisEarning({start_time, end_time , sites_ids:selectedSites, show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_ANALYSE_EARNING_SUCCESS", payload: resp.data.analysis })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_ANALYSE_EARNING_ERROR", payload: error })
    }
  }
}

export const fetchAnalysisEarningY_1 = (start_time='2023-01-01',end_time ='2023-01-01', selectedSites=undefined, show_by_site=false) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { analyse } = getState()
    // if (analyse.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    // const start = moment(start_time,"DD-MM-YYYY").format("YYYY-MM-DD").toString()
    // const end = moment(end_time,'DD-MM-YYYY').format('YYYY-MM-DD').toString()

    try {
      dispatch({ type: "LOAD_ANALYSE_EARNINGY_1" })
      const resp = await ApiService.GetAnalysisEarningY_1({start_time, end_time , sites_ids:selectedSites, show_by_site})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_ANALYSE_EARNINGY_1_SUCCESS", payload: resp.data.analysis })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_ANALYSE_EARNINGY_1_ERROR", payload: error })
    }
  }
}

export const fetchBenchmark = (start_time='2023-01-01',end_time ='2023-01-01', site_id='fa8bfccf-2097-4b3a-a21b-1fbc4db88f20') => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { analyse } = getState()
    // if (analyse.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    // const start = moment(start_time,"DD-MM-YYYY").format("YYYY-MM-DD").toString()
    // const end = moment(end_time,'DD-MM-YYYY').format('YYYY-MM-DD').toString()

    try {
      dispatch({ type: "LOAD_BENCHMARK" })
      const resp = await ApiService.GetBenchmark({start_time, end_time , site_id})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_BENCHMARK_SUCCESS", payload: resp.data.benchmark })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_BENCHMARK_ERROR", payload: error })
    }
  }
}

export const fetchLive = (start_time='2023-01-01',end_time ='2023-01-01', site_id='fa8bfccf-2097-4b3a-a21b-1fbc4db88f20') => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { analyse } = getState()
    // if (analyse.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    // const start = moment(start_time,"DD-MM-YYYY").format("YYYY-MM-DD").toString()
    // const end = moment(end_time,'DD-MM-YYYY').format('YYYY-MM-DD').toString()

    try {
      dispatch({ type: "LOAD_LIVE" })
      const resp = await ApiService.GetLive({start_time, end_time , site_id})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_LIVE_SUCCESS", payload: resp.data.live })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_LIVE_ERROR", payload: error })
    }
  }
}

export const fetchPortfolio = (start_time='2023-01-01',end_time ='2023-01-02') => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { portfolio } = getState()
    // if (portfolio.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_PORTFOLIO" })
      const resp = await ApiService.GetPortfolio({start_time, end_time})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_PORTFOLIO_SUCCESS", payload: resp.data.portfolio })
      }
    } catch (error) {
      dispatch({ type: "LOAD_PORTFOLIO_ERROR", payload: error })
    }
  }
}

export const fetchOutages = (start_time='2023-01-01',end_time ='2023-01-02') => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { outage } = getState()
    // if (outage.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_OUTAGE" })
      const resp = await ApiService.GetOutages({start_time, end_time})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_OUTAGE_SUCCESS", payload: resp.data })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_OUTAGE_ERROR", payload: error })
    }
  }
}


export const fetchPeakPeriods = (start_time='2023-01-01',end_time ='2023-01-02') => {
  return async (dispatch, getState) => {
    // check if data is already present in the store
    try {
      dispatch({ type: "LOAD_PP1PP2" })
      const resp = await ApiService.GetPeakPeriods({start_time, end_time})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_PP1PP2_SUCCESS", payload: resp.data })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_PP1PP2_ERROR", payload: error })
    }
  }
}

export const fetchNotificationsMarket = (start_time='2023-01-01',end_time ='2023-01-02') => {
  return async (dispatch, getState) => {
    // check if data is already present in the store
    try {
      // dispatch({ type: "LOAD_PP1PP2" })
      const resp = await ApiService.GetNotificationsMarket({start_time, end_time})
      if (resp.status == 200) {
        // dispatch({ type: "LOAD_PP1PP2_SUCCESS", payload: resp.data })
      }
    } catch (error) {
      handle401(error)
      // dispatch({ type: "LOAD_PP1PP2_ERROR", payload: error })
    }
  }
}

export const fetchInvoices = () => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    // const { invoices } = getState()
    // if (invoices.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_INVOICES" })
      const resp = await ApiService.GetInvoices()
      if (resp.status == 200) {
        dispatch({ type: "LOAD_INVOICES_SUCCESS", payload: resp.data })
        return resp.data
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_INVOICES_ERROR", payload: error })
    }
  }
}



export const fetchSubscriptions = () => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    // const { invoices } = getState()
    // if (invoices.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_SUBSCRIPTIONS" })
      const resp = await ApiService.GetSubscriptions()
      if (resp.status == 200) {
        dispatch({ type: "LOAD_SUBSCRIPTIONS_SUCCESS", payload: resp.data })
        return resp.data
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_SUBSCRIPTIONS_ERROR", payload: error })
    }
  }
}

export const fetchContracts = () => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    // if (contracts.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_CONTRACTS" })

      const resp = await ApiService.GetContracts()
      if (resp.status == 200) {
        dispatch({ type: "LOAD_CONTRACTS_SUCCESS", payload: resp.data })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_CONTRACTS_ERROR", payload: error })
    }
  }
}

export const fetchForecast = (start_time='2023-01-01',end_time ='2023-01-02', selectedSites = undefined) => {
  return async (dispatch, getState) => {
    // check if data is already present in the store

    const { forecast } = getState()
    // if (forecast.data) {
    //   // if data is already present, don't fetch it again
    //   return
    // }

    try {
      dispatch({ type: "LOAD_FORECAST" })
      const resp = await ApiService.GetForecast({start_time, end_time, sites_ids:selectedSites})
      if (resp.status == 200) {
        dispatch({ type: "LOAD_FORECAST_SUCCESS", payload: resp.data })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_FORECAST_ERROR", payload: error })
    }
  }
}

export const fetchSitesDetail = () => {
  return async (dispatch, getState) => {

    // const {sites}  = getState();
    // if (sites.data?.length) {
    //   // if data is already present, don't fetch it again
    //   console.log('SitesDetails In Cache')
    //   return;
    // }


    try {
      dispatch({ type: "LOAD_SITES_DETAIL" })
      const resp = await ApiService.GetSitesDetails()
      if (resp.status == 200) {
        dispatch({ type: "LOAD_SITES_DETAIL_SUCCESS", payload: resp.data })
      }
    } catch (error) {
      handle401(error)
      dispatch({ type: "LOAD_SITES_DETAIL_ERROR", payload: error })
    }
  }
}



export const fetchProductionUnits = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: "LOAD_PRODUCTION_UNITS_DETAIL" })
      const resp = await ApiService.GetProductionUnitsDetails()
      if (resp.status == 200) {
        dispatch({ type: "LOAD_PRODUCTION_UNITS_DETAIL_SUCCESS", payload: resp.data})
      }
    } catch (error) {
      dispatch({ type: "LOAD_PRODUCTION_UNITS_DETAIL_ERROR", payload: error })
    }
  }
}


export const fetchBudgetVolume = (start_time='2023-01-01',end_time='2023-12-31') => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: "LOAD_BUDGET" })
      const resp = await ApiService.GetBudgetVolume({start_time,end_time})
      if (resp.status == 200) {
        // console.log('budget volume',resp.data)
        dispatch({ type: "LOAD_BUDGET_SUCCESS", payload: resp.data})
      }
    } catch (error) {
      // console.log('budget volume error',error)
      dispatch({ type: "LOAD_BUDGETL_ERROR", payload: error })
    }
  }
}

// export const fetchHydrofrance = (dateDebut='2023-01-01',dateFin='2023-01-02', listStations = 'O400101001') => {
//   console.log('calling service 1 ')
//   return async (dispatch, getState) => {
//     try {
//       console.log('calling service')
//       const resp = await ApiService.GetHydrofrance({dateDebut, dateFin, listStations})
//       if (resp.status == 200) {
//         console.log('GetHydroFrance',resp.data)
//        return resp.data
//       }
//     } catch (error) {
//     }
//   }
// }



export const fetchHydrofrance = (dateDebut='2023-01-01',dateFin='2023-01-02', listStation = 'O400101001') => {
  // return async (dispatch, getState) => {
  //   const { analyse } = getState()
  //   try {

  //     const resp = await ApiService.GetHydrofrance({dateDebut, dateFin, listStation})
  //     if (resp.status == 200) {
  //       toast.success('hydrofrance Success')
  //     } 
  //   } catch (error) {
  //     toast.error(error.response.data.message)

  //   }
  // }

  toast.promise(ApiService.GetHydrofrance({dateDebut, dateFin, listStation}), {
    loading: `Loading - Hydrofrance - ${listStation}`,
    success: (data) => `${data.data.message}`,
    error: (err) => `${err.response.data.message.toString()}`
  });


}



export const fetchNCC = () => {

  return async (dispatch, getState) => {
    try {
      dispatch({ type: "LOAD_NCC" })
      const resp = await ApiService.GetNCC()
      if (resp.status == 200) {
        // console.log('fetch NCC',resp.data.data)
        dispatch({ type: "LOAD_NCC_SUCCESS", payload: resp.data.data})
        return resp.data
      }
    } catch (error) {
      // console.log('budget volume error',error)
      dispatch({ type: "LOAD_NCC_ERROR", payload: error })
    }
  }

}


export const fetchMeteoCiel = (dateDebut='2023-01-01',dateFin='2023-01-02', listStation = 'O400101001') => {
  
  // return async (dispatch, getState) => {
  //   const { analyse } = getState()
  //   try {
  //     const resp = await 
  //     if (resp.status == 200) {
  //     toast.success('Meteociel Success')
  //     } 
  //   } catch (error) {
  //     toast.error(error.response.data.message)
  //   }
  // }

    toast.promise(ApiService.GetMeteoCiel({dateDebut, dateFin, listStation}), {
      loading: `Loading - MeteoCiel - ${listStation}`,
      success: (data) => `${data.data.message}`,
      error: (err) => `${err.response.data.message.toString()}`
    });



}


export const fetchEnedisR4Q = (dateDebut='2023-01-01',dateFin='2023-01-02', prm = 'O400101001',insert=true,restore=false,mesuresCorrigees=true,sens='INJECTION') => {
  return async (dispatch, getState) => {
    const { analyse } = getState()
    // console.log('yo')

    toast.promise(ApiService.GetEnedisR4Q({dateDebut, dateFin, prm,insert,restore,mesuresCorrigees,sens}), {
      loading: `Loading - EnedisR4Q - ${prm}`,
      success: (data) => `${data.data.message}`,
      error: (err) => `${err.response.data.message.toString()}`
    });
  
  }



}






export const findPRM = (zipcode = '31240') => {
  return async (dispatch, getState) => {
    toast.promise(ApiService.GetFindPRM({zipcode}), {
      loading: `Loading - PRMS - ${zipcode}`,
      success: (data) => `${data.data.message}`,
      error: (err) => `${err.response.data.message.toString()}`
    });
  }
}