import { ResponsivePie } from '@nivo/pie'
import moment from 'moment';


const customTheme = {
  axis: {
    fontSize: '14px', // Adjust the font size here
    tickColor: '#eee',
    ticks: {
      line: {
        stroke: '#555'
      },
      text: {
        fill: '#555'
      }
    },
    legend: {
      text: {
        fontSize: '14px' // Adjust the font size for the legend
      }
    },
  },
  fontSize: 12,
};

const NIVO_PIE_OA = ({ data,type,total }:{data:any,type:any,total:any}) => {




  if (data === null || data === undefined) {
    return null; // or return an empty string, or any other appropriate value
  }

  
  const data1 = [
    {
      "id": "Energie",
      "label": "Energie",
      "value": data['oa_power_gain'],
      "color": "hsl(254, 70%, 50%)"
    },


  ]

  function humanFormat(number) {
    if (number === 0) return '0';

    const sign = number < 0 ? '-' : '';
    number = Math.abs(number);

    const sizes = ["", "K", "M", "B", "T"];
    const i = number === 0 ? 0 : Math.floor(Math.log(number) / Math.log(1000));
    if (i === 0) return sign + number;

    const roundedNumber = (number / Math.pow(1000, i)).toFixed(1);
    const removeInsignificantZero = roundedNumber.endsWith('.0') ? roundedNumber.slice(0, -2) : roundedNumber;

    return sign + removeInsignificantZero + sizes[i];
}


const CenteredText = ({ centerX, centerY }) => {
    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '16px',
                fill: '#6F729C',
            }}
        >
            <tspan x={centerX} dy="-0.6em">{type}</tspan>
            <tspan x={centerX} dy="1.2em">{total}€</tspan>
            
            
        </text>
    );
};


  return (
    <ResponsivePie
        data={data1}
        margin={{ top: 10, right: 70, bottom: 80, left: 70 }}
        innerRadius={0.6}
        padAngle={0.2}
        cornerRadius={2}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={3}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    3
                ]
            ]
        }}
        layers={[
            'arcs', 
            // 'arcLinkLabels', 
            'arcLabels',
            "legends",
            CenteredText
        ]}
        arcLabel={({ value }) =>
        value !== null ? `${humanFormat(value)}` : ''
        }
        tooltip={point => {
          return         <div
          className="text-white rounded-lg p-2"
          style={{ background: "#20263D" }}
        >
                  {point.datum.label} : {humanFormat(point.datum.value)}€
                </div>
        }}
        legends={[
          {
              anchor: 'bottom',
              direction: 'row',
              justify: true,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 10,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemTextColor: '#000'
                      }
                  }
              ]
          }
      ]}

    />
  )
}


export default NIVO_PIE_OA 