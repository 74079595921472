import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import { DateSelector } from "../components/DateSelector"
import Card from "../components/Card"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchBenchmark, fetchForecast, fetchSitesDetail } from "../redux/actions"
import { Avatar, Col, Collapse, Modal, Progress, Row, Spin, Tabs, TabsProps } from "antd"
import SyncChart from "../components/Charts/SyncChart"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import L from "leaflet"
import { Icon } from "../components/Icon"
import Text from "../components/Text"
import { stringToHexColor } from "../utils"
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
import ScatterComponent from "../components/Charts/Scatter"
import PrevisionChart from "../components/Charts/PrevisionChart"
import moment from "moment"
import { useQuery } from "react-query"
import { load_sites_details } from "../actions/load_sites_details"
import { load_benchmark } from "../actions/load_benchmark"
import { tickFormatter } from "../components/Charts/Util"
import { FullscreenOutlined } from "@material-ui/icons"
import { Key } from "@mui/icons-material"

const { Panel } = Collapse

export default function VueEnsemble() {
  var start_date = new Date()
  var today = new Date()
  start_date.setDate(today.getDate() - 7);
  var end_date = new Date()
  end_date.setDate(end_date.getDate());

  const auth = useSelector((state: RootStateOrAny) => state.auth)


  const [start_time, setstart_time] = useState(start_date.toISOString().split('T')[0])
  const [end_time, setend_time] = useState(end_date.toISOString().split('T')[0])
  const { data: SitesDetailRQ, isLoading: isLoadingSitesDetailRQ, isError: isErrorSitesDetailRQ, refetch: refetchSitesDetailRQ } = useQuery(['sites_detail', auth.currentUser?.user_id], load_sites_details, { staleTime: 30 * 60 * 1000 });
  const { data: BenchmarkRQ, isLoading: isLoadingBenchmarkRQ, isError: isErrorBenchmarkRQ, refetch: refetchBenchmarkRQ } = useQuery(['Benchmark', {
    start_time: start_time,
    end_time: end_time,
    selectedSites: [],
  }, auth.currentUser?.user_id], load_benchmark, { staleTime: 1000 * 60 * 10 });

  console.log('BenchmarkRQ', BenchmarkRQ)

  useEffect(() => {
    refetchBenchmarkRQ
  }, [start_time, end_time])

  // console.log('BenchmarkRQ', BenchmarkRQ)
  // console.log('SitesDetailRQ', SitesDetailRQ)


  const PanelHeader = ({ site }) => (
    <Row className="flex items-center" style={{ height: '100px' }} >
      <Col xs={24} sm={12} md={8} lg={5}>
        <Text type="16-600" style={{ textTransform: 'capitalize' }}>
          {site.name}</Text>
        <Text className="block" type="12-500" >
          {site.prm_number}
        </Text>

        <Text className="block" type="12-500" >
          {
            BenchmarkRQ ?
              'Mis à jour le ' + moment((BenchmarkRQ.filter((item) => item.prm === site.prm_number).slice(-1)[0]?.start_time)).format('DD MMM HH:mm')
              :
              ""
          }
        </Text>
      </Col>

      <Col xs={24} sm={12} md={8} lg={5}>
        <Text className="block" type="14-500">
          {BenchmarkRQ ? (
            (() => {
              const total = BenchmarkRQ
                .filter((item) => item.prm === site.prm_number)
                .slice(-1)[0]?.volume;
              return total > 15 ? (
                <div style={{ display: 'flex', flexDirection: 'row', color: 'green' }}>
                  En production ( {parseFloat(total).toFixed(0)} kwh )
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>
                  A l'arrêt
                </div>
              );
            })()
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>
              A l'arrêt
            </div>
          )}
        </Text>
      </Col>

      <Col xs={24} sm={12} md={8} lg={14}>
        <Text className="text-gray-6f" type="12-500">
          Ma production
        </Text>
        <ResponsiveContainer width="100%" height={80}>
          <BarChart width={650} height={80} data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)}>
            <YAxis type="number" domain={[0, 'dataMax']} axisLine={false} orientation={"right"} tickFormatter={value => Math.round(value)} />
            <Bar dataKey="volume" fill={stringToHexColor(site.name)} />
          </BarChart>
        </ResponsiveContainer>

      </Col>
    </Row>
  )


  const PanelBody = ({ site }) => {
    // Define the items array here and include the site variable as needed


    if (site) {

      if (site.site_type == 'hydro') {
        const items = [
          {
            key: '1',
            label: `Production`,
            children: <PrevisionChart data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)} site={site} type={"hydro"} keyArray={"flow_rate"} />,
          },
          {
            key: '2',
            label: `Profil`,
            children: <ScatterComponent data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)} site={site} type={"hydro"} keyArray={"flow_rate"} />,
          },
        ];
        return <Tabs centered defaultActiveKey="1" items={items} />;
      } else if (site.site_type == 'eolien') {
        const items = [
          {
            key: '1',
            label: `Production`,
            children: <PrevisionChart data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)} site={site} type={"eolien"} keyArray={"metric"} />,
          },
          {
            key: '2',
            label: `Profil`,
            children: <ScatterComponent data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)} site={site} type={"eolien"} keyArray={"metric"} />,
          },
        ];
        return <Tabs centered defaultActiveKey="1" items={items} />;
      } else if (site.site_type == 'solar') {
        const items = [
          {
            key: '1',
            label: `Production`,
            children: <PrevisionChart data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)} site={site} type={"solar"} keyArray={"metric"} />,
          },
          {
            key: '2',
            label: `Profil`,
            children: <ScatterComponent data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)} site={site} type={"solar"} keyArray={"metric"} />,
          },
        ];
        return <Tabs centered defaultActiveKey="1" items={items} />;
      } else {
        return null
      }
    }



  };

  console.log('SitesDetailRQ', SitesDetailRQ)


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectSite, setSelectSite] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const openmodal = (site) => {
    setIsModalOpen(true);
    setSelectSite(site)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const MyStyledCard = ({ key, site }) => {

    const total = BenchmarkRQ
      .filter((item) => item.prm === site.prm_number)
      .slice(-1)[0]?.volume

    const progress = (total / parseFloat(site.installed_capacity)) * 100

    return (
      <Card
        size={"small"}
        title={
          <div style={{ width: '900px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid lightgrey' }}>
            <div style={{ textAlign: 'left' }}>
              <div style={{ fontSize: '18px', fontWeight: '700' }}>{site.name}</div>
              <div style={{ fontSize: '14px', fontWeight: '500' }}>{site.prm_number}</div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <div style={{ fontSize: '18px', fontWeight: '700' }}>
                {BenchmarkRQ ? (
                  (() => {
                    const total = BenchmarkRQ
                      .filter((item) => item.prm === site.prm_number)
                      .slice(-1)[0]?.volume;
                    return total > 15 ? (
                      <div style={{ display: 'flex', flexDirection: 'row', color: 'green' }}>
                        En Production
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>
                        A l'arrêt
                      </div>
                    );
                  })()
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>
                    A l'arrêt
                  </div>
                )}
              </div>
              <div style={{ fontSize: '12px', fontWeight: '500' }}>
                {
                  BenchmarkRQ ?
                    'Mis à jour le ' + moment((BenchmarkRQ.filter((item) => item.prm === site.prm_number).slice(-1)[0]?.start_time)).format('DD MMM HH:mm')
                    :
                    ""
                }</div>
            </div>
          </div>
        }

      >
        <div style={{ marginTop: '20px', width: '900px', minHeight: '200px', overflow: 'hidden' }}>
          <Row gutter={24}>


            <Col span={8} style={{ display: 'flex' }}> {/* This column will approximately take up 30% of the width */}
              <img
                src={`/images/${site.site_type}.png`}
                alt="bohr logo"
                style={{ width: '100%', height: 'auto' }}  // This ensures the image scales correctly within the column
              />
            </Col>

            <Col span={16} style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
              <Progress
                percent={progress}
                status="active"
                showInfo={true}
                format={() => `${parseFloat(total).toFixed(0)} KWh`}
                strokeColor="#73d13d"
                size={[500, 12]}
                style={{ width: '90%', fontSize: '18px', fontWeight: '500' }} // This ensures the Progress bar uses the full width of the column
              />


              <div onClick={() => openmodal(site)} style={{ fontSize: '14px', fontWeight: '500', marginTop: '10px', fontSize: '14px', marginBottom: '10px' }}>
                <FullscreenOutlined /> Ma Production
              </div>

              <BarChart width={600} height={150} data={BenchmarkRQ?.filter((item) => item.prm === site.prm_number)}>
                <YAxis type="number" domain={[0, 'dataMax']} axisLine={false} orientation={"right"} tickFormatter={value => Math.round(value)} />
                <Bar dataKey="volume" fill={stringToHexColor(site.name)} />
              </BarChart>


            </Col>
          </Row>
        </div>



      </Card>
    );
  };

  const MyStyledModal = ({ key, site }) => {

    const total = BenchmarkRQ
      .filter((item) => item.prm === site.prm_number)
      .slice(-1)[0]?.volume

    const progress = (total / parseFloat(site.installed_capacity)) * 100

    return (
      <Card
        size={"small"}
        title={
          <div style={{ width: '1200px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid lightgrey' }}>
            <div style={{ textAlign: 'left' }}>
              <div style={{ fontSize: '18px', fontWeight: '700' }}>{site.name}</div>
              <div style={{ fontSize: '14px', fontWeight: '500' }}>{site.prm_number}</div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <div style={{ fontSize: '18px', fontWeight: '700' }}>
                {BenchmarkRQ ? (
                  (() => {
                    const total = BenchmarkRQ
                      .filter((item) => item.prm === site.prm_number)
                      .slice(-1)[0]?.volume;
                    return total > 15 ? (
                      <div style={{ display: 'flex', flexDirection: 'row', color: 'green' }}>
                        En Production
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>
                        A l'arrêt
                      </div>
                    );
                  })()
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row', color: 'red' }}>
                    A l'arrêt
                  </div>
                )}
              </div>
              <div style={{ fontSize: '12px', fontWeight: '500' }}>
                {
                  BenchmarkRQ ?
                    'Mis à jour le ' + moment((BenchmarkRQ.filter((item) => item.prm === site.prm_number).slice(-1)[0]?.start_time)).format('DD MMM HH:mm')
                    :
                    ""
                }</div>
            </div>
          </div>
        }

      >
        <div style={{ marginTop: '20px', width: '1200px', height: '600px', overflow: 'hidden' }}>

          <PanelBody site={site} />

        </div>



      </Card>
    );
  };


  return (
    <Layout isDashboard={true} showSignlerArret showContactUs showNotifications>
      <Seo title="Bohr Energie | Vue d'ensemble" />

      <Layout.Header>
        <h1 style={{ fontSize: 40 }} className="font-semibold text-3xl mb-0">
          Vue d'ensemble <Spin spinning={isLoadingBenchmarkRQ} />
        </h1>
        <div className="flex items-center gap-x-5 gap-y-3 flex-wrap">

          <div className="flex items-center gap-2">
            <DateSelector
              defaultValue={start_time}
              onChange={(date: string) => setstart_time(date)}
              format="YYYY-MM-DD"
              showHours={false}
            />
            au
            <DateSelector
              defaultValue={end_time}
              onChange={(date: string) => setend_time(date)}
              format="YYYY-MM-DD"
              showHours={false}
            />
          </div>

        </div>
      </Layout.Header>

      <Spin spinning={isLoadingBenchmarkRQ}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>






          {BenchmarkRQ && SitesDetailRQ?.map((site: any, index: number) => (

            <MyStyledCard key={index} site={site} />



          ))}





        </div>
      </Spin>


      <Modal open={isModalOpen} mask={true} onCancel={handleCancel} width={1300} footer={null}
        bodyStyle={{
          padding: '0px', // Remove padding
          background: 'white',
          backgroundColor: 'white' // Remove background color
        }}
        style={{
          padding: '0px',
          background: 'white', // Remove background color from the modal overlay area if needed
          borderRadius: '10px',
          backgroundColor: 'white'
        }}>
        <MyStyledModal key={"9999"} site={selectSite} />
      </Modal>

    </Layout>
  )
}
