import {
    PieChart,
    Pie,
    Tooltip,
    Legend,
    Cell,
    ResponsiveContainer,
  } from "recharts"
  import Text from "../Text"
  
  const CustomTooltip = ({ active, payload, label, ...props }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="text-white rounded-lg p-2"
          style={{ background: "#20263D" }}
        >
          {payload.map(p => (
            <div>
              <Text type="12-500">{p.name}</Text> <br />
              <Text type="14-600">{p.value.toLocaleString("fr")} €</Text>
            </div>
          ))}
        </div>
      )
    }
  
    return null
  }
  
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]
  
  
  function ExamplePieChart({data}) {
  
  
    if (!data) return (null)
  
  
    const data0 = [
      { name: "Production électrique", value: data.spot },
      { name: "Garanties d’origines", value: data.total_goo },
      { name: "Mécanisme de capacité", value:  data.total_mc/2 },
    ]
    return (

      <PieChart width={450} height={250}>
        <Tooltip
          content={p => <CustomTooltip {...p} />}
          cursor={{ fill: "transparent" }}
        />
        <Legend
          iconType="circle"
          layout="vertical"
          align='right'
          verticalAlign = 'middle'

        />
        <Pie
          data={data0}
          cx={120}
          cy={150}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}

          dataKey="value"
        >
          {data0.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>

    )
  }
  
  export default ExamplePieChart
  