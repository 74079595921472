import React, { useState } from 'react';

import { Card, DatePicker, Input, Select, Spin, Tag } from 'antd';
import styled, { keyframes,css } from 'styled-components';


const shiftColor = keyframes`
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
`;

const LoadingTag = styled(Tag)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.loading && css`
    background: linear-gradient(to left, #ffffff, #DDD1FC);
    background-size: 200% 100%;
    background-position: left;
    animation: ${shiftColor} 1s infinite alternate ease-in-out;

  `}

  .ant-tag-close-icon {
    margin-left: auto; // Ensures the close icon stays on the right
  }
`;

const TagInput = ({ title, tags, loadingTags, onTagsChange,onTagsClose }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputConfirm = () => {
    const newTag = inputValue.trim();
    if (newTag && !tags.includes(newTag)) {
      const newTags = [...tags, newTag];
      onTagsChange(newTags); // Update tags in the main component
    }
    setInputValue('');
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter(tag => tag !== removedTag);
    onTagsChange(newTags)
    onTagsClose(removedTag); // Update tags in the main component
  };

  return (
    <div>
      <div style={{marginBottom:'10px'}}>{title}</div>

      <Input
        placeholder="Type and press enter"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onPressEnter={handleInputConfirm}
      />
      <div style={{ marginTop: '10px' }}>
        {tags.map((tag, index) => (
          <LoadingTag
            key={index}
            closable
            onClose={() => handleClose(tag)}
            loading={loadingTags[tag]}
          >
            {tag}
          </LoadingTag>
        ))}
      </div>
    </div>
  );
};

export default TagInput;