import { Button, Checkbox, Form, Input } from "antd"
import TextArea from "antd/es/input/TextArea"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import ApiService from "../../services/ApiService"
import { fetchInvoices } from "../../redux/actions"

interface P {
  data: any
}

const FormPaidComponent: React.FC<P> = ({ data }) => {

  const dispatch = useDispatch()


  const validateInvoicePaymentAdmin = async (invoice_id :string) => {
    console.log('validateInvoicePaymentAdmin',invoice_id)
    const resp = await ApiService.ValidateInvoicePaymentAdmin({invoice_id})
    if (resp.status == 200) {
      dispatch(fetchInvoices());
    }
  }



  return (
    <>

      <Button style={{marginBottom:'30px'}} onClick={ () => validateInvoicePaymentAdmin(data.invoice_id) }>C'est Payé !</Button>



    </>
  )
}
export default FormPaidComponent
