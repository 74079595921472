import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import Card from "../components/Card"
import Text from "../components/Text"
import { Spin, Table } from "antd"
import AreaChartComponent from "../components/Charts/LineChart"
import { DateSelector } from "../components/DateSelector"
import SelectDropdown from "../components/SelectDropdown"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchMarche } from "../redux/actions"
// import moment from 'moment'
import moment from 'moment-timezone';
import { load_market } from "../actions/load_market"
import { useQuery } from "react-query"

const columns = [
  {
    title: "Année",
    dataIndex: "Année",
    key: "Année",
    render: (_, record) => <b>{record["Année"]}</b>,
  },
  {
    title: "Cal Base",
    dataIndex: "Cal Base",
    key: "Cal Base",
    responsive: ["lg"],
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["Cal Base"]}</Text>,
  },
  {
    title: "Cal Pointe",
    dataIndex: "Cal Pointe",
    key: "Cal Pointe",
    responsive: ["lg"],
    render: (_, rec) => <Text type="16-600" className="text-gray-6f">{rec["Cal Pointe"]}</Text>,
  },
]
const columnsmecapa = [
  {
    title: "Date de l'enchère",
    dataIndex: "trading_date",
    key: "trading_date",
    render: (_, record) => <b>{record["trading_date"]}</b>,

  },
  {
    title: "Année",
    dataIndex: "start_time",
    key: "start_time",
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["start_time"]}</Text>,

  },
  {
    title: "Prix",
    dataIndex: "price",
    key: "price",
    responsive: ["lg"],
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["price"]}</Text>,
  },
]
const columnsgos = [
  {
    title: "Date de l'enchère",
    dataIndex: "publication_time",
    key: "publication_time",
    render: (_, record) => <b>{record["publication_time"].toLocaleString("fr")}</b>,

  },
  {
    title: "Date",
    dataIndex: "start_time",
    key: "start_time",
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["start_time"].toLocaleString("fr")}</Text>,

  },
  {
    title: "Technolgy",
    dataIndex: "technology",
    key: "technology",
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["technology"]}</Text>,

  },
  {
    title: "Prix",
    dataIndex: "price",
    key: "price",
    responsive: ["lg"],
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["price"].toLocaleString("fr")}</Text>,
  },
]
const columnsoa = [
  {
    title: "Date",
    dataIndex: "start_time",
    key: "start_time",
    render: (_, record) => <b>{record["start_time"].toLocaleString("fr")}</b>,

  },
  {
    title: "Contract Type",
    dataIndex: "contract_type",
    key: "contract_type",
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["contract_type"].toLocaleString("fr")}</Text>,

  },
  {
    title: "Prix",
    dataIndex: "contract_value",
    key: "contract_value",
    responsive: ["lg"],
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["contract_value"].toLocaleString("fr")}</Text>,
  },
]

const columnssp = [
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
    render: (_, record) => <b>{moment(record["Date"]).format("DD/MM/YYYY")}</b>,

  },
  {
    title: "Spot Base",
    dataIndex: "Spot Base",
    key: "Spot Base",
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["Spot Base"]?.toLocaleString("fr")}</Text>,

  },
  {
    title: "Spot Pointe",
    dataIndex: "Spot Pointe",
    key: "Spot Pointe",
    // responsive: ["lg"],
    render: (_, record) => <Text type="16-600" className="text-gray-6f">{record["Spot Pointe"]?.toLocaleString("fr")}</Text>,
  },
]






export default function Marche() {

  const auth = useSelector((state: RootStateOrAny) => state.auth)
  var start_date = new Date() 
  var today = new Date() 
  var tomorrow = new Date() 
  start_date.setDate(today.getDate() - 7); 
  tomorrow.setDate(today.getDate() + 1); 
  
  const [xScale, setxScale] = useState({ key: "hour", value: "Heure" })
  const [start_time, setstart_time] = useState(start_date.toISOString().split('T')[0])
  const [end_time, setend_time] = useState(tomorrow.toISOString().split('T')[0])

  const { data: MarketRQ, isLoading: isLoadingMarketRQ, isError: isErrorMarketRQ, refetch: refetchMarketRQ } = useQuery(['Market', {
    start_time: start_time,
    end_time: end_time,
  },auth.currentUser?.user_id], load_market, { staleTime: 1000 * 60 * 60 });


  useEffect(() => {
    // dispatch(fetchMarche(start_time, end_time))
    refetchMarketRQ
  }, [start_time, end_time])


  function transform(oldData, spotMarketPrice) {

    if (!oldData) return []

    let newData1 = oldData?.reduce((acc, item) => {

      var date = moment.utc(item.start_time).tz('Europe/Paris').format()

      if (!acc[date]) {
        acc[date] = {}
      }

      acc[date][item.contract_type] = item.contract_value


      return acc
    }, {})

    let newData2 = spotMarketPrice?.reduce((acc, item) => {



      var date = moment.utc(item.start_time).tz('Europe/Paris').format()

      if (!acc[date]) {
        acc[date] = {}
      }

      // console.log('item["Prix marché Spot"]',item.start_time,item["Prix marché Spot"])
      acc[date]["Prix marché Spot"] = item["Prix marché Spot"]
      return acc
    }, newData1)

    if (!newData2) return []

    var arr: any[] = []
    Object.entries(newData2).forEach(([key, value]) =>
      arr.push({ date: key, ...value })
    )

    return arr
  }

  const handleSlectHour = val => {
    setxScale(val)
  }

  
  const [datachart, setDataChart] = useState(transform(MarketRQ?.OAcontract, MarketRQ?.spotMarketPrice))

  useEffect(() => {
    setDataChart(transform(MarketRQ?.OAcontract, MarketRQ?.spotMarketPrice))
  }, [MarketRQ])



  return (
    <Layout isDashboard={true} showSignlerArret showContactUs showNotifications>
      <Seo title="Bohr Energie | Marché" />
      <Layout.Header>
        <h1 style={{ fontSize: 40 }} className="font-semibold text-3xl mb-0">
          Marché
        </h1>
        <div className="flex items-center gap-2">
            <DateSelector
              defaultValue={start_time}
              onChange={(date: string) => setstart_time(date)}
              format="YYYY-MM-DD"
              showHours={false}
            />
            au
            <DateSelector
              defaultValue={end_time}
              onChange={(date: string) => setend_time(date)}
              format="YYYY-MM-DD"
              showHours={false}
            />
        </div>
      </Layout.Header>
      <Spin spinning={isLoadingMarketRQ}>
        <div className="flex-wrap" style={{ display: "flex" }}>
          <Card
            className="w-full"
            title="Prix marché"
            help="Prix marché help"
            headerRight={
              <SelectDropdown
                items={[
                  { key: "hour", value: "Heure" },
                  { key: "day", value: "Jour" },
                  { key: "week", value: "Semaine" },
                  { key: "month", value: "Mois" },
                ]}
                type="none"
                onSelect={handleSlectHour}
                placeholder="Select"
                defaultValue={xScale}
                width={112}
              />
            }
          >
            <AreaChartComponent
              xScale={xScale.key}
              data={datachart}
            />
          </Card>
          <Card className="w-full md:w-1/3" title="Prix futur"  help="futur help">
            <Table
              pagination={false}
              dataSource={MarketRQ?.futuresMarketPrice}
              columns={columns}
            />
          </Card>
          <div className="w-full md:w-1/3">
            <Card title="Prix marché Spot (€/MWh)" help="Prix Spot help">
              <Table
                pagination={false}
                dataSource={MarketRQ?.spotPrices}
                columns={columnssp}
              />

            </Card>
            <Card title="Prix Garanties d’origine" help="Gos help">
              <Table
                pagination={false}
                dataSource={MarketRQ?.GOs}
                columns={columnsgos}
              />
            </Card>
          </div>
          <div className="w-full md:w-1/3">
            <Card title="Prix Mécanisme de capacité"  help="Mécanisme help">
              <Table
                pagination={false}
                dataSource={MarketRQ?.MecaCapa}
                columns={columnsmecapa}
              />
            </Card>
            <Card title="Prix Contrats OA"  help="contrat help">
            
              <Table
                pagination={false}
                dataSource={MarketRQ?.OAContractBase}
                columns={columnsoa}
              />
            </Card>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}
