// const initState = { loading: false, data:null, volume: null ,error: null }

import moment from 'moment';

const initState = { 
  earning : {
    loading: false, 
    data:null,
    error: null,
    expire: null
  }
}

const earningReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "LOAD_DASHBOARD_EARNING":
      return {
        ...state,
        earning: {
          ...state.earning,
          loading: true,
          data: null,
          error: null
        }
      }
    case "LOAD_DASHBOARD_EARNING_SUCCESS":
      return {
        ...state,
        earning: {
          ...state.earning,
          loading: false,
          data: action.payload,
          error: null,
          expire : moment().date()
        }
      }
    case "LOAD_DASHBOARD_EARNING_ERROR":
      return {
        ...state,
        earning: {
          ...state.earning,
          loading: false,
          data: null,
          error: action.payload
        }
      }
      case "RESET_EARNING":
        return initState
    default:
      return state

  }
}

export default earningReducer
