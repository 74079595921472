import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { setCurrentUser } from "../redux/actions/auth"
import Card from "../components/Card"
import Text from "../components/Text"
import { Icon } from "../components/Icon"
import PieChart from "../components/Charts/PieChart"
import DeltaBadge from "../components/DeltaBadge"
import { Spin, Table } from "antd"
import { fetchDashboard, fetchDashboardEarning, fetchDashboardGos, fetchDashboardMecapa, fetchDashboardVolume, fetchSitesDetail } from "../redux/actions"
import BarChartComponent from "../components/Charts/BarChart"
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Space } from 'antd';
import moment from 'moment'
import { batch } from 'react-redux'
import EnergieComponent from "../components/Dashboard/Energie"
import { load_data_analysis_background } from "../actions/load_data_analysis_background"
import { useQuery, useQueryClient } from "react-query"
import { load_market } from "../actions/load_market"
import { load_sites_details } from "../actions/load_sites_details"
import { load_dashboard_earning } from "../actions/load_dashboard_earning"
import { load_dashboard_volume } from "../actions/load_dashboard_volume"
import { load_dashboard_mecapa } from "../actions/load_dashboard_mecapa"
import { load_dashboard_gos } from "../actions/load_dashboard_gos"
import { load_analysis_uptime } from "../actions/load_analysis_uptime"
import { load_analysis_volspot } from "../actions/load_analysis_volspot"
import { load_analysis_earning_y_1 } from "../actions/load_analysis_earning_y_1"
import { load_analysis_earning } from "../actions/load_analysis_earning"
import NIVO_PIE from "../components/Charts/NivoPie"
import NIVO_PIE_MARKET from "../components/Charts/NivoPie_Market"
import NIVO_PIE_OA from "../components/Charts/NivoPie_OA"
import { load_benchmark } from "../actions/load_benchmark"

const columns = [
  {
    title: "Année",
    dataIndex: "year",
    key: "year",
  },
  // {
  //   title: "Certifiées",
  //   dataIndex: "certified",
  //   key: "certified",
  //   responsive: ["lg"],
  //   render: (_, { certified }) => certified?.toLocaleString("fr"),
  // },
  {
    title: "Vendus",
    dataIndex: "sold",
    key: "sold",
    responsive: ["lg"],
    render: (_, { sold }) => sold?.toLocaleString("fr"),
  },
  {
    title: "Restants",
    dataIndex: "remaining",
    key: "remaining",
    responsive: ["lg"],
    render: (_, { remaining }) => remaining.toLocaleString("fr"),
  },
  {
    title: "Gain",
    dataIndex: "earning",
    key: "earning",
    render: (_, { earning }) => parseInt(earning).toLocaleString("fr"),
  },
]

const columns_ = [
  {
    title: "Mois",
    dataIndex: "month",
    key: "month",
  },
  {
    title: "Vendues",
    dataIndex: "sold",
    key: "sold",
    responsive: ["sm"],
    render: (_, { sold }) => parseInt(sold).toLocaleString("fr"),
  },
  // {
  //   title: "Certifiées",
  //   dataIndex: "certified",
  //   key: "certified",
  //   responsive: ["sm"],
  //   render: (_, { certified }) => parseInt(certified).toLocaleString("fr"),
  // },
  {
    title: "Gain",
    dataIndex: "profit",
    key: "profit",
    responsive: ["sm"],
    render: (_, { profit }) => parseInt(profit).toLocaleString("fr") + ' €',
  },
]


function filterDataBySite(showSite, data) {
  let filteredData;

  if (showSite) {
    filteredData = data.map(item => item.site).flat();
  } else {
    filteredData = data.map(({ site, ...rest }) => rest);
  }

  filteredData.sort((a, b) => b.total_gain - a.total_gain);
  filteredData.sort((a, b) => b.volume_2024 - a.volume_2024);
  return filteredData;
}


const PieChartMemo = React.memo(PieChart)
const BarChartComponentMemo = React.memo(BarChartComponent)
const EnergieComponentMemo = React.memo(EnergieComponent)

export default function Dashboard() {



  const [showSite, setShowSite] = React.useState(true)
  const [earningChart, setEarningChart] = React.useState([])
  const [volumeChart, setVolumeChart] = React.useState([])

  const auth = useSelector((state: RootStateOrAny) => state.auth)

  // Loading Data 
  const { data: SitesDetailRQ, isLoading: isLoadingSitesDetailRQ, isError: isErrorSitesDetailRQ, refetch: refetchSitesDetailRQ } = useQuery(['sites_detail', auth.currentUser?.user_id], load_sites_details, { staleTime: 30 * 60 * 1000 });
  const { data: DashboardEarningRQ, isLoading: isLoadingDashboardEarningRQ, isError: isErrorDashboardEarningRQ, refetch: refetchDashboardEarningRQ } = useQuery(['DashboardEarning', {
    showSite: showSite
  }, auth.currentUser?.user_id], load_dashboard_earning, { staleTime: 1000 * 60 * 60 });

  const { data: DashboardVolumeRQ, isLoading: isLoadingDashboardVolumeRQ, isError: isErrorDashboardVolumeRQ, refetch: refetchDashboardVolumeRQ } = useQuery(['DashboardVolume', {
    showSite: showSite
  }, auth.currentUser?.user_id], load_dashboard_volume, { staleTime: 1000 * 60 * 60 });

  const { data: DashboardGosRQ, isLoading: isLoadingDashboardGosRQ, isError: isErrorDashboardGosRQ, refetch: refetchDashboardGosRQ } = useQuery(['DashboardGos', {
    showSite: showSite
  }, auth.currentUser?.user_id], load_dashboard_gos, { staleTime: 1000 * 60 * 60 });

  const { data: DashboardMecapaRQ, isLoading: isLoadingDashboardMecapaRQ, isError: isErrorDashboardMecapaRQ, refetch: refetchDashboardMecapaRQ } = useQuery(['DashboardMecapa', {
    showSite: showSite
  }, auth.currentUser?.user_id], load_dashboard_mecapa, { staleTime: 1000 * 60 * 60 });


  // Execute load_market & load_data_analysis_background only after initial queries are loaded
  const [initialQueriesLoaded, setInitialQueriesLoaded] = useState(false);
  const initialQueriesLoading = [
    isLoadingDashboardEarningRQ,
    isLoadingDashboardVolumeRQ,
    isLoadingDashboardGosRQ,
    isLoadingDashboardMecapaRQ
  ];

  useEffect(() => {
    // console.log('initialQueriesLoading', initialQueriesLoading)
    if (!initialQueriesLoading.some(isLoading => isLoading)) {
      setInitialQueriesLoaded(true);
    }
  }, [initialQueriesLoading]);

  // console.log('initialQueriesLoaded', initialQueriesLoaded)

  // DEFAULT IMPORT OF DATA  ------------------------------------------------------------------------------------------
  var start_date = new Date()
  var end_date = new Date()
  end_date.setDate(end_date.getDate());
  var firstDayOfYear = new Date(start_date.getFullYear(), 0, 1);
  const [start_time, setstart_time] = useState(firstDayOfYear.toLocaleString("sv", { timeZone: "Europe/Paris" }).split(' ')[0])
  const [end_time, setend_time] = useState(end_date.toISOString().split('T')[0])
  const selectedSPV = Array()


  const { data: MarketRQ, isLoading: isLoadingMarketRQ, isError: isErrorMarketRQ, refetch: refetchMarketRQ } = useQuery(['Market', {
    start_time: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end_time: moment().add(1, 'days').format('YYYY-MM-DD'),
  }, auth.currentUser?.user_id], load_market, {
    enabled: initialQueriesLoaded,
    staleTime: 1000 * 60 * 60
  });

  const { data: AnalysisUptimeRQ, isLoading: isLoadingAnalysisUptimeRQ, isError: isErrorAnalysisUptimeRQ, refetch: refetchAnalysisUptimeRQ } = useQuery(['AnalysisUptime', {
    start_time: start_time,
    end_time: end_time,
    showSite: showSite
  }, auth.currentUser?.user_id], load_analysis_uptime, {
    enabled: initialQueriesLoaded,
    staleTime: 1000 * 60 * 60
  });

  const { data: AnalysisVolSpotRQ, isLoading: isLoadingAnalysisVolSpotRQ, isError: isErrorAnalysisVolSpotRQ, refetch: refetchAnalysisVolSpotRQ } = useQuery(['AnalysisVolSpot', {
    start_time: start_time,
    end_time: end_time,
    showSite: showSite
  }, auth.currentUser?.user_id], load_analysis_volspot, {
    enabled: initialQueriesLoaded,
    staleTime: 1000 * 60 * 60
  });

  const { data: AnalysisEarningRQ, isLoading: isLoadingAnalysisEarningRQ, isError: isErrorAnalysisEarningRQ, refetch: refetchAnalysisEarningRQ } = useQuery(['AnalysisEarning', {
    start_time: start_time,
    end_time: end_time,
    showSite: showSite
  }, auth.currentUser?.user_id], load_analysis_earning, {
    enabled: initialQueriesLoaded,
    staleTime: 1000 * 60 * 60
  });


  const { data: BenchmarkRQ, isLoading: isLoadingBenchmarkRQ, isError: isErrorBenchmarkRQ, refetch: refetchBenchmarkRQ } = useQuery(['Benchmark', {
    start_time: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end_time: moment().format('YYYY-MM-DD'),
    selectedSites: [],
  }, auth.currentUser?.user_id], load_benchmark, { enabled: initialQueriesLoaded,staleTime: 1000 * 60 * 10 });

  // DEFAULT IMPORT OF DATA  ------------------------------------------------------------------------------------------


  useEffect(() => {
    if (DashboardEarningRQ) {
      const data = filterDataBySite(showSite, DashboardEarningRQ.spv)

      setEarningChart(data)
    }
    if (DashboardVolumeRQ) {
      const data = filterDataBySite(showSite, DashboardVolumeRQ.byspv)
      console.log('volume DashboardVolumeRQ', data)
      setVolumeChart(data)
    }
  }, [showSite])

  useEffect(() => {
    // console.log('use effect earning?.data')
    if (DashboardEarningRQ) {
      const data = filterDataBySite(showSite, DashboardEarningRQ.spv)
      setEarningChart(data)
    }
  }, [DashboardEarningRQ])

  useEffect(() => {
    if (DashboardVolumeRQ) {
      const data = filterDataBySite(showSite, DashboardVolumeRQ.byspv)
      setVolumeChart(data)
    }
  }, [DashboardVolumeRQ])


  console.log('DashboardVolumeRQ', DashboardVolumeRQ)


  const currentYear = moment().format('YYYY');
  const previousYear = moment().subtract(1, 'years').format('YYYY');

  const dataKey1 = `volume_${currentYear}`;
  const customTooltip = false;
  const dataKeyRender1 = `${currentYear}`;
  const dataKey2 = `volume_${previousYear}`;
  const dataKeyRender2 = `${previousYear}`;

  console.log('DashboardMecapaRQ', DashboardMecapaRQ)
  return (
    <Layout isDashboard={true} showSignlerArret showContactUs showNotifications>
      <Seo title="Bohr Energie | Tableau de bord" />
      <Layout.Header>
        <div>
          <h1 style={{ fontSize: 40 }} className="font-semibold text-3xl mb-2">
            Bonjour {auth.currentUser?.name},
          </h1>
          <p className="text-gray-6f font-medium text-xl">
            Voici le résumé de l’activité de vos sites.
          </p>
        </div>
        <div className="flex items-center gap-2">
          <Space direction="vertical">
            <Switch style={{ backgroundColor: '#5819F1' }} checkedChildren="Sites" unCheckedChildren="SPV" defaultChecked onChange={() => setShowSite(!showSite)} />
          </Space>
        </div>
      </Layout.Header>
      {/* <Spin spinning={loading}> */}

      <div className="flex-wrap" style={{ display: "flex" }}>
        <img
          src="/images/accueil.png"
          alt="dashboard"
          style={{ width: 167, position: "absolute", right: 66, top: 14 }}
          className="hidden md:block"
        />


        <EnergieComponentMemo earning={DashboardEarningRQ} isloading={isLoadingDashboardEarningRQ} earningChart={earningChart} />


        <Card
          className="w-full md:w-1/2"
          style={{ width: '100%', height: "100%", boxSizing: 'border-box' }}
          bodyStyle={{ padding: '5px', height: '500px' }}
          title={<div style={{ fontSize: '20px', fontWeight: 500, color: 'black' }}>{"Ma production d'énergie annuelle en " + moment().year()}</div>}
          voirPlus
          details={
            <>
              <div className="p-6 h-4/6 bg-white rounded-md">
                <div className="flex items-center gap-x-4  mb-1.5 ">
                  <Text type="32-600">
                    {parseInt(DashboardVolumeRQ?.totalVolumes['2024'] || 0).toLocaleString("fr")} MWh
                  </Text>
                  {/* <DeltaBadge delta={(((DashboardVolumeRQ?.totalVolumes['2023'] / DashboardVolumeRQ?.totalVolumes['2022']) - 1) * 100) || 0} /> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', marginTop: '10px', marginBottom: '10px' }}>

                  {DashboardVolumeRQ?.oa_volume !== 0 &&
                    <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{parseInt(DashboardVolumeRQ?.oa_volume || 0).toLocaleString("fr")} MWh OA -</div>
                  }



                  <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{" "} {parseInt(DashboardVolumeRQ?.market_volume || 0).toLocaleString("fr")} MWh Marché</div>
                </div>

                <BarChartComponent
                  unit="MWh"
                  maxTickNumber={10}
                  data={volumeChart}
                  dataKey1={dataKey1}
                  customTooltip={false}
                  dataKeyRender1={dataKeyRender1}
                  dataKey2={dataKey2}
                  dataKeyRender2={dataKeyRender2}
                />
              </div>
            </>
          }
        >
          <Spin spinning={isLoadingDashboardVolumeRQ}>
            <div className="flex items-center gap-x-4  mb-1.5">
              <Text type="32-600">
                {parseInt(DashboardVolumeRQ?.totalVolumes['2024'] || 0).toLocaleString("fr")} MWh
              </Text>
              <DeltaBadge delta={(((DashboardVolumeRQ?.totalVolumes[dataKeyRender1] / DashboardVolumeRQ?.totalVolumes[dataKeyRender2]) - 1) * 100) || 0} text={`vs ${moment().year() - 1}`} />
            </div>
            {/* <div style={{fontSize:'20px',fontWeight:500,color:'#6F729C'}}>
                {parseInt(DashboardVolumeRQ?.totalVolumes['2023'] || 0).toLocaleString("fr")} Mwh en{" "}
              {'2023'}
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', marginTop: '10px', marginBottom: '10px' }}>
              {DashboardVolumeRQ?.oa_volume !== 0 &&
                <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{parseInt(DashboardVolumeRQ?.oa_volume || 0).toLocaleString("fr")} MWh OA -</div>
              }

              <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{" "} {parseInt(DashboardVolumeRQ?.market_volume || 0).toLocaleString("fr")} MWh Marché</div>
            </div>

            <BarChartComponent
              unit="MWh"
              maxTickNumber={7}
              data={volumeChart}
              dataKey1={dataKey1}
              customTooltip={false}
              dataKeyRender1={dataKeyRender1}
              dataKey2={dataKey2}
              dataKeyRender2={dataKeyRender2}
            />
          </Spin>
        </Card>





        <Card
          className="w-full md:w-1/2"
          title={<div style={{ fontSize: '20px', fontWeight: 500, color: 'black' }}>{"Mon CA global annuel en " + moment().year()}</div>}
        >
          <Spin spinning={isLoadingDashboardEarningRQ}>
            <div className="flex items-center gap-x-4 mb-2.5">
              <Text type="32-600">
                {parseInt(DashboardEarningRQ?.total_gain || 0).toLocaleString("fr")} €
              </Text>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', height: '300px' }}>

              {DashboardEarningRQ?.['oa_power_gain'] != 0 ?
                <NIVO_PIE_OA data={DashboardEarningRQ} type={"OA"} total={parseInt(DashboardEarningRQ?.['oa_power_gain'] || 0).toLocaleString("fr")} />
                :
                <></>
              }

              <NIVO_PIE_MARKET data={DashboardEarningRQ} type={"Marché"} total={parseInt(DashboardEarningRQ?.['market_power_gain'] + DashboardEarningRQ?.['market_mecapa_gain'] + DashboardEarningRQ?.['market_gos_gain'] || 0).toLocaleString("fr")} />
            </div>
          </Spin>
        </Card>



        <Card className="w-full md:w-1/4"
          title={<div style={{ fontSize: '20px', fontWeight: 500, color: 'black' }}>Mes garanties d'origines</div>}>
          <Spin spinning={isLoadingDashboardGosRQ}>
            <div className="flex items-center gap-x-4 mb-2.5">
              <Text type="32-600">
                {parseInt(DashboardGosRQ?.total_gain_gos || 0).toLocaleString("fr")} €
              </Text>

            </div>
            {/* <Text type="16-600" className="text-gray-6f">
              <span>
                {parseInt(0).toLocaleString("fr")}
              </span>{" "}
              € avec l’ancien contrat
            </Text> */}
            <Text type="16-600" className="text-gray-6f">
              {""}
            </Text>
            <Table
              pagination={false}
              dataSource={DashboardGosRQ?.monthly_data}
              columns={columns_}
            />
          </Spin>
        </Card>




        <Card className="w-full md:w-1/4"
          title={<div style={{ fontSize: '20px', fontWeight: 500, color: 'black' }}>Mes mécanismes de capacité</div>}>
          <Spin spinning={isLoadingDashboardMecapaRQ}>
            <div className="flex items-center gap-x-4 mb-2.5">
              <Text type="32-600">
                {parseInt(DashboardMecapaRQ?.mecapa_earning || 0).toLocaleString("fr")} €
              </Text>

            </div>
            {/* <Text type="16-600" className="text-gray-6f">
              <span>
                {parseInt(0).toLocaleString("fr")}
              </span>{" "}
              € avec l’ancien contrat
            </Text> */}

            <Text type="16-600" className="text-gray-6f">
              {""}
            </Text>
            <Table
              pagination={false}
              dataSource={DashboardMecapaRQ?.mecapa_portfolio}
              columns={columns}
            />
          </Spin>
        </Card>



      </div>

      {/* </Spin> */}

    </Layout>
  )
}
