import React, { useEffect, useState } from "react"
import { Spin, Timeline } from "antd"
import moment from 'moment'
import { SmileOutlined } from '@ant-design/icons';
import BarChartComponent from "../Charts/BarChart";
import DeltaBadge from "../DeltaBadge";
import Text from "../Text";
import Card from "../Card";
import EnergieChart from "./EnergieChart";

interface P {
  earning: any,
  isloading: boolean,
  earningChart: any
}

const EnergieComponent: React.FC<P> = ({ earning, isloading, earningChart }) => {

  // console.log('EnergieComponent - earningChart', earningChart)

  return (
    <>
      <Card
        className="w-full md:w-1/2"
        style={{ width: '100%', height: "100%", boxSizing: 'border-box' }}
        bodyStyle={{ padding: '5px', height: '500px' }}
        title={<div style={{ fontSize: '20px', fontWeight: 500, color: 'black' }}>{"Mon CA Energie annuel en " + moment().year()}</div>}
        voirPlus
        details={
          <>
          <div className="p-6 h-4/6 bg-white rounded-md">
            <div className="flex items-center gap-x-4 mb-2.5">
              <Text type="32-600">
                {parseInt(earning?.total_power_gain || 0).toLocaleString("fr")} €
              </Text>
              {/* <DeltaBadge delta={(((earning?.power_portfolio_YTD / earning?.total_gain_benchmark) - 1) * 100) || 0} /> */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', marginTop: '10px', marginBottom: '10px' }}>
              {earning?.oa_power_gain !== 0 &&
                <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{parseInt(earning?.oa_power_gain || 0).toLocaleString("fr")}€ OA -</div>
              }
              <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{" "} {parseInt(earning?.market_power_gain || 0).toLocaleString("fr")}€ Marché</div>
            </div>
            <EnergieChart
              unit="€"
              maxTickNumber={20}
              customTooltip={true}
              data={earningChart}
              dataKey1=""
              dataKey2="total_gain"
              dataKeyRender1={``}
              dataKeyRender2={`Bohr Energie`}
            />
            </div>
          </>
        }
      >
        <Spin spinning={isloading}>
          <div className="flex items-center gap-x-4 mb-1.5">
            <Text type="32-600">
              {parseInt(earning?.total_power_gain || 0).toLocaleString("fr")} €
            </Text>

            {/* <DeltaBadge delta={(((earning?.power_portfolio_YTD / earning?.total_gain_benchmark) - 1) * 100) || 0} /> */}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', marginTop: '10px', marginBottom: '10px' }}>
            {earning?.oa_power_gain !== 0 &&
              <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{parseInt(earning?.oa_power_gain || 0).toLocaleString("fr")}€ OA -</div>
            }
            <div style={{ fontSize: '20px', fontWeight: 500, color: '#6F729C' }}>{" "} {parseInt(earning?.market_power_gain || 0).toLocaleString("fr")}€ Marché</div>
          </div>

          <EnergieChart
            unit="€"
            maxTickNumber={7}
            customTooltip={true}
            data={earningChart}
            dataKey1=""
            dataKey2="total_gain"
            dataKeyRender1={``}
            dataKeyRender2={`Bohr Energie`}
          />
        </Spin>
      </Card>
    </>
  )
}
export default EnergieComponent
