import { useEffect, useState } from "react"
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    TooltipProps,
} from "recharts"
import moment from "moment"
import Text from "../Text"


function stringToHexColor(string: string) {
    let hash = 0
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = "#"
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff
        color += ("00" + value.toString(16)).substr(-2)
    }
    return color
}

interface P {

    data: any,
    datakey: string,
    unit: any,

}



const MixBarChartComponent: React.FC<P> = ({ data, datakey, unit }) => {


    const [transData, settransData] = useState<any>([])
    const [sites, setsites] = useState()
    const [dataChart, setDataChart] = useState([])

    const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {

        if (active && payload && payload.length) {
            return (
                <div
                    className="text-white rounded-lg p-2"
                    style={{ background: "#20263D" }}
                >
                    {payload.map((p: any) => (
                        <div>



                            {
                                datakey == 'value' ?
                                    <>
                                        <Text type="12-500">Spot : </Text> <br />
                                        <Text type="14-600">
                                            {p.value.toLocaleString("fr")} {unit}
                                        </Text>

                                        <><br></br></>
                                        <Text type="12-500">H01 : </Text> <br />
                                        <Text type="14-600">
                                            {p.payload.value_h01.toLocaleString("fr")} {unit}
                                        </Text>
                                        <><br></br></>
                                        <Text type="12-500">H07 : </Text> <br />
                                        <Text type="14-600">
                                            {p.payload.value_h07.toLocaleString("fr")} {unit}
                                        </Text>
                                        <><br></br></>
                                        <Text type="12-500">S21 : </Text> <br />
                                        <Text type="14-600">
                                            {p.payload.value_s21.toLocaleString("fr")} {unit}
                                        </Text>
                                    </>
                                    :
                                    <>
                                        <Text type="12-500">Volume : </Text> <br />
                                        <Text type="14-600">
                                            {p.value.toLocaleString("fr")} {unit}
                                        </Text>
                                        </>

                            }
                        </div>
                    ))}
                </div>
            )
        }

        return null
    }

    useEffect(() => {
        setDataChart(data)
    }, [data])

    console.log(data, datakey, unit)

    type CustomTooltipProps = TooltipProps<any, any> & {
        active?: any
        payload?: any
        label?: any
        xScale?: any
    }



    return (
        <ResponsiveContainer width="95%" height={300}>
            <BarChart data={dataChart} style={{ background: "white" }}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                  }}>
                <CartesianGrid strokeDasharray="2 2" vertical={false} />
                <XAxis tickLine={false} axisLine={false} dataKey="start_time" />
                <YAxis
                    padding={{ bottom: 0 }}
                    domain={["auto", "auto"]}
                    tick={{ fontSize: 12 }}
                    unit={unit}
                    tickLine={false}
                    axisLine={false}
                    // tickFormatter={(val)=>(val).toLocaleString('fr')}
                    tickFormatter={value =>
                        new Intl.NumberFormat("fr", {
                            notation: "compact",
                            compactDisplay: "short",
                        }).format(value)
                    }
                    domain={["0", "dataMax"]}
                />
                <Tooltip
                    content={p => <CustomTooltip {...p} />}
                    cursor={{ fill: "transparent" }}
                />
                <Legend
                    wrapperStyle={{
                        marginLeft: 20,
                        top: -15,
                    }}
                    iconType="circle"
                    align="left"
                    verticalAlign="top"
                />


                <Bar
                    dataKey={datakey}
                    stackId="a"
                    fill={'purple'}
                    barSize={12}
                />

                {/* {datakey=='value' ?
                <>
                <Bar
                dataKey={'value_h07'}
                fill={'green'}
                barSize={12}
                />

                <Bar
                dataKey={'value_h01'}
                fill={'red'}
                barSize={12}
                />
                </>
          :

          <></>
          
          } */}


            </BarChart>
        </ResponsiveContainer>
    )
}
export default MixBarChartComponent
