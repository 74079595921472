import React, { useEffect, useState } from "react"
import { Card, DatePicker, Input, Select, Spin, Tag } from 'antd';
import NettingChartComponent from "./NettingChart";
const { RangePicker } = DatePicker;
import styled, { keyframes,css } from 'styled-components';
import TagInput from "./TagInput.";
import { useMutation, useQueryClient } from 'react-query';
import { load_enedis_r4q } from "../../actions/load_enedis_r4q";

const shiftColor = keyframes`
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
`;

const LoadingTag = styled(Tag)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.loading && css`
    background: linear-gradient(to left, #ffffff, #DDD1FC);
    background-size: 200% 100%;
    background-position: left;
    animation: ${shiftColor} 2s infinite alternate ease-in-out;

  `}

  .ant-tag-close-icon {
    margin-left: auto; // Ensures the close icon stays on the right
  }
`;


function transformData(data,type) {
  const dataMap = new Map();

  // Function to process individual items
  const processItem = (item, type) => {
    const { prm, start_time, volume } = item;

    if (!dataMap.has(prm)) {
      dataMap.set(prm, {
        id: prm,
        color: "", // Assign a color if needed
        type : type,
        data: []
      });
    }

    dataMap.get(prm).data.push({
      x: start_time,
      y: volume,
      type: type // Adding a type field to distinguish between Conso and Prod
    });
  };

  // Process each item in dataConso and dataProd
  data.forEach(item => processItem(item, type));

  return Array.from(dataMap.values());
}

const Matching = () => {

  const [dataConso, setDataConso] = useState([]);
  const [dataProd, setDataProd] = useState([]);

  const [producteurTags, setProducteurTags] = useState([]);
  const [consommateurTags, setConsommateurTags] = useState([]);
  const [prevProducteurTags, setPrevProducteurTags] = useState([]);
  const [prevConsommateurTags, setPrevConsommateurTags] = useState([]);

  const [loadingTags, setLoadingTags] = useState({'test':true}); // This can be a combined state for both

  const handleTagChange = (newTags, type) => {
    console.log('handleTagChange', newTags, type);

    let currentTags = type === 'producteur' ? producteurTags : consommateurTags;

    // For each new tag, trigger a new mutation
    newTags.forEach(tag => {
      if (!currentTags.includes(tag)) {
        console.log('New tag detected:', tag);
        setLoadingTags(prevLoadingTags => ({ ...prevLoadingTags, [tag]: true }));
        mutation.mutate({ newTag: tag, type });
      }
    });

    // Update the tags state
    if (type === 'producteur') {
      setProducteurTags(newTags);
    } else {
      setConsommateurTags(newTags);
    }
  };

  // useEffect to handle tag changes if necessary
  useEffect(() => {
    // Handle any side effects of tag change


  }, [producteurTags, consommateurTags]);



  const queryClient = useQueryClient();

  // Set up the mutation with useMutation

  const mutation = useMutation(
    ({ newTag, type }) => {
      const sens = type === 'producteur' ? 'INJECTION' : 'SOUTIRAGE';
      const mesuresCorrigees = type === 'producteur' ? true : false;
      return load_enedis_r4q({
        dateDebut: '2024-01-01', 
        dateFin: '2024-02-28',
        prm: newTag,
        insert: false,
        restore: true,
        mesuresCorrigees: mesuresCorrigees,
        sens: sens
      });
    },
    {
      onSuccess: (data, { newTag, type }) => { // Updated to receive an object
        console.log('success data', data.data.hour);
        setLoadingTags(prevLoadingTags => {
          const updatedLoadingTags = { ...prevLoadingTags };
          delete updatedLoadingTags[newTag];
          return updatedLoadingTags;
        });
        console.log('Success - type', type);
        const x = transformData(data.data.hour,type)
        console.log('x',x[0])
        if (type === 'producteur') {
          setDataProd([...dataProd, x[0]]); // Add x[0] as a new element
        } else {
          setDataConso([...dataConso, x[0]]); // Same for dataConso
        }
        // Handle successful data fetching
      },
      onError: (error, { newTag, type }) => { // Updated to receive an object
        setLoadingTags(prevLoadingTags => {
          const updatedLoadingTags = { ...prevLoadingTags };
          delete updatedLoadingTags[newTag];
          return updatedLoadingTags;
        });
        // Handle error
      },
    }
  );





  const handleTagClose = (tag, type) => {
    console.log('handleTagClose', tag, type);
    if (type=='producteur') {
      setDataProd(dataProd => dataProd.filter(item => item.id !== tag));
    } else {
      setDataConso(dataConso => dataConso.filter(item => item.id !== tag));
    }
    
  }


  console.log('dataConso',dataConso)
  console.log('dataProd',dataProd)

  return (
    <>






<div className="flex all-Campton" style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '20px', paddingBottom: '20px' }}>
      <div>
        <TagInput
          title="Consommateur"
          tags={consommateurTags}
          loadingTags={loadingTags}
          onTagsChange={(newTags) => handleTagChange(newTags, 'consommateur')}
          onTagsClose={(tag) => handleTagClose(tag, 'consommateur')}
        />
      </div>
      <div>
        <TagInput
          title="Producteur"
          tags={producteurTags}
          loadingTags={loadingTags}
          onTagsChange={(newTags) => handleTagChange(newTags, 'producteur')}
          onTagsClose={(tag) => handleTagClose(tag, 'producteur')}
        />
      </div>
    </div>



      <div>
        <Card>
          <NettingChartComponent dataConso={dataConso} dataProd={dataProd} />
        </Card>

      </div>

    </>
  )
}

export default Matching
