import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../../components/seo"
import { DateSelector } from "../components/DateSelector"
import Card from "../components/Card"
import Text from "../components/Text"
import DeltaBadge from "../components/DeltaBadge"
import SelectDropdown from "../components/SelectDropdown"
import { Avatar, Spin, Table, Tag, Collapse, Col, Row, Popover, } from "antd"
import { Line, LineChart } from "recharts"
import MixBarChart from "../components/Charts/MixBarChart"
import { stringToHexColor } from "../utils"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { fetchAnalysis, fetchAnalysisEarning, fetchAnalysisEarningY_1, fetchAnalysisUptime, fetchAnalysisVolSpot, fetchSitesDetail } from "../redux/actions"
import moment from "moment"
import ModalAssign from "../components/Manage/ModalAssign"
import { Icon } from "../components/Icon"
import { BorderBottom } from "@material-ui/icons"
import { fetchCompanies } from "../redux/actions/commun"
import { Switch, Space } from 'antd';
import UpTimeChart from "../components/Charts/UpTimeChart"
import { useQuery } from "react-query"
import { load_analysis_uptime } from "../actions/load_analysis_uptime"
import { load_analysis_earning } from "../actions/load_analysis_earning"
import { load_analysis_volspot } from "../actions/load_analysis_volspot"
import { load_analysis_volconso } from "../actions/load_analysis_volconso"
import { load_analysis_earning_y_1 } from "../actions/load_analysis_earning_y_1"
import { load_sites_details } from "../actions/load_sites_details"
import { load_companies } from "../actions/load_companies"
import { load_dashboard_volume } from "../actions/load_dashboard_volume"


function transform(oldData, keymap, filter) {
  if (!oldData) return [];

  // Filter oldData based on contract_type, except when filter is "Tous"
  let filteredData = oldData.filter(item => filter === "Tous" || item.contract_type === filter);

  let newData1 = filteredData.reduce((acc, item) => {
    if (!acc[item.start_time]) {
      acc[item.start_time] = {};
    }
    acc[item.start_time][item.unit_name] = parseFloat(item[keymap]);
    return acc;
  }, {});

  var arr = [];
  Object.entries(newData1).forEach(([key, value]) =>
    arr.push({ date: key, ...value })
  );

  return arr;
}
export default function Analyses() {

  const auth = useSelector((state: RootStateOrAny) => state.auth)
  var start_date = new Date()
  var end_date = new Date()
  end_date.setDate(end_date.getDate());
  var firstDayOfYear = new Date(start_date.getFullYear(), 0, 1);
  var firstDayOfMonth = new Date(start_date.getFullYear(), start_date.getMonth(), 1);



  // const analyse = useSelector((state: RootStateOrAny) => state.analyse)
  // const commun = useSelector((state: RootStateOrAny) => state.commun)

  // useEffect(() => {
  //   dispatch(fetchSitesDetail())
  //   dispatch(fetchCompanies())
  //   const companies = commun.companies?.map(item => { return { ...item, name: item.name.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()) } })
  //   setSPV(companies)

  // }, [])
  // const { data: sitesDetails } = useSelector(
  //   (state: RootStateOrAny) => state.sites
  // )


  const [SPV, setSPV] = useState(Array())
  const [selectedSPV, setSelectedSPV] = useState(Array())
  const [showSite, setShowSite] = React.useState(true)
  const [xScale, setxScale] = useState({ key: "day", value: "Jour" })
  const [xScale2, setxScale2] = useState({ key: "day", value: "Jour" })
  const [xScale3, setxScale3] = useState({ key: "day", value: "Jour" })
  const [contract, setContract] = useState({ key: "Tous", value: "Tous" })
  const [contract2, setContract2] = useState({ key: "Tous", value: "Tous" })
  const [start_time, setstart_time] = useState(firstDayOfYear.toLocaleString("sv", { timeZone: "Europe/Paris" }).split(' ')[0])
  const [end_time, setend_time] = useState(end_date.toISOString().split('T')[0])
  const [selectedSites, setselectedSites] = useState()


  useEffect(() => {
    refetchAnalysisUptimeRQ
    refetchAnalysisVolSpotRQ
    refetchAnalysisEarningRQ
  }, [start_time, end_time])


  useEffect(() => {
    console.log('changing spv', selectedSPV)
  }, [selectedSPV])



  const { data: AnalysisUptimeRQ, isLoading: isLoadingAnalysisUptimeRQ, isError: isErrorAnalysisUptimeRQ, refetch: refetchAnalysisUptimeRQ } = useQuery(['AnalysisUptime', {
    start_time: start_time,
    end_time: end_time,
    // selectedSPV: selectedSPV,
    showSite: showSite
  }, auth.currentUser?.user_id], load_analysis_uptime, { staleTime: 1000 * 60 * 60 });

  const { data: AnalysisVolSpotRQ, isLoading: isLoadingAnalysisVolSpotRQ, isError: isErrorAnalysisVolSpotRQ, refetch: refetchAnalysisVolSpotRQ } = useQuery(['AnalysisVolSpot', {
    start_time: start_time,
    end_time: end_time,
    // selectedSPV: selectedSPV,
    showSite: showSite
  }, auth.currentUser?.user_id], load_analysis_volspot, { staleTime: 1000 * 60 * 60 });

  // const { data: AnalysisVolConsoRQ, isLoading: isLoadingAnalysisVolConsoRQ, isError: isErrorAnalysisVolConsoRQ, refetch: refetchAnalysisVolConsoRQ } = useQuery(['AnalysisVolConso', {
  //   start_time: start_time,
  //   end_time: end_time,
  //   // selectedSPV: selectedSPV,
  //   showSite: showSite
  // }, auth.currentUser?.user_id], load_analysis_volconso, { staleTime: 1000 * 60 * 60 });

  const { data: AnalysisEarningRQ, isLoading: isLoadingAnalysisEarningRQ, isError: isErrorAnalysisEarningRQ, refetch: refetchAnalysisEarningRQ } = useQuery(['AnalysisEarning', {
    start_time: start_time,
    end_time: end_time,
    // selectedSPV: selectedSPV,
    showSite: showSite
  }, auth.currentUser?.user_id], load_analysis_earning, { staleTime: 1000 * 60 * 60 });

  // const { data: AnalysisEarningY_1RQ, isLoading: isLoadingAnalysisEarningY_1RQ, isError: isErrorAnalysisEarningY_1RQ, refetch: refetchAnalysisEarningY_1RQ } = useQuery(['AnalysisEarningY_1', {
  //   start_time: start_time,
  //   end_time: end_time,
  //   // selectedSPV: selectedSPV,
  //   showSite: showSite
  // }, auth.currentUser?.user_id], load_analysis_earning_y_1, { staleTime: 1000 * 60 * 60 });


  const { data: DashboardVolumeRQ, isLoading: isLoadingDashboardVolumeRQ, isError: isErrorDashboardVolumeRQ, refetch: refetchDashboardVolumeRQ } = useQuery(['DashboardVolume', {
    showSite: showSite
  }, auth.currentUser?.user_id], load_dashboard_volume, { staleTime: 1000 * 60 * 60 });


  const { data: CompaniesRQ, isLoading: isLoadingCompaniesRQ, isError: isErrorCompaniesRQ, refetch: refetchCompaniesRQ } = useQuery('companies', load_companies, { staleTime: 30 * 60 * 1000 });

  useEffect(() => {
    if (CompaniesRQ) {
      const companies = CompaniesRQ?.map(item => { return { ...item, name: item.name.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()) } })
      setSPV(companies)
    }
  }, [CompaniesRQ])

  console.log('AnalysisVolSpotRQ', AnalysisVolSpotRQ)


  const handleSlectHour = val => {
    setxScale(val)
  }
  const handleSlectHour2 = val => {
    setxScale2(val)
  }

  const handleSlectHour3 = val => {
    setxScale3(val)
  }


  const handleSelectContract2 = val => {
    setContract2(val)
  }

  const handleSelectContract = val => {
    setContract(val)
  }

  const handleSelectSPV = val => {
    setSelectedSPV(val.map(i => i.public_id))
  }
  const handleUnselectSPV = val => {
    setSelectedSPV(selectedSPV.filter(i => i != val))
  }

  const [uniqueMonths, setUniqueMonths] = useState(Array())

  const f_uniqueMonths = () => {

    const _uniqueMonths = new Set();
    // Iterate over the array and collect unique month values
    AnalysisUptimeRQ?.prm.forEach(item => {
      const excludeKeys = ['site', 'spv'];
      const months = Object.keys(item).filter(key => !excludeKeys.includes(key));
      months.forEach(month => _uniqueMonths.add(month));
    });
    // Convert the Set to an array
    const monthList = Array.from(_uniqueMonths);
    setUniqueMonths(monthList)
  }


  useEffect(() => {
    if (AnalysisUptimeRQ?.prm) {
      var z = f_uniqueMonths()
    }
  }, [AnalysisUptimeRQ])


  const columns = [
    {
      title: "Site",
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => (
        <>
          <Avatar
            gap={9}
            style={{
              backgroundColor: stringToHexColor(name),
              color: "white",
              marginRight: 19,
            }}
            size={40}
          >
            {name.slice(0, 2)}
          </Avatar>
          {name}
        </>
      ),
    },
    {
      title: "CA Global",
      dataIndex: "total_earning",
      key: "total_gain",
      responsive: ["lg"],
      render: (_, { total_gain, total_gain_benchmark, benchmark }) => (
        <div>
          <Text type="16-600">{parseInt(total_gain).toLocaleString("fr")} €</Text>{" "}
          {/* <br />
          <Text type="16-600" className="text-gray-6f">
            {parseInt(total_gain_benchmark).toLocaleString("fr")}€ en {benchmark}
          </Text> */}
        </div>
      ),
    },

    {
      title: "Energie",
      dataIndex: "portfolio_earning_delta",
      key: "power_gain",
      responsive: ["lg"],
      render: (_, { power_gain }) => (
        <Text type="12-500">{parseInt(power_gain).toLocaleString("fr")} €</Text>
      ),
    },

    {
      title: "Mecanisme de capacité",
      dataIndex: "portfolio_earning_delta",
      key: "mecapa_gain",
      responsive: ["lg"],
      render: (_, { mecapa_gain }) => (
        <Text type="12-500">{parseInt(mecapa_gain).toLocaleString("fr")} €</Text>
      ),
    },

    {
      title: "Garantie d'origine",
      dataIndex: "portfolio_earning_delta",
      key: "gos_gain",
      responsive: ["lg"],
      render: (_, { gos_gain }) => (
        <Text type="12-500">{parseInt(gos_gain).toLocaleString("fr")} €</Text>
      ),
    },
  ]




  const PanelHeader = ({ data }) => (
    <Row className="flex items-center">
      <Col xs={24} sm={12} md={8} lg={8}>
        <Avatar
          gap={9}
          style={{
            backgroundColor: stringToHexColor(data.name),
            color: "white",
            marginRight: 19,
          }}
          size={40}
        >
          {data.name.toUpperCase().slice(0, 2)}
        </Avatar>
        <Text type="16-600" style={{ textTransform: 'capitalize' }}>{data.name}</Text>
      </Col>
      <Col xs={24} sm={12} md={8} lg={4}>
        <Text className="text-black-6f" type="14-600">
          CA Global
        </Text>
        <Text className="block" type="14-500" >
          {parseInt(data.total_gain).toLocaleString('fr')}  {" "}€
        </Text>
        {/* <DeltaBadge
          background={false}
          delta={Math.round(data.total_gain_evol* 100) }
        /> */}
      </Col>

      <Col xs={24} sm={12} md={8} lg={4}>
        <Text className="text-gray-6f" type="12-500">
          Energie
        </Text>
        <Text className="block" type="14-500">
          {parseInt(data.power_gain).toLocaleString('fr')}  {" "}€
        </Text>
      </Col>
      <Col xs={24} sm={12} md={8} lg={4}>
        <Text className="text-gray-6f" type="12-500">
          Mecanisme de capacité
        </Text>
        <Text className="block" type="14-500">
          {parseInt(data.mecapa_gain).toLocaleString('fr')}  {" "}€
        </Text>
      </Col>
      <Col xs={24} sm={12} md={8} lg={4}>
        <Text className="text-gray-6f" type="12-500">
          Garantie d'origine
        </Text>
        <Text className="block" type="14-500">
          {parseInt(data.gos_gain).toLocaleString('fr')}  {" "}€
        </Text>
      </Col>
    </Row>
  )


  const PanelBody = ({ data }) => (
    <Table
      style={{ margin: 0, padding: 0 }}
      dataSource={data}
      pagination={false}
      columns={columns}
    />
  )

  const { Panel } = Collapse

  const data2 = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
    },
    {
      name: "Page B",
      uv: 5000,
      pv: 1398,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 6800,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 9908,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 14800,
    },
  ]




  const columns3 = [
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      render: (text) => <a>{text}</a>,
    },
    ...uniqueMonths.map(month => ({
      title: moment(month, "YYY-MM-DD").format("MMM YY"),
      dataIndex: month,
      key: month,
      align: "center",
      render(text, record) {
        return {
          props: {
            style: { background: text && text["uptime"] ? "rgba(221,255,221," + text["uptime"] / text["total"] + ")" : 'white' }
          },
          children: <div >
            {text && text["uptime"] ?
              <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ width: '50%', border: '0px blue solid', display: 'flex', flexDirection: 'column' }}>
                    <Popover title="Pourcentage du mois où production > 15kwh">
                      <div style={{ color: 'black', margin: '3px', padding: '5px', borderRadius: '2px', background: 'white', fontSize: '14px' }}>
                        {text["uptime"]} hrs
                      </div>
                    </Popover>
                    <Popover title={`Production Totale MWh ${text["total_prd"] ? text["total_prd"].toFixed(3) : ""}`} >
                      <div style={{ color: 'black', margin: '3px', padding: '5px', borderRadius: '2px', background: 'white', fontSize: '14px' }}>
                        {text["total_prd"] ? text["total_prd"].toFixed(0) : ''} MWh
                      </div>
                    </Popover>
                  </div>
                  <div style={{ width: '50%', border: '0px blue solid', display: 'flex', flexDirection: 'column' }}>
                    <Popover title="Pourcentage du mois où production > 15kwh">
                      <div style={{ color: 'black', margin: '3px', padding: '5px', borderRadius: '2px', background: 'white', fontSize: '14px' }}>
                        {(text["uptime"] * 100 / text["total"]).toFixed(0) + '%'}
                      </div>
                    </Popover>
                    <Popover title="Production moyenne lorsque production > 15kwh">
                      <div style={{ color: 'black', margin: '3px', padding: '5px', borderRadius: '2px', background: 'white', fontSize: '14px' }}>
                        {text["avg_prd"]} kwh
                      </div>
                    </Popover>
                  </div>
                </div>
              </>
              :
              <a>-</a>
            }
          </div>
        };
      }
    }))
  ];






  const totalVolumeSumCurrent = (AnalysisEarningRQ?.spv?.filter(item => selectedSPV.length === 0 || selectedSPV.includes(item.spv)) || []).reduce((acc, item) => {
    return acc + (parseInt(item.total_volume) || 0);
  }, 0);

  const totalGainSumCurrent = (AnalysisEarningRQ?.spv?.filter(item => selectedSPV.length === 0 || selectedSPV.includes(item.spv)) || []).reduce((acc, item) => {
    return acc + (parseInt(item.total_gain) || 0);
  }, 0);

  // Calculate the sum of total_volume for the previous year (multiplied by 1000 if needed)
  const totalVolumeSumPrevious = (DashboardVolumeRQ?.byspv?.filter(item => selectedSPV.length === 0 || selectedSPV.includes(item.spv)) || []).reduce((acc, item) => {
    return acc + (parseInt(item[`volume_${moment().year() - 1}`]) || 0);  // Assuming you need to multiply by 1000
  }, 0);

  const formattedTotalVolumeCurrent = totalVolumeSumCurrent.toLocaleString("fr");
  const formattedTotalGainCurrent = totalGainSumCurrent.toLocaleString("fr");
  const formattedTotalVolumePrevious = totalVolumeSumPrevious.toLocaleString("fr");


  return (
    <Layout isDashboard={true} showSignlerArret showContactUs showNotifications>
      <Seo title="Bohr Energie | Analyses" />
      <Layout.Header>
        <h1 style={{ fontSize: 40 }} className="font-semibold text-3xl mb-0">
          Analyses
        </h1>
        <div className="flex flex-col sm:flex-row items-center gap-x-5 gap-y-4">
          <SelectDropdown
            items={SPV}
            keyAttribute="public_id"
            valueAttribute="name"
            defaultValues={selectedSPV}
            onSelect={handleSelectSPV}
            placeholder="Sélectionnez un SPV / Société"
          />
          <div className="flex items-center gap-2">
            <DateSelector
              defaultValue={start_time}
              onChange={(date: string) => setstart_time(date)}
              format="YYYY-MM-DD"
              showHours={false}
            />
            au
            <DateSelector
              defaultValue={end_time}
              onChange={(date: string) => setend_time(date)}
              format="YYYY-MM-DD"
              showHours={false}
            />
          </div>
        </div>
      </Layout.Header>

      <div className="flex flex-wrap gap-3">
        {SPV?.filter(
          spv => selectedSPV && selectedSPV.includes(spv.public_id)
        )
          .map(spv => (
            <Tag closable onClose={() => handleUnselectSPV(spv.public_id)}>{spv.name}</Tag>
          ))}
      </div>

      <Spin spinning={isLoadingAnalysisEarningRQ}>
        <div className="flex-wrap" style={{ display: "flex" }}>
          <Card
            title="Mon CA global"
            className="w-full md:w-1/3"
            bodyClassName="flex"
          >
            <div className="flex flex-col gap-4 pr-4">
              <LineChart width={65} height={40} data={data2}>
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#19753C"
                  strokeWidth={3}
                  dot={false}
                />
              </LineChart>
              {/* <DeltaBadge delta={(((AnalysisEarningRQ?.total_gain / AnalysisEarningRQ?.total_gain_benchmark) - 1) * 100) || 0} /> */}
            </div>
            <div>
              <Text type="32-600" className="block mb-1.5">
                {formattedTotalGainCurrent} €
              </Text>
              <Text type="16-600" className="text-gray-6f">
                Soit {formattedTotalVolumeCurrent} MWh
                de production
              </Text>
            </div>
            {/* </Spin> */}
          </Card>

          <Card
            className="w-full md:w-1/3"
            title="Ma production totale vendue"
            bodyClassName="flex"
          >
            <div className="flex flex-col gap-4 pr-4">
              <LineChart width={65} height={40} data={data2}>
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#19753C"
                  strokeWidth={3}
                  dot={false}
                />
              </LineChart>
              {/* <DeltaBadge delta={(((AnalysisEarningRQ?.total_volume / AnalysisEarningY_1RQ?.total_volume) - 1) * 100) || 0} /> */}
            </div>
            <div>
              <Text type="32-600" className="block mb-1.5">
                {formattedTotalVolumeCurrent} MWh
              </Text>
              <Text type="16-600" className="text-gray-6f">
                {formattedTotalVolumePrevious} MWh l'année dernère
              </Text>
            </div>
          </Card>

          <Card
            className="w-full md:w-1/3"
            title="Mon Solde"
            bodyClassName="flex"
          >
            <div className="flex flex-col gap-4 pr-4">
              <LineChart width={65} height={40} data={data2}>
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#19753C"
                  strokeWidth={3}
                  dot={false}
                />
              </LineChart>
              {/* <DeltaBadge delta={(((AnalysisEarningRQ?.total_gain / AnalysisEarningRQ?.total_gain_benchmark) - 1) * 100) || 0} /> */}
            </div>
            <div>
            <Text type="32-600" className="block mb-1.5">
                {formattedTotalGainCurrent} €
              </Text>
              <Text type="16-600" className="text-gray-6f">
                Soit {formattedTotalVolumeCurrent} MWh
                de production
              </Text>
            </div>
            {/* </Spin> */}
          </Card>


          <Card className="w-full" title="Mes SPV / Sociétés" >

            <Spin spinning={isLoadingAnalysisEarningRQ} style={{ padding: '0px' }}>
              <Collapse
                style={{ padding: '0px' }}
                defaultActiveKey={[]}
                expandIconPosition="end"
                ghost
                collapsible="icon"
                expandIcon={({ isActive }) => (
                  <Icon name={isActive ? "arrow-up" : "arrow-down"} />
                )}
              >

                {AnalysisEarningRQ?.spv?.filter(item => selectedSPV.length === 0 || selectedSPV.includes(item.spv)).map((site: any, index: number) => (
                  <Panel
                    style={{ marginBottom: '10px', borderBottom: '1px #f1f1f1 solid' }}
                    header={<PanelHeader data={site} />}
                    key={index + ""}
                  >
                    <PanelBody data={site.site} />
                  </Panel>
                ))}
              </Collapse>
            </Spin>

          </Card>

          <Card
            className="w-full"
            title="Mon CA energie"
            headerRight={

              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>

                <SelectDropdown
                  items={[
                    { key: "Tous", value: "Tous" },
                    { key: "marché", value: "marché" },
                    { key: "oa", value: "oa" },
                  ]}
                  type="none"
                  onSelect={handleSelectContract}
                  placeholder="Select"
                  defaultValue={contract}
                  width={112}
                />

                <SelectDropdown
                  items={[
                    { key: "day", value: "Jour" },
                    { key: "week", value: "Semaine" },
                    { key: "month", value: "Mois" },
                  ]}
                  type="none"
                  onSelect={handleSlectHour}
                  placeholder="Select"
                  defaultValue={xScale}
                  width={112}
                />
              </div>
            }
          >
            <Spin spinning={isLoadingAnalysisVolSpotRQ} style={{ padding: '0px' }}>
              <MixBarChart
                xScale={xScale.key}
                contract={contract.key}
                data={transform(AnalysisVolSpotRQ?.filter(item => selectedSPV.length === 0 || selectedSPV.includes(item.public_id_companies)), 'earning', contract.key)}
                unit={"€"} />
            </Spin>
          </Card>



          <Card
            className="w-full"
            title="Ma production"
            headerRight={
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>

                <SelectDropdown
                  items={[
                    { key: "Tous", value: "Tous" },
                    { key: "marché", value: "marché" },
                    { key: "oa", value: "oa" },
                  ]}
                  type="none"
                  onSelect={handleSelectContract2}
                  placeholder="Select"
                  defaultValue={contract2}
                  width={112}
                />

                <SelectDropdown
                  items={[
                    { key: "day", value: "Jour" },
                    { key: "week", value: "Semaine" },
                    { key: "month", value: "Mois" },
                  ]}
                  type="none"
                  onSelect={handleSlectHour2}
                  placeholder="Select"
                  defaultValue={xScale2}
                  width={112}
                />

              </div>

            }
          >
            <Spin spinning={isLoadingAnalysisVolSpotRQ} style={{ padding: '0px' }}>
              <MixBarChart
                xScale={xScale2.key}
                contract={contract2.key}
                data={transform(AnalysisVolSpotRQ?.filter(item => selectedSPV.length === 0 || selectedSPV.includes(item.public_id_companies)), 'volume', contract2.key)}
                unit={"MWh"} />
            </Spin>
          </Card>


          {/* {AnalysisVolConsoRQ && AnalysisVolConsoRQ.length > 0 ?
            <Card
              className="w-full"
              title="Ma Consommation"
              headerRight={
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>


                  <SelectDropdown
                    items={[
                      { key: "hour", value: "Heure" },
                      { key: "day", value: "Jour" },
                      { key: "week", value: "Semaine" },
                      { key: "month", value: "Mois" },
                    ]}
                    type="none"
                    onSelect={handleSlectHour3}
                    placeholder="Select"
                    defaultValue={xScale3}
                    width={112}
                  />

                </div>

              }
            >
              <Spin spinning={isLoadingAnalysisVolConsoRQ} style={{ padding: '0px' }}>


                <MixBarChart
                  xScale={xScale2.key}
                  contract={"Tous"}
                  data={transform(AnalysisVolConsoRQ, 'volume', "Tous")}
                  unit={"KWh"}
                />



              </Spin>
            </Card>
            :
            <></>

          } */}
          <Card
            className="w-full"
            title="Mes statistiques de fonctionnement (mois)"
          >
            <Spin spinning={isLoadingAnalysisUptimeRQ} style={{ padding: '0px' }}>
              <Table columns={columns3} dataSource={AnalysisUptimeRQ?.prm.filter(item => selectedSPV.length === 0 || selectedSPV.includes(item.spv))} pagination={false} />
            </Spin>
          </Card>
        </div>
      </Spin>
    </Layout>
  )
}
