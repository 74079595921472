import React, { useEffect, useState, Suspense } from "react"
import { Col, Collapse, Row, Table, Tag, Upload, Space, Modal, Switch, Select } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import ApiService from "../../services/ApiService";
import { fetchInvoices } from "../../redux/actions";
import moment from 'moment'
import { Colors, Icon } from "../Icon";
import Text from "../Text"

import TextArea from "antd/es/input/TextArea";
import TimelineComponent from "../Facturation/Timeline";
import UtilFactureComponent from "../Facturation/UtilFacture";
import { SyncOutlined } from "@material-ui/icons";
import FormContestComponent from "../Facturation/FormContest";
import FormValidateComponent from "../Facturation/FormValidate";
import { useQuery } from "react-query";
import { load_invoices } from "../../actions/load_invoices";


const { Panel } = Collapse
// const { confirm } = Modal


export default function FactureAdmin() {



  const auth = useSelector((state: RootStateOrAny) => state.auth)
  const { data: invoicesRQ, isLoading: isLoadinginvoicesRQ, isError: isErrorinvoicesRQ, refetch: refetchinvoicesRQ } = useQuery(['invoices',auth.currentUser?.user_id], load_invoices,{staleTime: 30*60*1000});


  const dispatch = useDispatch()

  const [invoices, setInvoices] = useState(Array())
  const [Dates, setDates] = useState(Array())
  const [Status, setStatus] = useState(Array())
  const [Labels, setLabels] = useState(Array())
  const [selectedDate, setSelectedDate] = useState(Array())
  const [selectedLabel, setSelectedLabel] = useState(Array())
  const [selectedStatus, setSelectedStatus] = useState(Array())
  const [modalPdfOpen, setmodalPdfOpen] = useState(false)
  const [pdfContent, setPdfContent] = useState(null)
  const [okFAR, setOkFAR] = React.useState(true)



  useEffect(() => {
    console.log("useEffect invoices")
    if (invoicesRQ) {
      console.log("useEffect invoices f", invoicesRQ)
      setInvoices(invoicesRQ)
      const uniques = extractUnique(invoicesRQ)
      setDates(uniques.dates)
      setStatus(uniques.status)
      setLabels(uniques.labels)
    }
  }, [invoicesRQ])


  const onChange = (info) => {
    console.log(info)
  };





  const handleCloseModalPdf = () => {
    setmodalPdfOpen(false)
  }

  const genExtra = invoice => (
    <Space>

      {

          invoice.status === "validé client" ?
            <Icon
              color={Colors.primary}
              title="En attente validation Bohr"
              style={{ right: 25, position: "relative" }}
              name="clock"
            />
            :
            invoice.status === "conflit" ?
              <Icon
                color={"red"}
                title="en re-édition"
                style={{ right: 25, position: "relative" }}
                name="check"
              />
              :
              invoice.status === "validé bohr" ?
                <Icon
                  color={Colors.primary}
                  title="paiement en cours"
                  style={{ right: 25, position: "relative" }}
                  name="clock"
                />
                :
                invoice.status === "payé" ?
                  <Icon
                    color={Colors.primary}
                    title="tout est bon !"
                    style={{ right: 25, position: "relative" }}
                    name="dollar"
                  />
                  :
                  <></>
      }

    </Space>
  )

  const PanelHeader = ({ data }) => (
    <Row className="flex items-center">
      <Col xs={24} sm={12} md={4} lg={3}>
        <Text className="text-gray-6f" type="12-500">
          Date
        </Text>
        <Text className="block" type="14-500">
          {moment(data.invoice_id.slice(0, 6), "YYYYMM").endOf('month').format('YYYY-MM-DD') || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={4}>
        <Text className="text-gray-6f" type="12-500">
          Référence
        </Text>
        <Text className="block" type="14-500">
          {data.invoice_id || "-"}
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={8}>
        <Text className="text-gray-6f" type="12-500">
          Label
        </Text>
        <Text className="block" type="14-500">
          <Text type="16-600" style={{ textTransform: 'capitalize' }}>{data.invoice_label}</Text>
        </Text>
      </Col>
      <Col xs={24} sm={12} md={4} lg={2}>
        <Text className="text-gray-6f" type="12-500">
          Montant
        </Text>
        <Text className="block" type="14-500">
          {parseFloat(data.total_amount).toLocaleString('fr') + "€" || "-"}
        </Text>
      </Col>

      <Col style={{ textAlign: 'center' }} xs={24} sm={12} md={4} lg={5}>
        {data.status === "en edition" ?
          <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#78C0E0", width: '230px' }}>{data.status || "-"}</Tag>
          :
          data.status === "envoyé au client" ?
            <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#449DD1", width: '230px' }}>En attente de votre validation</Tag>
            :
            data.status === "conflit" ?
              <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "red", width: '230px' }}>Refusé</Tag>
              :
              data.status === "validé client" ?
                <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#192BC2", width: '230px' }}>En attente de notre validation</Tag>
                :
                data.status === "validé bohr" ?
                  <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#150578", width: '230px' }}>Validé - En attente du paiement</Tag>
                  :
                  data.status === "payé" ?
                    <Tag style={{ color: 'white', fontSize: '14px', backgroundColor: "#0E0E52", width: '230px' }}>Payé</Tag>
                    :
                    <></>
        }

      </Col>


    </Row>
  )


  const columns = [
    {
      title: "Produit",
      dataIndex: "product",
      key: "product",
      responsive: ["sm"],
      width: '40%',
      render: (_, { product }) => (product),
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
      responsive: ["sm"],
      width: '15%',
      editable: true,
      render: (_, { volume,product }) => (
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {parseFloat(volume)} {product.includes("capacité") ? "GC" : "MWh"}
        </span>
      ),
      
    },
    {
      title: "Prix",
      dataIndex: "price",
      key: "price",
      responsive: ["sm"],
      width: '15%',
      render: (_, { price }) => (parseFloat(price).toLocaleString('fr') + "€"),
    },
    {
      title: "Montant",
      dataIndex: "amount",
      key: "amount",
      responsive: ["sm"],
      width: '15%',
      render: (_, { amount }) => (parseFloat(amount).toLocaleString('fr') + "€"),
    },
    {
      title: "tva",
      dataIndex: "tva",
      key: "tva",
      responsive: ["sm"],
      width: '15%',
      render: (_, { tva }) => (parseFloat(tva).toLocaleString('fr') + "%"),
    }
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (

    //   ),
    // },
  ]







  const PanelBody = ({ data }) => {
    return (
      <Row style={{ marginTop: 20 }}>
        <Col flex={2}>
          <div style={{ marginBottom: 40, fontSize: '16px', fontWeight: '600' }}> Votre Facture à recevoir </div>
          {
            data.invoices_details.map((item) =>

              <>
                <div style={{ marginTop: 20, marginBottom: 20, fontSize: '16px', fontWeight: '600' }}> {item.reference_label} </div>
                <Table
                  size="small"
                  className="text-sm"
                  columns={columns}
                  dataSource={item.lines}
                  rowKey={"product"}
                  pagination={false}
                  style={{ width: '100%' }}
                  summary={pageData => {
                    let totalAmount = 0;

                    item.lines.forEach(({ product, amount }) => {
                      if (product.includes("Frais")) {
                        totalAmount -= parseFloat(amount);
                      } else {
                        totalAmount += parseFloat(amount);
                      }

                    });

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell className="text-md font-bold">Total</Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md">-</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md">-</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md font-bold">{totalAmount.toFixed(2).toLocaleString('fr') + "€"}</div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div className="text-md">-</div>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </>
            )
          }


        </Col>

        <Col flex={1}>
        </Col>
        <Col flex={2}>
          {data.status === 'envoyé au client' ?
            <>
              <div style={{ marginBottom: 20, fontSize: '16px', fontWeight: '600' }}> Votre Validation </div>

              <Switch
                checkedChildren="Je suis d'accord avec la FAR"
                unCheckedChildren="Je ne suis pas d'accord avec la FAR"
                onChange={() => setOkFAR(!okFAR)}
                checked={okFAR}
                style={{ marginBottom: 20 }}
              />

              {
                okFAR ?
                  <FormValidateComponent data={data} />
                  :
                  <FormContestComponent data={data} />

              }


            </>
            :
            data.status === 'validé client' ?
              <>
                <TimelineComponent data={data} />
                <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
              </>
              :
              data.status === 'conflit' ?
                <>
                  <TimelineComponent data={data} />
                </>
                :
                data.status === 'validé bohr' ?
                  <>
                    <TimelineComponent data={data} />
                    <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                  </>
                  :
                  data.status === "payé" ?
                    <>
                      <TimelineComponent data={data} />
                      <UtilFactureComponent data={data} setmodalPdfOpen={setmodalPdfOpen} setPdfContent={setPdfContent} />
                    </>
                    :
                    <></>

          }
        </Col>
      </Row>
    )
  }

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
    const x = (value + '').split(',')
    if (x && x.length > 0) {
      setSelectedDate(x)
    }
  };

  const extractUnique = (arr) => {

    const resultArray = [];
    const uniqueDate = [];

    const resultStatus = [];
    const uniqueStatus = [];

    const resultLabels = [];
    const uniqueLabels = [];

    for (const obj of arr) {
      // dates
      const PeriodEnd = moment(obj.invoice_id.slice(0, 6), "YYYYMM").endOf('month').format('YYYY-MM-DD');
      if (!uniqueDate.includes(PeriodEnd)) {
        resultArray.push({ "label": PeriodEnd, "value": PeriodEnd });
      }
      uniqueDate.push(PeriodEnd)

      // status
      const Status = obj.status
      if (!uniqueStatus.includes(Status)) {
        resultStatus.push({ "label": Status, "value": Status});
      }
      uniqueStatus.push(Status)

      // labels
      const Labels = obj.invoice_label
      if (!uniqueLabels.includes(Labels)) {
        resultLabels.push({ "label": Labels, "value": Labels});
      }
      uniqueLabels.push(Labels)

    }

    resultArray.sort((a, b) => a.label.localeCompare(b.label));
    resultStatus.sort((a, b) => a.label.localeCompare(b.label));
    resultLabels.sort((a, b) => a.label.localeCompare(b.label));
    
    return {dates : resultArray, status : resultStatus, labels : resultLabels}
  }


  return (

    <Suspense fallback={<div> Please Wait... </div>} >


      <div className="UserTable px-6 py-4 rounded-xl bg-white my-8">
        <div className="md:flex justify-between  items-center pb-4">
          <div className="text-base font-semibold	mb-4 md:mb-0">
            Factures
          </div>
          <div className="flex justify-center" style={{ width: '500px' }}>
            <select style={{ margin: '10px' }} value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
              <option value="">Date</option>
              {Dates.map((date) => (
                <option key={date.value} value={date.value}>
                  {date.label}
                </option>
              ))}
            </select>

            <select style={{ margin: '10px' }} value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
              <option value="">Status</option>
              {Status.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>

            <select style={{ margin: '10px' }} value={selectedLabel} onChange={(e) => setSelectedLabel(e.target.value)}>
              <option value="">Société</option>
              {Labels.map((label) => (
                <option key={label.value} value={label.value}>
                  {label.label}
                </option>
              ))}
            </select>
          </div>
          <div style={{ cursor: 'pointer' }}>
            <SyncOutlined onClick={() => refetchinvoicesRQ()} />
          </div>

        </div>



        <Collapse
          // defaultActiveKey={["0"]}
          onChange={onChange}
          expandIconPosition="end"
          ghost
          collapsible="icon"
          accordion
          expandIcon={({ isActive }) => (
            <Icon name={isActive ? "arrow-up" : "arrow-down"} />
          )}
        >



          {invoices
            .filter((item) => {
              const dateFilter =
              selectedDate.length === 0 || selectedDate === moment(item.invoice_id.slice(0, 6), "YYYYMM").endOf('month').format('YYYY-MM-DD');
            const statusFilter = selectedStatus.length === 0 || selectedStatus === item.status;
            const labelFilter = selectedLabel.length === 0 || selectedLabel === item.invoice_label;
            return dateFilter && statusFilter && labelFilter;
            })
            .map((invoice: any, index: number) => {
              return (
                <Panel
                  header={<PanelHeader data={invoice} />}
                  key={index + ""}
                  extra={genExtra(invoice)}
                >
                  <PanelBody data={invoice} />
                </Panel>
              );
            })
          }



        </Collapse>

      </div>

      <Modal
        className="modal-fullscreen rounded-none h-full w-full p-0 m-0 all-Campton"
        open={modalPdfOpen}
        onCancel={handleCloseModalPdf}
        bodyStyle={{ minHeight: "100vh" }}
        width="100%"
        footer={null}
      >
        {pdfContent}
      </Modal>

    </Suspense>
  )
}
function setSPV(companies: any) {
  throw new Error("Function not implemented.");
}

